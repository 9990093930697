// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CalcsAlert_changesStatusContainer__WLPyo {
  width: 20%;
  background-color: var(--primary-color);;
  border-radius: 10px;
  padding: 8px 16px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 7px;
  z-index: 10000;
  animation: CalcsAlert_animate__qtFWL 1s ease;
}

.CalcsAlert_changesStatusContainerText__Wo5ZT {
  color: #fff;
  text-align: center;
  font-family: var(--lexend); 
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.CalcsAlert_success__2yeXv {
  background-color: #5ca821;
}

@media (max-width: 767.98px) {
  .CalcsAlert_changesStatusContainer__WLPyo {
    width: 30%;
    z-index: 1500;
  }
}

@keyframes CalcsAlert_animate__qtFWL {
  from {
    top: -120px;
  }

  to {
    top: 7px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/CalcsAlert/CalcsAlert.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,sCAAsC;EACtC,mBAAmB;EACnB,iBAAiB;EACjB,eAAe;EACf,SAAS;EACT,2BAA2B;EAC3B,QAAQ;EACR,cAAc;EACd,4CAA0B;AAC5B;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,0BAA0B;EAC1B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE;IACE,UAAU;IACV,aAAa;EACf;AACF;;AAEA;EACE;IACE,WAAW;EACb;;EAEA;IACE,QAAQ;EACV;AACF","sourcesContent":[".changesStatusContainer {\n  width: 20%;\n  background-color: var(--primary-color);;\n  border-radius: 10px;\n  padding: 8px 16px;\n  position: fixed;\n  left: 50%;\n  transform: translateX(-50%);\n  top: 7px;\n  z-index: 10000;\n  animation: animate 1s ease;\n}\n\n.changesStatusContainerText {\n  color: #fff;\n  text-align: center;\n  font-family: var(--lexend); \n  font-size: 1rem;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  margin: 0;\n}\n\n.success {\n  background-color: #5ca821;\n}\n\n@media (max-width: 767.98px) {\n  .changesStatusContainer {\n    width: 30%;\n    z-index: 1500;\n  }\n}\n\n@keyframes animate {\n  from {\n    top: -120px;\n  }\n\n  to {\n    top: 7px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"changesStatusContainer": `CalcsAlert_changesStatusContainer__WLPyo`,
	"animate": `CalcsAlert_animate__qtFWL`,
	"changesStatusContainerText": `CalcsAlert_changesStatusContainerText__Wo5ZT`,
	"success": `CalcsAlert_success__2yeXv`
};
export default ___CSS_LOADER_EXPORT___;
