import React, { useContext, useEffect, useState } from "react";
import styles from "../SystemPricing/SystemPricing.module.css";
import { AppContext } from "../../../context/AppContext";
import CostOverview from "./CostOverview/CostOverview";
import useSave from "../../../CustomHooks/useSave";
import useSend from "../../../CustomHooks/useSend";
import { ProposalContext } from "../../../context/ProposalContext";
import { MenuContext } from "../../../context/MenuContext";
import { AuthContext } from "../../../context/AuthContext";
import { apiUrl } from "../../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { BatteriesContext } from "../../../context/BatteriesContext";
import { FinancialContext } from "../../../context/FinancialContext";

const SystemPricing = () => {
  /* Global states */
  const { token } = useContext(AuthContext);
  const { sending, setShareModal } = useContext(AppContext);
  const { menuSystemPricing, setMenuSystemPricing } = useContext(MenuContext);
  const {
    proposalData,
    onlyView,
    setProposalOnlyViewLink,
    moduleId,
    inverterId,
    information,
  } = useContext(ProposalContext);
  const { selectedBatteries, batteries,monthsConsumptions,profileSelected } = useContext(BatteriesContext);
  const { selectedBank, selectedPaymentOption, taxCreditValue, taxCredit } =
    useContext(FinancialContext);

  const navigate = useNavigate();
  /* Custom Hooks */
  const { saveProposal } = useSave();
  const { sendProposal } = useSend();

  /* Boolean */
  const [spAnimation, setSpAnimation] = useState(false); // To animate the menu

  const [proposalEncoded, setProposalEncoded] = useState(proposalData);

  useEffect(() => {
    const objectToSend = {
      proposalData,
      moduleId,
      inverterId,
      information,
      selectedBank,
      selectedBatteries,
      batteries,
      selectedPaymentOption,
      taxCreditValue,
      taxCredit,
      monthsConsumptions,
      profileSelected
    };

    setProposalEncoded(objectToSend);
  }, [
    proposalData,
    moduleId,
    inverterId,
    information,
    selectedBank,
    selectedBatteries,
    batteries,
    selectedPaymentOption,
    taxCreditValue,
    taxCredit,
    monthsConsumptions,
    profileSelected
  ]);

  /* To realize the animation */
  const handleClickInCloseButton = () => {
    setSpAnimation(!spAnimation);
    setTimeout(() => {
      setSpAnimation(!spAnimation);
      setMenuSystemPricing(!menuSystemPricing);
    }, 400);
  };

  const share = async () => {
    try {
      setShareModal(true);
      const config = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
          "Cache-Control": "no-cache",
        },
      };
      const response = await fetch(`${apiUrl}/project/shareToken`, config);
      const tokenOnlyView = await response.json();
      const shareUrl = `${window.location.origin}/project?token=${tokenOnlyView.Token}`;
      setProposalOnlyViewLink(shareUrl);
    } catch (error) {
      console.log(error);
    }
  };

  const viewPdf = () => {
    localStorage.setItem("pdfInfo",JSON.stringify(proposalEncoded))
    window.open(`/project/proposal/pdf`,"_blank");
  };

  return (
    <div className={`${styles.modal} ${spAnimation && styles.spAnimateEnd}`}>
      <div className={styles.modalTitleContainer}>
        <h3>SYSTEM PRICING</h3>
        <div className={styles.modalTitleContainerImg}>
          <img
            onClick={handleClickInCloseButton}
            src="/icons/close_ring_duotone.svg"
            alt="close"
          />
        </div>
      </div>
      <div className={styles.menuContainer}>
        <div className={styles.infoContainer}>
          {<CostOverview />}
          <div className={styles.total}>
            <p> Loan Payment </p>
            <div className="d-flex gap-4">
              <p>${proposalData.payment}</p>
            </div>
          </div>
          <div className={styles.total2}>
            <p className="m-0"> Final System Cost</p>
            <div className={`${styles.mobileFlex} d-flex gap-4`}>
              <p className={styles.financialAmount}>
                ${proposalData?.financialAmount?.toFixed(2)}
              </p>
              {!onlyView && (
                <>
                  <button
                    className={styles.button}
                    onClick={() => saveProposal()}
                    disabled={sending}
                  >
                    Save
                  </button>
                  <button
                    className={`${styles.button} ${styles.buttonSend}`}
                    onClick={() => {
                      saveProposal().then(() => {
                        sendProposal();
                      });
                    }}
                    disabled={sending}
                  >
                    Send
                  </button>
                  <button className={`${styles.buttonShare}`} onClick={share}>
                    <img src="/icons/share.svg" alt="Share" />
                  </button>
                  <button className={`${styles.buttonPdf}`} onClick={viewPdf}>
                    <img src="/icons/pdf.svg" alt="Save as pdf" />
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemPricing;
