import React, { useContext, useEffect, useState } from "react";
import styles from "./ConsumptionProfileL.module.css";
import ConsumptionProfileChart from "./ConsumptionProfile.chart";
import CustomConsumptionProfileGraph from "./CustomConsumption.chart";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AppContext } from "../../../context/AppContext";
import { BatteriesContext } from "../../../context/BatteriesContext";
import { AuthContext } from "../../../context/AuthContext";
import { apiUrl } from "../../../context/AppContext";
import { ProposalContext } from "../../../context/ProposalContext";

const ConsumptionProfileComponentL = () => {
  const { token } = useContext(AuthContext);
  const { inner } = useContext(AppContext);
  const { setSending } = useContext(AppContext);
  const {
    consumptionsProfiles,
    setConsumptionsProfiles,
    profileSelected,
    setProfileSelected,
    setChangeConsumptionModal,
  } = useContext(BatteriesContext);
  const { setSaveSuccess, setSaveStatus, setProposalStatusLegend,onlyView} =
    useContext(ProposalContext);
  const [isProfileDeleted,setIsProfileDeleted] = useState(false);

  useEffect(() => {
    return () => {
      
      if (profileSelected?.isCustom) {
        sendCustomProfile();
      }
    };
  }, []);

  const handleClickInProfile = (id) => {
    id = Number(id);
    const profile = consumptionsProfiles.find((profile) => profile.id === id);

    setProfileSelected(profile);
  };

  const createACustomProfile = () => {
    const exist = consumptionsProfiles.find(
      (profile) => profile.name === `${profileSelected.name} Custom`
    );

    if (exist) return;
    addCustomProfile();
  };

  const addCustomProfile = async () => {
    try {
      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
          "Cache-Control": "no-cache",
        },
        body: JSON.stringify({
          ...profileSelected,
          isCustom: true,
        }),
      };

      const response = await fetch(`${apiUrl}/consumptionProfile`, config);
      const data = await response.json();

      setConsumptionsProfiles((prev) => {
        return [
          ...prev,
          {
            ...data,
          },
        ];
      });

      setProfileSelected(data);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCustomProfile = async () => {
    try {
      setIsProfileDeleted(true);
      const config = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
          "Cache-Control": "no-cache",
        },
        body: JSON.stringify({
          profileId: profileSelected.id,
        }),
      };
      
      await fetch(`${apiUrl}/consumptionProfile?deleteCustom=true`, config);

      const findIndex = consumptionsProfiles.findIndex(
        (profile) => profile.id === profileSelected.id
      );
      const newConsumptionsProfiles = [...consumptionsProfiles];
      newConsumptionsProfiles.splice(findIndex, 1);
      setConsumptionsProfiles(newConsumptionsProfiles);
      setProfileSelected(consumptionsProfiles[0]);
    } catch (error) {
      console.log(error);
      setSaveSuccess(true);
      setSaveStatus(true);
      setProposalStatusLegend(
        "We couldn't delete your consumption profile, try again."
      );
      setTimeout(() => {
        setSaveSuccess(false);
        setSending(false);
        setProposalStatusLegend("");
      }, 3000);

      setSaveStatus(false);
    }
  };

  const sendCustomProfile = async () => {
    try {
      if(isProfileDeleted){
        setIsProfileDeleted(false);
        return;
      }
      const config = {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
          "Cache-Control": "no-cache",
        },
        body: JSON.stringify({
          profileId: profileSelected.id,
          consumptionPercentage: profileSelected.consumptionPercentage,
          name: profileSelected.name,
          isCustom: true,
        }),
      };

      const response = await fetch(`${apiUrl}/consumptionProfile`, config);
      const data = await response.json();
      const customProfileChangedIndex = consumptionsProfiles.findIndex(
        (profile) => profile.id === profileSelected.id
      );
      let consumptionsProfilesCopy = consumptionsProfiles;
      consumptionsProfilesCopy[customProfileChangedIndex] =
        profileSelected;

      setConsumptionsProfiles(consumptionsProfilesCopy);
      setSaveSuccess(true);
      setSaveStatus(true);
      setProposalStatusLegend("Your consumption has been saved");
      setTimeout(() => {
        setSaveSuccess(false);
        setSending(false);
        setProposalStatusLegend("");
      }, 3000);
    } catch (error) {
      setSaveSuccess(true);
      setSaveStatus(true);
      setProposalStatusLegend(
        "We couldn't save your consumption profile, try again."
      );
      setTimeout(() => {
        setSaveSuccess(false);
        setSending(false);
        setProposalStatusLegend("");
      }, 3000);

      setSaveStatus(false);
    }
  };

  const tooltipSave = <Tooltip id="tooltipSave">Save Custom Profile</Tooltip>;

  const tooltipDelete = (
    <Tooltip id="tooltipDelete">Delete Custom Profile</Tooltip>
  );

  const tooltipEdit = <Tooltip id="tooltipEdit">Edit Custom Profile</Tooltip>;

  return (
    <div className={styles.consumptionProfileSection}>
      <h2 className={styles.title}>Consumption Profile</h2>

      <div className={styles.consumptionProfileContainer}>
        {inner.innerWidth >= 991 && !onlyView ? (
          <div className={styles.profiles}>
            <div>
              {consumptionsProfiles.map((profile, index) => {
                return (
                  <div
                    key={index}
                    id={profile.id}
                    className={styles.profile}
                    onClick={() => handleClickInProfile(profile.id)}
                  >
                    {profile.name}
                    {profile.isCustom && <span>Custom</span>}
                  </div>
                );
              })}
            </div>
          </div>
        ) : !onlyView && (
          <>
            <select
              className={styles.select}
              onChange={(e) => handleClickInProfile(e.target.value)}
              value={profileSelected?.id}
            >
              {consumptionsProfiles.map((profile, index) => {
                return (
                  <option key={index} value={profile.id}>
                    {profile.name} {profile.isCustom && <span>(Custom)</span>}
                  </option>
                );
              })}
            </select>
          </>
        )}

        <div className={`${styles.consumptionProfile} ${styles.consumptionProfileOnlyView}`}>
          <div className={styles.selectedContainer}>
            <span>Consumption Profile Selected:</span>
            <p>
              {profileSelected?.name}{" "}
              {profileSelected?.isCustom && `(Custom ${profileSelected.id})`}
            </p>
          </div>
          {profileSelected?.isCustom && !onlyView ? (
            <>
              <OverlayTrigger placement="bottom" overlay={tooltipEdit}>
                <div
                  className={styles.edit}
                  onClick={() => setChangeConsumptionModal((prev) => !prev)}
                >
                  <img src="/icons/edit_white.svg" alt="Edit" />
                </div>
              </OverlayTrigger>
              <OverlayTrigger placement="bottom" overlay={tooltipDelete}>
                <div className={styles.trash}>
                  <img
                    src="/icons/trash.svg"
                    alt="Trash"
                    onClick={deleteCustomProfile}
                  />
                </div>
              </OverlayTrigger>
              <OverlayTrigger placement="bottom" overlay={tooltipSave}>
                <div className={styles.send}>
                  <img
                    src="/icons/send.svg"
                    alt="Send"
                    onClick={sendCustomProfile}
                  />
                </div>
              </OverlayTrigger>
            </>
          ) : !onlyView && (
            <>
              <div className={styles.add}>
                <button
                  onClick={createACustomProfile}
                  className={styles.opButton}
                >
                  Customize
                </button>
              </div>
            </>
          )}
          {profileSelected.isCustom && inner.innerWidth >= 1280 && !onlyView ? (
            <CustomConsumptionProfileGraph
              sendCustomProfile={sendCustomProfile}
            />
          ) : (
            <ConsumptionProfileChart profileSelected={profileSelected} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ConsumptionProfileComponentL;
