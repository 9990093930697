import React, { useContext } from 'react'
import { AppContext } from '../../context/AppContext'


const VideoComponent = ({id,source,background}) => {
    const {handleVideoLoad,videosLoadStatus} = useContext(AppContext);
    
  return (
    <video
    id={id}
    autoPlay
    loop
    muted
    className={videosLoadStatus[id] ? "video" : "video imageClass" }
    style={{backgroundImage: `url(${background})`}}
    onLoadedData={(event) => handleVideoLoad(event.target.id)}
  >
    <source src={source} type="video/mp4" />
  </video>
  )
}

export default VideoComponent