import { useContext } from "react";
import styles from "./AutoSave.module.css";
import { AppContext } from "../../context/AppContext";
import { ProposalContext } from "../../context/ProposalContext";

const AutoSave = () => {
  const { autoSaveError } = useContext(AppContext);
  const { autoSaveStatus } = useContext(ProposalContext);

  return (
    <div
      className={`${styles.autoSaveStatusContainer} ${
        autoSaveError && styles.autoSaveStatusContainerError
      }`}
    >
      <p className={styles.autoSaveStatusContainerText}>{autoSaveStatus}</p>
    </div>
  );
};

export default AutoSave;
