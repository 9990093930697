function createObjectOfUtillityBills(proposalData) {

  
  
  const max = proposalData.firstYearBill ? Math.max(
    ...proposalData?.firstYearBill,
    ...proposalData?.oldBillsProjection
  ) : new Array(12).fill(0);
  const steps = 250;
  const yMax = (steps * Math.ceil(max / steps))

  const option = {
    animation: false,
    textStyle: {
      fontFamily: "DM Sans",
    },
    color: ["#5c5c5c", "#ffc010"],
    title: {
      text: "Utility Bills Before and After Solar",
      textStyle: {
        color: "#fff",
        fontFamily: "DM Sans",
        margin: "30px",
      },
    },
    tooltip: {},
    legend: {
      data: [
        {
          name: "Pre-Solar Bill",
          textStyle: {
            color: "#999999",
          },
        },
        {
          name: "Post-Solar Bill",
          textStyle: {
            color: "#ffc010",
          },
        },
      ],
      top: "auto",
      bottom: 0,
    },
    xAxis: {
      type: "category",
      data: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      axisLabel: {
        color: "#fff",
      },
    },
    yAxis: {
      axisLabel: { formatter: "{value} $", color: "#fff" },
      max: yMax,
    },
    series: [
      {
        color: ["#ffc010"],
        name: "Post-Solar Bill",
        type: "bar",
        data: proposalData?.firstYearBill,
      },
      {
        color: ["#999999"],
        name: "Pre-Solar Bill",
        type: "bar",
        data: proposalData?.oldBillsProjection,
      },
    ],
  };

  return option;
}

export default createObjectOfUtillityBills;
