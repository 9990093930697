import React, {useEffect, useRef } from "react";
import * as echarts from "echarts";
import createOptionsOfConsumptionProfile from "./ConsumptionProfile.chart.config";

const ConsumptionProfileGraph = ({profileSelected}) => {
  const chartRef = useRef(null);

  /* Create the chart foreach change in the data */
  useEffect(() => {

    const myChart = echarts.init(chartRef.current);

    const option = createOptionsOfConsumptionProfile(profileSelected);
    option && myChart.setOption(option);

    function resize(){
      myChart.resize();
    }

    /* Resize the chart when the window is resized */
    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
      myChart.dispose();
    };
  }, [profileSelected]);

  return <div ref={chartRef} style={{ width: "100%", height: "100%" }}></div>;
};

export default ConsumptionProfileGraph;