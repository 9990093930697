import { useContext, useEffect, useState } from "react";
import styles from "./ChangeConsumptionModal.module.css";
import { BatteriesContext } from "../../../context/BatteriesContext";

const ChangeConsumptionModal = () => {
  const {
    profileSelected,
    setChangeConsumptionModal,
    setProfileSelected,
    setConsumptionsProfiles,
  } = useContext(BatteriesContext);

  const [consumption, setConsumption] = useState(
    profileSelected.consumptionPercentage
  );




  const handleChange = (event) => {
    const index = event.target.id.split("-")[1];
    const value = event.target.value;

    const newConsumption = [...consumption];
    newConsumption[index] = Number(value);
    setConsumption(newConsumption);
  };

  const closeModal = () => {
    setProfileSelected(prev => {
        return {...prev, consumptionPercentage: consumption}
    });
    setConsumptionsProfiles((prev) => {
      return prev.map((profile) => {
        if (profile.id === profileSelected.id) {
          return { ...profileSelected, consumptionPercentage: consumption };
        }
        return profile;
      });
    });
    setChangeConsumptionModal(false);
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalContainer}>
        <div className={styles.modalTitleContainer}>
          <h3>
            Settings of <span>{profileSelected.name}</span>
          </h3>
          <div className={styles.modalTitleContainerImg}>
            <img
              onClick={closeModal}
              src="/icons/close_ring_duotone.svg"
              alt="close"
            />
          </div>
        </div>
      </div>
      <div>
        <h2 className={styles.title}>
          Change the KwH consumption for each hour of the day
        </h2>
        <div className={styles.inputsContainer}>
          {consumption.map((value, index) => {
            return (
              <div key={index} className={styles.inputContainer}>
                <label htmlFor={`hour-${index}`}>{index}:00</label>
                <input
                  type="number"
                  id={`hour-${index}`}
                  value={value}
                  onChange={handleChange}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ChangeConsumptionModal;