function createObjectOfUtBills(proposalData) {
  const object = {
    animation: false,
    title: {
      text: "Utility Bill Escalation vs. Cost of Solar",
      textStyle: {
        fontFamily: "Lexend",
        fontSize: "1.2rem",
        color: "white",
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    toolbox: {},
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        data: Array.from({ length: 30 }, (_, i) => i + 1),
        axisLabel: {
          show: true,
          color: "#fff",
          interval: (_, value) => {
            return value === "1" || value % 5 === 0;
          },
        },
      },
    ],
    yAxis: [
      {
        name: "Dolar",
        type: "value",
        axisLabel: { show: true, color: "#fff" },
      },
    ],
    series: [
      {
        name: "Utility Bill: ",
        type: "line",
        areaStyle: {
          opacity: 0.4,
        },
        color: "#ffc010",
        shadowBlur: 10,
        shadowColor: "rgba(0, 0, 0, 0.3)",
        data: proposalData.thirtyYearsBillProjection,
        showSymbol: false,
      },
      {
        name: "Cost of Solar ",
        type: "line",
        areaStyle: {
          opacity: 0.4,
        },
        color: "rgba(0,200,217)",
        shadowBlur: 10,
        shadowColor: "rgba(0, 0, 0, 0.3)",
        data: proposalData.thirtyYearsTotalBillProjection,
        showSymbol: false,
      },
    ],
  };

  return object;
}

export default createObjectOfUtBills;
