import React, { useContext, useEffect, useRef } from "react";
import * as echarts from "echarts";
import { ProposalContext } from "../../context/ProposalContext";
import createObjectOfUtBills from "./UtBills.chart.config";

/* Utility Bill Escalation vs. Cost of Solar */

function UtBillsEchartsComponents({
  proposalData,
  setSavingsAnnual,
  setSavingsAnnualDataIndex,
}) {
  /*   const { proposalData, setSavingsAnnual, setSavingsAnnualDataIndex } =
    useContext(ProposalContext); */

  useEffect(() => {
    const chartDom = document.getElementById("cost");
    const myChart = echarts.init(chartDom);

    const option = createObjectOfUtBills(proposalData);
    myChart.setOption(option);

    /* Resize the chart when the window is resized */
    window.addEventListener("resize", () => {
      myChart.resize();
      /* Resize the title font size when the window is resized */
      myChart.setOption({
        title: {
          textStyle: {
            fontSize: window.innerWidth <= 370 ? "1rem" : "1.2rem",
            overflow: window.innerWidth <= 370 ? "break" : "none",
          },
        },
      });
    });

    if (setSavingsAnnualDataIndex) {
      myChart.on("highlight", function (params) {
        setSavingsAnnualDataIndex(params.batch[0].dataIndex);
        const utilityBill =
          proposalData.thirtyYearsBillProjection[params.batch[0].dataIndex];
        const costOfSolar =
          proposalData.thirtyYearsTotalBillProjection[
            params.batch[0].dataIndex
          ];

        let calc = (utilityBill - costOfSolar) * 12;
        setSavingsAnnual(calc);
      });
    }

    return () => {
      window.removeEventListener("resize", () => {
        myChart.resize();
      });
      myChart.off("mouseover");
      myChart.dispose();
    };
  }, [proposalData]);

  const handleLeave = () => {
    if (setSavingsAnnualDataIndex && setSavingsAnnual) {
      setSavingsAnnualDataIndex(0);

      if (proposalData.thirtyYearsBillProjection) {
        setSavingsAnnual(
          (proposalData.thirtyYearsBillProjection[0] -
            proposalData.thirtyYearsTotalBillProjection[0]) *
            12
        );
      }
    }
  };

  return (
    <div
      id="cost"
      style={{
        width: "100%",
        height: "400px " /* 600px */,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onMouseLeave={() => handleLeave()}
    />
  );
}

export default UtBillsEchartsComponents;
