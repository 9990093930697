import React, { useContext, useEffect, useRef } from "react";
import * as echarts from "echarts";
import { BatteriesContext } from "../../context/BatteriesContext";
import { ProposalContext } from "../../context/ProposalContext";
import styles from "./Section7.module.css";
import createObjectOfHistoricalVsExpectedConfig from "./HistoricalVsExpected.config";

/* Historical Energy Consumption vs. Expected Solar Production */

function EnergyVSsolar({proposalData,monthsConsumptions}) {
/*   const { proposalData } = useContext(ProposalContext);
  const { monthsConsumptions } = useContext(BatteriesContext); */



  const chartRef = useRef(null);

  useEffect(() => {
    const chartDom = chartRef.current;
    const myChart = echarts.init(chartDom, "dark");

    const option = createObjectOfHistoricalVsExpectedConfig(
      proposalData,
      monthsConsumptions
    );

    myChart.setOption(option);

    // Manejar el evento de redimensionamiento de ventana
    const handleResize = () => {
      myChart.setOption({
        title: {
          textStyle: {
            fontSize: window.innerWidth <= 991 ? "1rem" : "1.4rem",
            overflow: window.innerWidth <= 576 ? "break" : "none",
          },
        },
      });

      myChart.resize();
    };

    window.addEventListener("resize", handleResize);

    // Asegúrate de limpiar el evento al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
      myChart.dispose();
    };
  }, [proposalData, monthsConsumptions]);

  return (
    <div className={styles.chartContainer}>
      <div
        ref={chartRef}
        id="consume"
        style={{ width: "100%", height: "100%" }}
      ></div>
    </div>
  );
}

export default EnergyVSsolar;
