import React from "react";
import styles from "./Section9.module.css";
const Card = ({ info }) => {
  return (
    <div className={styles.stepContainer}>
      <div className={styles.cardContainer}>
        <div className={styles.imageContainer}>
          <img className={styles.img} src={info.imageSource} alt="" />
        </div>
        <div className={styles.informationContainer}>
          <h3>{info.title}</h3>
          <p>{info.description}</p>
        </div>
      </div>
      <div className={styles.stageContainer}>
        { (
          <p className={styles.stage}>
            {info.id !== 8 ? `Stage ${info.stage[0]} • ${info.stage[1]} - ${info.stage[2]} weeks` : ""}
          </p>
        )}
      </div>
    </div>
  );
};

export default Card;
