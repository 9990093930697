import UtilityBillsChart from "./UtilityBills.chart";
import "../../styles.css";
import styles from "./Section8.module.css";
import { useContext } from "react";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";
import { FinancialContext } from "../../context/FinancialContext";
import { ProposalContext } from "../../context/ProposalContext";

const Section8 = ({reference}) => {
  const { proposalData } = useContext(ProposalContext);
  const { taxCreditValue, selectedBank, selectedPaymentOption } =
    useContext(FinancialContext);
  const [ref, inView] = useInView({ triggerOnce: true });

  return (
    <section id="section8" className="videoSection" ref={reference}>
      <div className={styles.sectionEightContainer}>
        <div className="row container-fluid p-0 overflow-hidden">
          <div
            data-aos="fade-right"
            className={styles.sectionEightTitleContainer}
          >
            <h1 className={styles.h1Section8}>Proposal details</h1>
            <div className={styles.textSec8Sub}>
              Financing Your solar system
            </div>
          </div>
          <div className="col-lg-5 centered-elements p-0">
            <div className="rect8 mt-4 ">
              <div className="row paymentContainer m-0">
                <div className={`${styles.textPaymentTitle}`}>
                  MONTHLY PAYMENT
                </div>
                <div className="col-md-6 payment">
                  <div>
                    <div className={styles.textPayment}>Payment 1-15</div>
                    <div ref={ref} className={styles.h1Section8Card}>
                      {inView ? (
                        <CountUp
                          start={0}
                          end={proposalData.payment?.toFixed(2)}
                          duration={1}
                          decimals={2}
                          prefix="$"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 estimated">
                  <div>
                    <div className={styles.textPayment}>
                      Estimated 30 years saving
                    </div>
                    <div ref={ref} className={styles.h1Section8Card}>
                      {inView ? (
                        <CountUp
                          start={0}
                          end={proposalData?.savingsOverThirtyYears?.toFixed(2)}
                          duration={1}
                          decimals={2}
                          prefix="$"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.rect8White}>
                <div className="row m-0 w-100">
                  <div className={`${styles.textPayment} text-start`}>
                    FINANCE OPTION
                  </div>
                  <div className={styles.div}>
                    <div className={styles.divBackground}>
                      <span>{selectedPaymentOption.bankName || "-"}</span>
                      <span>
                        {selectedPaymentOption.name ||
                          "Financial company was not selected"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row p-0 w-100">
                  <div className="col-8 ">
{/*                     <div className={`${styles.textPayment} text-start`}>
                      Loan Product
                    </div> */}
                    <div className={`${styles.textPayment} text-start`}>
                      Loan term
                    </div>
                    <div className={`${styles.textPayment} text-start`}>
                      Loan APR
                    </div>
                    <div className={`${styles.textFinancial} text-start`}>
                      TOTAL INCENTIVES
                    </div>
                    <div className={`${styles.textFinancial} text-start`}>
                      FINANCED AMOUNT
                    </div>
                  </div>
                  <div className="col-4 text-end p-0">
    {/*                 <div className={styles.textPayment}> Standart </div> */}
                    <div className={styles.textPayment}>
                      {`${Math.round(selectedPaymentOption.term / 12) || "-"} years`}
                    </div>
                    <div className={styles.textPayment}>
                      {selectedPaymentOption.interestRate}%
                    </div>
                    <div ref={ref} className={styles.textFinancial}>
                      {inView ? (
                        <CountUp
                          start={0}
                          end={taxCreditValue?.toFixed(2)}
                          duration={1}
                          decimals={2}
                          prefix="$"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div ref={ref} className={styles.textFinancial}>
                      {inView ? (
                        <CountUp
                          start={0}
                          end={proposalData?.financialAmount?.toFixed(2)}
                          duration={1}
                          decimals={2}
                          prefix="$"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={`row ${styles.paddingContainer}`}>
                <div className="col-8 text-start">
                  <div className={`${styles.textFinancial} ${styles.textFont}`}>
                    NEW COST
                  </div>
                </div>
                <div className="col-4 p-0">
                  <div
                    ref={ref}
                    className={`${styles.textFinancial} ${styles.textFont} text-end`}
                  >
                    {inView ? (
                      <CountUp
                        start={0}
                        end={proposalData.financialAmount?.toFixed(2)}
                        duration={1}
                        decimals={2}
                        prefix="$"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className=" pt-5 mt-5">
              <UtilityBillsChart proposalData={proposalData} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section8;
