import UtBillsEchartsComponents from "./UtBills.chart";
import { useContext } from "react";
import styles from "./Section6.module.css";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";
import { ProposalContext } from "../../context/ProposalContext";

const Section6Part1 = () => {
  const { proposalData, savingsAnnual, savingsAnnualDataIndex, setSavingsAnnual, setSavingsAnnualDataIndex } =
    useContext(ProposalContext);
  const [ref, inView] = useInView({ triggerOnce: true });

  const ordinalNumbers = [
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
    "11th",
    "12th",
    "13th",
    "14th",
    "15th",
    "16th",
    "17th",
    "18th",
    "19th",
    "20th",
    "21st",
    "22nd",
    "23rd",
    "24th",
    "25th",
    "26th",
    "27th",
    "28th",
    "29th",
    "30th",
  ];

  return (
    <div className={`container-fluid ${styles.paddingBottomContainer}`}>
      <div className="row ">
        <div className="col-lg-12 p-0 d-flex flex-column align-items-center">
          <div className={`mb-5 ${styles.rectH1} overflow-hidden`}>
            <h2 data-aos="zoom-in" className={styles.customH1Section6}>
              Save with Solar
            </h2>
          </div>
          <br />
          <div className={`row rounded-rectangle ${styles.rect6Part1}`}>
            <div className={"col-md-4 overflow-hidden"}>
              <div data-aos="zoom-in" data-aos-duration="300">
                <div className={styles.customTextSec6}>
                  Your Current Utility Bill
                </div>
                <div className={styles.customCashSec6}>
                  {inView ? (
                    <CountUp
                      start={0}
                      end={proposalData.project.avgMonthlyBill?.toFixed(2)}
                      duration={1}
                      decimals={2}
                      prefix="$"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className={styles.bold}>
                  {ordinalNumbers[savingsAnnualDataIndex]} Year Annual Saving
                </div>
                <span ref={ref} className={styles.infoYellow}>
                  {inView ? (
                    <CountUp
                      start={0}
                      end={savingsAnnual?.toFixed(2)}
                      duration={0.1}
                      decimals={2}
                      prefix="$"
                    />
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </div>
            <div className="col-md-8">
              <div className="container">
                <UtBillsEchartsComponents  proposalData={proposalData} setSavingsAnnual={setSavingsAnnual} setSavingsAnnualDataIndex={setSavingsAnnualDataIndex}></UtBillsEchartsComponents>
                <div className={styles.textGraph}> 30 Year Saving </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section6Part1;
