import React from "react";
import Part1 from "./Part1/Part1";
import Part2 from "./Part2/Part2";
import styles from "./Section3R.module.css";

const Section3R = ({reference}) => {
  return (
    <section className={styles.section3Container} id="section3" ref={reference}>
      <Part1 />
      <Part2 />
    </section>
  );
};

export default Section3R;
