import { useState } from "react";
import styles from "./SubMenu.module.css";

const SubMenu = ({ adder, handleChangeInInput, onlyView }) => {
  const [inputValue, setInputValue] = useState(parseFloat(adder.qty) || 1);

  const handleChange = (event, adder) => {
    setInputValue(event.target.value);
    handleChangeInInput(event, adder);
  };

  return (
    <div className={styles.subMenuContainer}>
      {adder.measurementUnit && (
        <>
          <div>
            <input
              onChange={(event) => handleChange(event, adder)}
              id={adder.measurementUnit}
              havemeasurementunit={"true"}
              name={adder.measurementUnit}
              className={styles.input}
              type="number"
              step={0.01}
              min={1}
              value={inputValue}
              disabled={onlyView}
            />
            <label htmlFor={adder.measurementUnit}>
              {adder.measurementUnit}
            </label>
          </div>
          <span className={styles.totalPrice}>
            ${adder?.totalPrice?.toFixed(2)}
          </span>
        </>
      )}
    </div>
  );
};

export default SubMenu;
