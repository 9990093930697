import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
  Font,
} from "@react-pdf/renderer";
import { ProposalContext } from "../../context/ProposalContext";
import React, { useContext } from "react";
import { useEffect } from "react";
import useProposal from "../../CustomHooks/useProposal";
import { AuthContext } from "../../context/AuthContext";
import Loader from "../Loader/Loader";

Font.registerHyphenationCallback((word) => [word]);

const styles = StyleSheet.create({
  section: {
    marginBottom: "20px",
    fontSize: "12px",
  },
  sectionTitle: {
    marginBottom: "10px",
    fontSize: "20px",
  },
  customerInfoCell: {
    marginBottom: "10px",
  },
  customerInfoTitle: {
    display: "flex",
    gap: "8px",
    fontSize: "8px",
  },
  annualIrrImagesContainer: {
    display: "flex",
    gap: "10px",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#313131",
    borderBottom: "0px",
    // textAlign: "center",
    display: "flex",
    fontSize: "8px",
    color: "#313131",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHeaders: {
    width: "25%",
    borderBottom: "1px solid #313131",
    borderRight: "1px solid #313131",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    height: "20px",
  },
  tableCol: {
    width: "25%",
    borderColor: "#313131",
  },
  tableCellMiddle: {
    borderBottom: "1px solid #313131",
    borderRight: "1px solid #313131",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingLeft: "5px",
    display: "flex",
    height: "20px",
  },
  lastColumns: {
    borderRight: "0px",
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
});

function ShadeReportPDF() {
  const { token, setToken } = useContext(AuthContext);
  const [getProposal] = useProposal();
  const { proposalData, isFetching, error } = useContext(ProposalContext);

  useEffect(() => {
    if (!token) {
      const searchParams = new URLSearchParams(window.location.search);

      if (searchParams.get("token")) {
        setToken(searchParams.get("token"));
      }
    } else {
      getProposal();
    }
  }, [token]);

  return isFetching || error ? (
    <Loader />
  ) : (
    <PDFViewer style={{ width: "100vw", height: "100vh" }}>
      <Document title="Shade Report">
        <Page size="A4">
          <View style={{ padding: "10% 10%" }}>
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>
                Shade Report - {proposalData.project.clientName}
              </Text>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "3px",
                }}
              >
                <View style={{ flex: 1 }}>
                  <View style={styles.customerInfoCell}>
                    <Text style={styles.customerInfoTitle}>Customer</Text>
                    <Text>{proposalData.project.clientName}</Text>
                  </View>

                  <View>
                    <Text style={styles.customerInfoTitle}>Address</Text>
                    <Text wrap={false}>
                      {proposalData.project.clientAddress}
                    </Text>
                  </View>
                </View>

                <View style={{ flex: 1 }}>
                  <View style={styles.customerInfoCell}>
                    <Text style={styles.customerInfoTitle}>Designer</Text>
                    <Text>{proposalData.project.salesmanName}</Text>
                  </View>

                  <View>
                    <Text style={styles.customerInfoTitle}>Coordinate</Text>
                    <Text>{proposalData.project.coordinates?.join(", ")}</Text>
                  </View>
                </View>

                <View style={{ flex: 1 }}>
                  <View>
                    <Text style={styles.customerInfoTitle}>Date</Text>
                    <Text>
                      {new Date().getDate().toString()}/
                      {(new Date().getMonth() + 1).toString()}/
                      {new Date().getFullYear()}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Annual irradiance</Text>
              <View style={styles.annualIrrImagesContainer}>
                {proposalData.shadeReport[0]?.image && (
                  <Image
                    style={styles.annualIrrImage}
                    src={proposalData.shadeReport[0].image
                      .substring(
                        0,
                        proposalData.shadeReport[0].image.length - 4
                      )
                      .concat("png")}
                  />
                )}
                {proposalData.shadeReport[1]?.image && (
                  <Image
                    style={styles.annualIrrImage}
                    src={proposalData.shadeReport[1].image
                      .substring(
                        0,
                        proposalData.shadeReport[1].image.length - 4
                      )
                      .concat("png")}
                  />
                )}
              </View>
            </View>

            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Summary</Text>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableHeaders}>
                    <Text>Array ID</Text>
                  </View>

                  <View style={styles.tableHeaders}>
                    <Text>Panel count</Text>
                  </View>

                  <View style={styles.tableHeaders}>
                    <Text>Azimuth</Text>
                  </View>

                  <View style={styles.tableHeaders}>
                    <Text>Pitch</Text>
                  </View>

                  <View style={styles.tableHeaders}>
                    <Text>Annual TOF</Text>
                  </View>

                  <View style={styles.tableHeaders}>
                    <Text>Annual solar access</Text>
                  </View>

                  <View
                    style={{ ...styles.tableHeaders, ...styles.lastColumns }}
                  >
                    <Text>Annual TSRF</Text>
                  </View>
                </View>

                {proposalData.systemSizeInfo.map((sz) => (
                  <View style={styles.tableRow}>
                    <View
                      style={{
                        ...styles.tableCol,
                        ...styles.tableCellMiddle,
                      }}
                    >
                      <Text>{sz.array}</Text>
                    </View>

                    <View
                      style={{
                        ...styles.tableCol,
                        ...styles.tableCellMiddle,
                      }}
                    >
                      <Text>{sz.modules}</Text>
                    </View>

                    <View
                      style={{
                        ...styles.tableCol,
                        ...styles.tableCellMiddle,
                      }}
                    >
                      <Text>{sz.azimuth.join(", ")}°</Text>
                    </View>

                    <View
                      style={{
                        ...styles.tableCol,
                        ...styles.tableCellMiddle,
                      }}
                    >
                      <Text>{sz.tilt.join(", ")}°</Text>
                    </View>

                    <View
                      style={{
                        ...styles.tableCol,
                        ...styles.tableCellMiddle,
                      }}
                    >
                      <Text>{sz.annualTof}%</Text>
                    </View>

                    <View
                      style={{
                        ...styles.tableCol,
                        ...styles.tableCellMiddle,
                      }}
                    >
                      <Text>{sz.annualSolarAccess}%</Text>
                    </View>

                    <View
                      style={{
                        ...styles.tableCol,
                        ...styles.tableCellMiddle,
                        ...styles.lastColumns,
                      }}
                    >
                      <Text>'Annual TSRF'</Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default ShadeReportPDF;
