import { useContext, useEffect, useState } from "react";
import styles from "./OverrideModal.module.css";
import { MenuContext } from "../../../../../../context/MenuContext";
import { ProposalContext } from "../../../../../../context/ProposalContext";

const OverrideModal = () => {
  const { setModalOverride } = useContext(MenuContext);
  const { proposalData } = useContext(ProposalContext);
  const {
    overrideAndProfit,
    setOverrideAndProfit,
    setInformation,
    basePricePerWatt,
  } = useContext(ProposalContext);
  const [errorValidate, setErrorValidate] = useState({
    override: false,
    profit: false,
  });


  /* Close the modal and reset the override and profit values */
  const handleClickInClose = () => {
    setModalOverride(false);
    if (overrideAndProfit.override <= 2.4 || overrideAndProfit.profit < 0) {
      setOverrideAndProfit({
        override: basePricePerWatt,
        profit: 0,
      });
      setInformation((prev) => ({
        ...prev,
        overridePricePerWatt: basePricePerWatt,
        profit: 0,
      }));
      return;
    }

    setInformation((prev) => ({
      ...prev,
      overridePricePerWatt: overrideAndProfit.override,
      profit: overrideAndProfit.profit,
    }));
  };

  /* validations */
  const validate = (event) => {
    let value = parseFloat(event.target.value);
    const id = event.target.id;

    const errorValidate = {
      override: false,
      profit: false,
    };

    if (id === "override")
      if (value < basePricePerWatt)
        errorValidate.override = `The override price must be greater than the base price (>=${proposalData.project.basePricePerWatt})`;

    if (id === "profit")
      if (value < 0) errorValidate.profit = "The profit must be greater than 0";

    setErrorValidate(errorValidate);
    return errorValidate;
  };

  const handleChange = (event) => {
    if (isNaN(event.target.value)) event.target.value = 0;

    const errorValidate = validate(event);

    if (errorValidate.profit) {
      setOverrideAndProfit({
        override: basePricePerWatt,
        profit: event.target.value,
      });
      return;
    }

    if (errorValidate.override) {
      setOverrideAndProfit({
        override: event.target.value,
        profit: 0,
      });
      return;
    }

    if (event.target.id === "profit") {
      if (event.target.value === "") {
        setOverrideAndProfit({
          ...overrideAndProfit,
          [event.target.id]: "",
          override: basePricePerWatt,
        });
        return;
      }

      let calc = Number(
        parseFloat(event.target.value) /
          (proposalData.realSystemCapacity * 1000).toFixed(2)
      );
      setOverrideAndProfit({
        [event.target.id]: event.target.value,
        override: Math.ceil((basePricePerWatt + calc) * 100 ) / 100,
      });
    } else if (event.target.id === "override") {
      if (event.target.value === "") {
        setOverrideAndProfit({
          ...overrideAndProfit,
          [event.target.id]: "",
          profit: 0,
        });
        return;
      }

      let calc = parseFloat(event.target.value) - basePricePerWatt;
      setOverrideAndProfit({
        [event.target.id]: event.target.value,
        profit: Number(
          (calc * proposalData.realSystemCapacity * 1000).toFixed(2)
        ),
      });
    }
  };

  return (
    <div className={styles.overrideContainer}>
      <img
        onClick={handleClickInClose}
        className={styles.img}
        src="/icons/close_ring_duotone.svg"
        alt="close"
      />
      <div className={styles.inputsContainer}>
        <div>
          <h3>Override</h3>
          <input
            id="override"
            type="number"
            min={0}
            step={0.01}
            value={overrideAndProfit.override}
            onChange={(event) => handleChange(event)}
          />
        </div>
        <div>
          <h3>Profit</h3>
          <input
            id="profit"
            type="number"
            min={0}
            step={0.01}
            value={overrideAndProfit.profit}
            onChange={(event) => handleChange(event)}
          />
        </div>
      </div>
        <p className={styles.error}>
          {errorValidate.profit
            ? errorValidate.profit
            : errorValidate.override
            ? errorValidate.override
            : ""}
        </p>

    </div>
  );
};

export default OverrideModal;
