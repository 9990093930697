import { useContext } from "react";
import { FinancialContext } from "../../../../../context/FinancialContext";
import styles from "../CostOverview.module.css";

const Financing = ({ proposalData }) => {
  const { selectedPaymentOption } = useContext(FinancialContext);
  return (
    <div className={styles.tableContainer}>
      <div className={styles.category}>
        <h3>Financing</h3>
      </div>
      <table className={styles.section5Table}>
        <tbody className={styles.elementContainer}>
          <tr className={styles.element}>
            <td>Contract Term (months) </td>
            <td>
              <span>{proposalData.financials?.selectedFinancial[0]?.term || selectedPaymentOption.term} </span>
            </td>
          </tr>
          <tr className={styles.element}>
            <td> Interest Rate </td>
            <td>
              {proposalData.financials?.selectedFinancial[0]?.interestRate ||
                selectedPaymentOption.interestRate}
              %
            </td>
          </tr>
          <tr className={styles.element}>
            <td>Financed Amount</td>
            <td> ${proposalData.financialAmount?.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Financing;
