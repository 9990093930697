// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tariffs_searchBar__PPxTB {
  padding: 8px 16px 8px 38px;
  width: 100%;
  outline: none;
  border-style: none;
}

.Tariffs_searchBar__PPxTB:disabled {
  background-color: #fff;
  color: #000;
}

.Tariffs_title__\\+SpL9{
    font-size: 1.625rem;
    font-family: var(--lexend);
    font-weight: 500;
    color: #fff;
    margin-bottom: 16px;
    margin-top: 16px;
  
    text-align: left;
}


.Tariffs_searchContainer__6FJm4 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding-left: 16px;
  align-items: start;
}

.Tariffs_inputContainer__UIuYN {
  position: relative;
  width: 50%;
}

.Tariffs_inputContainer__UIuYN img {
  position: absolute;
  left: 1%;
  top: 20%;
}

.Tariffs_tariffs__H\\+6x1 {
  position: absolute;
  width: 100%;
  height: auto;
  background-color: #fff;
  z-index: 50;
  margin-top: 1px;
}

.Tariffs_tariff__WHDaX:hover {
  background-color: var(--primary-color);
  cursor: pointer;
}

.Tariffs_tariff__WHDaX p {
  text-align: left;
  padding: 4px 8px 4px 38px;
  margin-bottom: 0;
  font-family: var(--dm-sans);
}

.Tariffs_tariff__WHDaX p span {
    font-size: 0.775rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Batteries/Tariffs/Tariffs.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,WAAW;EACX,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,WAAW;AACb;;AAEA;IACI,mBAAmB;IACnB,0BAA0B;IAC1B,gBAAgB;IAChB,WAAW;IACX,mBAAmB;IACnB,gBAAgB;;IAEhB,gBAAgB;AACpB;;;AAGA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,QAAQ;AACV;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,sCAAsC;EACtC,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,gBAAgB;EAChB,2BAA2B;AAC7B;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".searchBar {\n  padding: 8px 16px 8px 38px;\n  width: 100%;\n  outline: none;\n  border-style: none;\n}\n\n.searchBar:disabled {\n  background-color: #fff;\n  color: #000;\n}\n\n.title{\n    font-size: 1.625rem;\n    font-family: var(--lexend);\n    font-weight: 500;\n    color: #fff;\n    margin-bottom: 16px;\n    margin-top: 16px;\n  \n    text-align: left;\n}\n\n\n.searchContainer {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: start;\n  padding-left: 16px;\n  align-items: start;\n}\n\n.inputContainer {\n  position: relative;\n  width: 50%;\n}\n\n.inputContainer img {\n  position: absolute;\n  left: 1%;\n  top: 20%;\n}\n\n.tariffs {\n  position: absolute;\n  width: 100%;\n  height: auto;\n  background-color: #fff;\n  z-index: 50;\n  margin-top: 1px;\n}\n\n.tariff:hover {\n  background-color: var(--primary-color);\n  cursor: pointer;\n}\n\n.tariff p {\n  text-align: left;\n  padding: 4px 8px 4px 38px;\n  margin-bottom: 0;\n  font-family: var(--dm-sans);\n}\n\n.tariff p span {\n    font-size: 0.775rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchBar": `Tariffs_searchBar__PPxTB`,
	"title": `Tariffs_title__+SpL9`,
	"searchContainer": `Tariffs_searchContainer__6FJm4`,
	"inputContainer": `Tariffs_inputContainer__UIuYN`,
	"tariffs": `Tariffs_tariffs__H+6x1`,
	"tariff": `Tariffs_tariff__WHDaX`
};
export default ___CSS_LOADER_EXPORT___;
