function createOptionsOfCostLineChart(proposalData, data, links) {
  const thirtyYearsBillProjection =
    proposalData.thirtyYearsBillProjection?.filter((_, index) => {
      return index === 0 || index % 5 === 0 || index === 29;
    });

  const object = {
    animation: false,
    title: {
      text: "Bills for 30 years.",
      color: "#fff",
      textStyle: {
        fontFamily: "Lexend",
        fontSize: "1rem",
        color: "white",
      },
    },
    tooltip: {},

    xAxis: {
      name: "Years",
      nameTextStyle: {
        color: "#fff",
        fontFamily: "Lexend",
      },
      type: "category",
      boundaryGap: false,
      data: data,
      axisLabel: {
        color: "#fff",
      },
    },
    yAxis: {
      name: "Dollars",
      nameTextStyle: {
        color: "#fff",
        fontFamily: "Lexend",
      },
      type: "value",
      min: 0,
      max: proposalData.thirtyYearsBillProjection
        ? proposalData?.thirtyYearsBillProjection[
            proposalData?.thirtyYearsBillProjection?.length - 1
          ]
        : 0,
      interval: proposalData.thirtyYearsBillProjection
        ? proposalData?.thirtyYearsBillProjection[
            proposalData?.thirtyYearsBillProjection?.length / 2
          ]
        : 0,
      scale: true,
      axisLabel: {
        color: "#fff",
      },
    },
    series: [
      {
        type: "graph",
        layout: "none",
        coordinateSystem: "cartesian2d",
        symbolSize: 20,
        label: {
          show: true,
          formatter: function (params) {
            const value = thirtyYearsBillProjection[params.dataIndex];
            if (typeof value === "number") {
              return value.toFixed(2);
            }
            return value;
          },
        },
        edgeSymbol: ["circle", "arrow"],
        edgeSymbolSize: [4, 10],
        data: thirtyYearsBillProjection,
        links: links,
        lineStyle: {
          color: "rgba(254,0,0)",
          width: 4,
          shadowBlur: 10,
          shadowColor: "rgba(0,0,0,0.4)",
        },
        itemStyle: {
          color: "rgba(204,0,0,0.6)",
          shadowBlur: 10,
          shadowColor: "rgba(0,0,0,0.6)",
        },
      },
    ],
  };

  return object;
}

export default createOptionsOfCostLineChart;
