import { useContext } from "react";
import styles from "./BatteryList.module.css";
import trash from "../../assets/img/trash-02.svg";
import { BatteriesContext } from "../../context/BatteriesContext";
import { ProposalContext } from "../../context/ProposalContext";

const BatteryList = ({ index, quantity, reset, setReset }) => {
  const {
    batteries,
    selectedBatteries,
    lastButtonPressed,
    setLastButtonPressed,
    selectedBatteryIndex,
    setSelectedBatteryIndex,
  } = useContext(BatteriesContext);
  const { onlyView } = useContext(ProposalContext)

  /* resetClick() is used to reset the quantity of the battery to 0. */
  const resetClick = () => {
    setSelectedBatteryIndex(index);
    setReset(!reset);
  };

  return (
    <div
      key={batteries[index].id}
      className={`d-flex justify-content-between ${styles.line}`}
    >
      <span className={styles.quantitySpan}>{batteries[index].name}</span>
      <span className={styles.quantitySpan}>{batteries[index].price}</span>
      <span className={styles.quantitySpan}>
        ${batteries[index].price * quantity}
      </span>
      <div className={styles.buttonsContainer}>
        {
          !onlyView &&
          <button
            className={`${styles.button} ${
              lastButtonPressed === "-" && selectedBatteryIndex === index
                ? styles.pressed
                : ""
            }`}
            onClick={() => {
              setSelectedBatteryIndex(index);
              setLastButtonPressed("-");
            }}
          >
            -
          </button>
        }
        <span className={styles.span}>{selectedBatteries[index]}</span>
        {
          !onlyView &&
          <>
            <button
              className={`${styles.button} ${
                lastButtonPressed === "+" && selectedBatteryIndex === index
                  ? styles.pressed
                  : ""
              }`}
              onClick={() => {
                setSelectedBatteryIndex(index);
                setLastButtonPressed("+");
              }}
            >
              +
            </button>

            <img
              className={styles.trash}
              src={trash}
              alt="trash"
              onClick={resetClick}
            />
          </>
        }
      </div>
    </div>
  );
};

export default BatteryList;
