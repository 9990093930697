// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/img/preloadImage/fondo-006.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Batteries_batteriesContainer__b8GKD {
  position: relative;
  width: 100%;
  min-height: 100vh;
  border: none;
}

.Batteries_batteriesSection4Button__7xLGw{
  position: fixed;
  top: 50%;
  left: 1%;
  width: 40px;
  height: 40px;
  background-color: #fbb800;
  cursor: pointer;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.Batteries_batteriesSection4Button__7xLGw img {
  width: 25px;
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);

  height: auto;
}

.Batteries_image__-5C1T {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100;
  object-fit: cover;
  border: none;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}

.Batteries_offCanvasTitle__8eUTG{
  color: #fff;
  font-family: var(--dm-sans);
  font-size: 1.2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Batteries/Batteries.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,QAAQ;EACR,QAAQ;EACR,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,eAAe;EACf,kBAAkB;EAClB,gCAAgC;EAChC,aAAa;AACf;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;;EAEhC,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,yDAAwE;EACxE,4BAA4B;EAC5B,4BAA4B;EAC5B,2BAA2B;EAI3B,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,2BAA2B;EAC3B,iBAAiB;AACnB","sourcesContent":[".batteriesContainer {\n  position: relative;\n  width: 100%;\n  min-height: 100vh;\n  border: none;\n}\n\n.batteriesSection4Button{\n  position: fixed;\n  top: 50%;\n  left: 1%;\n  width: 40px;\n  height: 40px;\n  background-color: #fbb800;\n  cursor: pointer;\n  border-radius: 50%;\n  transform: translate(-50%, -50%);\n  z-index: 1000;\n}\n\n.batteriesSection4Button img {\n  width: 25px;\n  position: absolute;\n  top: 50%;\n  left: 60%;\n  transform: translate(-50%, -50%);\n\n  height: auto;\n}\n\n.image {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  min-height: 100;\n  object-fit: cover;\n  border: none;\n  background-image: url(\"../../../public/img/preloadImage/fondo-006.webp\");\n  background-repeat: no-repeat;\n  background-attachment: fixed;\n  background-position: center;\n  -webkit-background-size: cover;\n  -moz-background-size: cover;\n  -o-background-size: cover;\n  background-size: cover;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding-top: 100px;\n}\n\n.offCanvasTitle{\n  color: #fff;\n  font-family: var(--dm-sans);\n  font-size: 1.2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"batteriesContainer": `Batteries_batteriesContainer__b8GKD`,
	"batteriesSection4Button": `Batteries_batteriesSection4Button__7xLGw`,
	"image": `Batteries_image__-5C1T`,
	"offCanvasTitle": `Batteries_offCanvasTitle__8eUTG`
};
export default ___CSS_LOADER_EXPORT___;
