import styles from "./Modal.module.css";
import Modules from "./Tables/Modules";
import Inverters from "./Tables/Inverters";
import { useContext } from "react";
import SystemInfo from "./Tables/SystemInfo";
import { ProposalContext } from "../../../context/ProposalContext";
import { MenuContext } from "../../../context/MenuContext";

const Modal = ({ title, modules, inverters, showModal, systemInfo }) => {
  const { setShowModal } = useContext(MenuContext);
  const { moduleId, setModuleId, inverterId, setInverterId } =
    useContext(ProposalContext);

  
  const closeModal = () => {

    /* Set the modal state in false */
      setShowModal((prev) => {

      /* Set all the modal states in false */
      let keys = Object.keys(prev);
      keys.map((prop) => (prev[prop] = false));
      return { ...prev };
    });
  };

  return (
    <div className={styles.modalContainer}>
      <div className={styles.headerContainer}>
        <h3 className={styles.headerContainerTitle}>{title}</h3>
        <div className={styles.headerContainerImg}>
          <img
            id={title.toLowerCase()}
            onClick={closeModal}
            src="/icons/close_ring_duotone.svg"
            alt="close"
          />
        </div>
      </div>
      <div className={styles.tableContainer}>
        {showModal.modules && (
          <Modules
            modules={modules}
            moduleId={moduleId}
            setModuleId={setModuleId}
            setShowModal={setShowModal}
          />
        )}
        {showModal.inverters && (
          <Inverters
            inverters={inverters}
            inverterId={inverterId}
            setInverterId={setInverterId}
            setShowModal={setShowModal}
          />
        )}
        {showModal.systemInfo && <SystemInfo info={systemInfo} />}
      </div>
    </div>
  );
};

export default Modal;
