import React, { useContext, useEffect, useState } from "react";
import styles from "./Section6.module.css";
import { Dropdown } from "react-bootstrap";
import { FinancialContext } from "../../context/FinancialContext";
import { ProposalContext } from "../../context/ProposalContext";

const Section6Part2 = () => {
  /* Destructuring */
  const { proposalData, onlyView } = useContext(ProposalContext);
  const { financials} = proposalData;
  const { Banks } = financials;
  const {
    selectedBank,
    setSelectedBank,
    selectedPaymentOption,
    setSelectedPaymentOption,
    paymentOptions,
    setPaymentOptions,
  } = useContext(FinancialContext);
  const [isInitialRender, setIsInitialRender] = useState(true);



  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
    } else {
      setSelectedPaymentOption("");
    }
  }, [paymentOptions]);

  const handleBankSelect = (event) => {
    const bank = event.target.id;
    setSelectedBank(bank); //puedes cargar las formas de pago desde el backend
    setPaymentOptions(financials[bank]);
  };

  const handleClickInSelector = (event) => {
    let id = parseInt(event.target.id);
    let selected = paymentOptions.find((bank) => {
      return bank.id === id;
    });
    
    setSelectedPaymentOption(selected);
  };

  return (
    <div className={`container ${styles.part2Container}`}>
      <br />
      <div className={`row ${styles.mobileRow}`}>
        <div className={`col-lg-8 ${styles.zIndex} ${styles.centeredElements}`}>
          <div className={`${styles.rect6Part2}`}>
            <div className="row">
              <div
                className={`col-md-12 ${styles.centeredElements} ${styles.gapForDropdown}`}
              >
                <Dropdown>
                  <Dropdown.Toggle
                    className={styles.dropdownToggle}
                    variant="success"
                    id="bank-dropdown"
                    disabled={onlyView}
                  >
                    {selectedBank}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className={styles.dropdownMenuBanks}>
                    {Banks.map((bank,index) => {
              
                      return (
                        <Dropdown.Item
                          key={index}
                          id={bank}
                          className={styles.dropdownItem}
                          onClick={(event) => handleBankSelect(event)}
                        >
                          {bank}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>

                {/* Selector para mostrar las opciones de pago */}
                <Dropdown>
                  <Dropdown.Toggle
                    className={styles.dropdownToggle}
                    variant="success"
                    id="payment-dropdown"
                    disabled={onlyView}
                  >
                    {selectedPaymentOption.name || "Select Payment Option"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    className={`${styles.dropdownMenu} ${styles.scroll}`}
                  >
                    {paymentOptions?.map((option,index) => {
                      return (
                        <Dropdown.Item
                          className={styles.dropdownItem}
                          key={index}
                          id={option.id}
                          onClick={(event) => handleClickInSelector(event)}
                        >
                          {option.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="container d-flex flex-column justify-content-center align-items-center mt-4">
              <div className={styles.containerInfo}>
                <p className={styles.tMonths}>Dealer Fee</p>
                <p className={styles.tMonthsCash}>
                  {selectedPaymentOption.dealerFee || "-"}
                </p>
              </div>
              <div className={styles.containerInfo}>
                <p className={styles.tMonths}>Interest Rate</p>
                <p className={styles.tMonthsCash}>
                  {selectedPaymentOption.interestRate || "-"}
                </p>
              </div>
              <p className={styles.legend}>Final terms will be provided in your loan agreement.</p>
            </div>
            <br />
          </div>
        </div>
        <div className={`col-lg-4 ${styles.centeredElements} overflow-hidden`}>
          <div
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            className={` ${styles.containerMobile} `}
          >
            <div className={styles.customH1Sec6Part2}>Financial options</div>
            <div className={styles.textSec6Part2}> Select Financial Option</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section6Part2;
