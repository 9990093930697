// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/img/fondo-col2.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.part2_part2Container__gv11H {
  flex: 1 1;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
}

.part2_title__O-BP\\+ {
  font-size: 3.125rem;
  font-family: var(--lexend);
  font-weight: 500;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  color: #ffffff;
}

.part2_information__Znybj {
  padding: 0 20px;
  font-family: var(--dm-sans);
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
  align-items: right;
  height: 125px;
  color: #fff;
  margin-bottom: 0;
}

@media (max-width: 767.98px) { 

  .part2_title__O-BP\\+{
    font-size: 2.45rem;
    padding: 0;
    text-align: center;
    padding-top: 16px;
  }

  .part2_information__Znybj {
    font-size: 1rem;
    font-weight: 400;
  }

}`, "",{"version":3,"sources":["webpack://./src/components/Section3Reloaded/Part2/part2.module.css"],"names":[],"mappings":"AAAA;EACE,SAAO;EACP,yDAAoD;EACpD,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,0BAA0B;EAC1B,gBAAgB;EAChB,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,2BAA2B;EAC3B,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,gBAAgB;AAClB;;AAEA;;EAEE;IACE,kBAAkB;IAClB,UAAU;IACV,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB;;AAEF","sourcesContent":[".part2Container {\n  flex: 1;\n  background-image: url(\"/public/img/fondo-col2.webp\");\n  background-size: cover;\n}\n\n.title {\n  font-size: 3.125rem;\n  font-family: var(--lexend);\n  font-weight: 500;\n  height: 250px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 0;\n  color: #ffffff;\n}\n\n.information {\n  padding: 0 20px;\n  font-family: var(--dm-sans);\n  font-size: 1.1rem;\n  font-weight: 500;\n  text-align: center;\n  align-items: right;\n  height: 125px;\n  color: #fff;\n  margin-bottom: 0;\n}\n\n@media (max-width: 767.98px) { \n\n  .title{\n    font-size: 2.45rem;\n    padding: 0;\n    text-align: center;\n    padding-top: 16px;\n  }\n\n  .information {\n    font-size: 1rem;\n    font-weight: 400;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"part2Container": `part2_part2Container__gv11H`,
	"title": `part2_title__O-BP+`,
	"information": `part2_information__Znybj`
};
export default ___CSS_LOADER_EXPORT___;
