// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AutoSave_autoSaveStatusContainer__vdcQI{
    width: 20%;
    background-color: #5CA821;
    border-radius: 10px;
    padding: 8px 16px;
    position: fixed;
    left: 50%;  
    transform: translateX(-50%);  
    top: 7px;
    z-index: 10000;
    animation: AutoSave_animate__QtCME 1s ease;
}

.AutoSave_autoSaveStatusContainerError__YIuT4{
    background-color: var(--primary-color)!important;
}

.AutoSave_autoSaveStatusContainerText__pRiDe{
    color: #fff;
    text-align: center;
    font-family: var(--lexend); 
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

@media (max-width: 767.98px){
    .AutoSave_autoSaveStatusContainer__vdcQI{
        width: 30%;
    }
}

@keyframes AutoSave_animate__QtCME {
    from {
        top: -120px;
    }

    to{
        top: 7px;
    }


}`, "",{"version":3,"sources":["webpack://./src/components/AutoSaveAlert/AutoSave.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,yBAAyB;IACzB,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;IACf,SAAS;IACT,2BAA2B;IAC3B,QAAQ;IACR,cAAc;IACd,0CAA0B;AAC9B;;AAEA;IACI,gDAAgD;AACpD;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,0BAA0B;IAC1B,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,WAAW;IACf;;IAEA;QACI,QAAQ;IACZ;;;AAGJ","sourcesContent":[".autoSaveStatusContainer{\n    width: 20%;\n    background-color: #5CA821;\n    border-radius: 10px;\n    padding: 8px 16px;\n    position: fixed;\n    left: 50%;  \n    transform: translateX(-50%);  \n    top: 7px;\n    z-index: 10000;\n    animation: animate 1s ease;\n}\n\n.autoSaveStatusContainerError{\n    background-color: var(--primary-color)!important;\n}\n\n.autoSaveStatusContainerText{\n    color: #fff;\n    text-align: center;\n    font-family: var(--lexend); \n    font-size: 1rem;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;\n    margin: 0;\n}\n\n@media (max-width: 767.98px){\n    .autoSaveStatusContainer{\n        width: 30%;\n    }\n}\n\n@keyframes animate {\n    from {\n        top: -120px;\n    }\n\n    to{\n        top: 7px;\n    }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"autoSaveStatusContainer": `AutoSave_autoSaveStatusContainer__vdcQI`,
	"animate": `AutoSave_animate__QtCME`,
	"autoSaveStatusContainerError": `AutoSave_autoSaveStatusContainerError__YIuT4`,
	"autoSaveStatusContainerText": `AutoSave_autoSaveStatusContainerText__pRiDe`
};
export default ___CSS_LOADER_EXPORT___;
