import styles from "./Circle.module.css";
import CircleWhite from "../../assets/img/CircleWhite.svg";
import CircleYellow from "../../assets/img/miniCircleYellow.svg";
import bgCircle from "../../assets/img/bg-minicircle.svg";
import { useState } from "react";

const Circle = ({ variable, handleClick, id, formIsSelected, text,left }) => {
  /* if the state is true, the tooltip will be displayed */
  const [inDiv, setInDiv] = useState(false);
  return (
    <div
      id={id}
      className={styles[variable]}
      onClick={(event) => handleClick(event)}
      onMouseEnter={() => setInDiv(true)}
      onMouseLeave={() => setInDiv(false)}
    >
      <div className={styles.imageContainer}>
        <img className={styles.imageBg} src={bgCircle} alt="Button" />
        <img
          className={styles.imageCircle}
          src={formIsSelected ? CircleYellow : CircleWhite}
          alt="Button"
        />
      </div>
      {inDiv && <div className={`${styles.tooltip} ${left ? styles[left]: ""}`}>{text}</div>}
    </div>
  );
};

export default Circle;
