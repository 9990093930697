/* JSON con Opciones a renderizar en el Setup */
import imagesFont from "./imagesFont.json";
import { useContext } from "react";
import styles from "./MenuOpen.module.css";
import { MenuContext } from "../../context/MenuContext";



/* SETUP  */

const MenuOpen = ({handleClick}) => {
  const { menuOpen, setMenuOpen } = useContext(MenuContext);

  return (
    <div className={styles.menuOpenContainer}>
      <div className={styles.titleContainer}>
        <h3>SETUP</h3>
        <div className={`${styles.imageContainer} ${styles.imageContainerClose}`} onClick={() => setMenuOpen(!menuOpen)}>
          <img src="/icons/close_ring_duotone.svg" alt="Botón de cierre" /> 
        </div>
      </div>
      <div className={styles.containerOfUl} >
        {imagesFont.map((item) => {
          return (
            <a href={item.section? `#section${item.section}` : "#null"} className={`${styles.listContainer} ${item.name === "adders" && styles.line}`} onClick={(event) =>{handleClick(event)} } id={item.name} key={item.name}>
              <div className={styles.displayNameContainer}>
                <span className={styles.displayName} >{item.displayName}</span>
              </div>
              <div className={styles.imageContainer}>
                  <span key={item.name}className={styles.iconImage}>
                  <img  src={item.url} alt={item.name} />
                </span>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default MenuOpen;
