import Menu from "./components/Menu/Menu";
import Section1 from "./components/Section1/Section1";
import Section2 from "./components/Section2/Section2";
import Section4 from "./components/Section4/Section4";
import Section5 from "./components/Section5/Section5";
import Section6 from "./components/Section6/Section6";
import Section7 from "./components/Section7/Section7";
import Section8 from "./components/Section8/Section8";
import Section9 from "./components/Section9/Section9";
import SystemPricing from "./components/Menu/SystemPricing/SystemPricing";
import "./App.css";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "./context/AppContext";
import Adders from "./components/Menu/Adders/Adders";
import Loader from "./components/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import Settings from "./components/Menu/Settings/Settings";
import useDataToCalculate from "./CustomHooks/useDataToCalculate";
import CalcsAlert from "./components/CalcsAlert/CalcsAlert";
import AutoSave from "./components/AutoSaveAlert/AutoSave";
import AOS from "aos";
import Modules from "./components/Section2/Modal/Modal";
import { Spin as Hamburger } from "hamburger-react";
import useProposal from "./CustomHooks/useProposal";
import useSave from "./CustomHooks/useSave";
import VideoComponent from "./components/VideoComponent/VideoComponent";
import MonthlyConsumptionModal from "./components/Section7/Modal/MonthlyConsumptionModal";
import Warning from "./components/Warning/Warning";
import { AuthContext } from "./context/AuthContext";
import { BatteriesContext } from "./context/BatteriesContext";
import { AddersContext } from "./context/AddersContext";
import { FinancialContext } from "./context/FinancialContext";
import { ProposalContext } from "./context/ProposalContext";
import { MenuContext } from "./context/MenuContext";
import Section3R from "./components/Section3Reloaded/Section3R";
import OverrideModal from "./components/Menu/SystemPricing/CostOverview/Tables/OverrideModal/OverrideModal";
import Batteries from "./components/Batteries/Batteries";
import ChangeConsumptionModal from "./components/Batteries/ChangeConsumption/ChangeConsumptionModal";
import { jwtDecode } from "jwt-decode";

import ShareModal from "./components/Menu/SystemPricing/ShareModal/ShareModal";
import ShadeReportPDF from "./components/ShadeReportPDF/ShadeReportPDF";

const Proposal = () => {
  /* Global states */
  const {
    setApplyChangesStatus,
    applyChangesStatus,
    isUserActive,
    showModalWarning,
    setShowModalWarning,
    setSending,
    sending,
    shareModal,
  } = useContext(AppContext);
  const { token } = useContext(AuthContext);
  const {
    showModal,
    menuOpen,
    setMenuOpen,
    menuAdder,
    menuSettings,
    menuSystemPricing,
    setMenuSystemPricing,
    setMenuAdder,
    modalOverride,
  } = useContext(MenuContext);
  const {
    proposalData,
    setProposalData,
    projectId,
    setProjectId,
    information,
    error,
    autoSaveStatus,
    setAutoSaveStatus,
    isFetching,
    setSaveSuccess,
    setProposalStatusLegend,
    saveSuccess,
    onlyView,
    setOnlyView,
    isShadeReportPDF,
    setIsShadeReportPDF,
  } = useContext(ProposalContext);

  const {
    batteries,
    selectedBatteries,
    monthlyConsumptionModal,
    changeConsumptionModal,
    batteriesSettings,
    setBatteriesSettings,
    profileSelected,
  } = useContext(BatteriesContext);
  const { adders } = useContext(AddersContext);
  const { selectedPaymentOption } = useContext(FinancialContext);

  /* Custom Hooks */
  const [getProposal, getToken] = useProposal();
  const { saveProposalAuto } = useSave();
  const [config, getCalcs] = useDataToCalculate();

  /* Hook */
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();
  // const history = useHistory()

  /* Component States */
  const [intervalStatus, setIntervalStatus] = useState(false);
  let cut = pathname.split("/");
  let id = cut[cut.length - 1];

  useEffect(() => {
    if (hash) {
      document.querySelector(hash)?.scrollIntoView({ behavior: "smooth" });
      navigate(pathname, { replace: true });
    }
  }, [hash]);

  useEffect(() => {
    if (saveSuccess || sending)
      setTimeout(() => {
        setSaveSuccess(false);
        setSending(false);
        setProposalStatusLegend("");
      }, 3000);
  }, [saveSuccess, sending]);

  /* Setting project Id */
  useEffect(() => {
    setProjectId(id);
  }, []);

  /* Get token if projectId exists */
  useEffect(() => {
    projectId && !token && getToken();
  }, [projectId]);

  /* Get proposal if token exists */
  useEffect(() => {
    token && getProposal();
  }, [token]);

  /* Get calculations and refresh proposal if token, config and proposalData exists and the timeout is x second */
  useEffect(() => {
    let timeoutCalcs;

    clearTimeout(timeoutCalcs);

    timeoutCalcs = setTimeout(() => {
      if (!isFetching && token && config)
        getCalcs(config, "calculations").then((calcs) => {
          const projectData = calcs.project;
          /* delete project from calcs for avoid to save it in the proposalData */
          delete calcs.project;
          setProposalData((prev) => ({
            ...prev,
            ...calcs,
            project: { ...prev.project, ...projectData },
          }));
          setApplyChangesStatus("Changes Applied");
        });
    }, 1000);

    return () => {
      clearTimeout(timeoutCalcs);
    };
  }, [
    config,
    isFetching,
    token,
    information,
    batteries,
    adders,
    selectedPaymentOption,
    profileSelected,
  ]);

  /* Auto Save every x seconds if token and isUserActive exists */
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIntervalStatus(!intervalStatus);
    }, 50000);

    if (token && !isFetching && !onlyView) {
      if (isUserActive && intervalStatus) {
        saveProposalAuto();
        setAutoSaveStatus("Proposal Saved");
        setIntervalStatus(false);
      }
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [token, isUserActive, isFetching, selectedBatteries, intervalStatus]);

  /* Toggle menuAdder and menuSystemPricing */
  const handleClick = () => {
    setMenuOpen(!menuOpen);
    setMenuAdder(false);
    setMenuSystemPricing(false);
  };

  /* Initialize AOS (animation library) */
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  useEffect(() => {
    if (token) {
      const tokenData = jwtDecode(token);
      setOnlyView(tokenData?.onlyView);
    }
  }, [token]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.get("shadeReportPDF") === "true" && token) {
      setIsShadeReportPDF(true);
    }
  }, []);

  useEffect(() => {
    if (!isFetching && isShadeReportPDF) {
      navigate("/project/proposal/shadeReportPDF" + "?token=" + token);
    }
  }, [isFetching]);

  return isFetching ? (
    <Loader />
  ) : error ? (
    <Loader />
  ) : (
    <>
      <header className="App-header">
        <div className="menu">
          <a
            href="https://vertexbeta.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="anchorHeader"
          >
            VertexBeta
          </a>
          {batteriesSettings && (
            <button
              className="buttonGo"
              onClick={() => {
                setBatteriesSettings((prev) => !prev);
                window.scrollTo(0, 0);
              }}
            >
              Back to proposal
            </button>
          )}
          <div className="view-hamburguer-icon">
            <Hamburger
              color="#fff"
              toggled={menuOpen}
              toggle={setMenuOpen}
              onToggle={handleClick}
            />
          </div>
        </div>
      </header>

      {(showModal?.modules ||
        showModal?.inverters ||
        showModal?.systemInfo) && (
        <Modules
          title={
            showModal.modules
              ? "Modules"
              : showModal.inverters
              ? "Inverters"
              : "System Size"
          }
          modules={proposalData.modules}
          inverters={proposalData.inverters}
          systemInfo={proposalData.systemSizeInfo}
          showModal={showModal}
        />
      )}
      {monthlyConsumptionModal && <MonthlyConsumptionModal />}
      {autoSaveStatus && <AutoSave />}
      {applyChangesStatus && <CalcsAlert />}
      <Menu />
      {/* Menú desplegable */}
      {/* Menu de Adders y SystemPricing (OCULTOS hasta que se haga click en la opción) */}
      {!menuOpen && menuAdder && <Adders />}
      {!menuOpen && menuSystemPricing && <SystemPricing />}
      {!menuOpen && menuSettings && <Settings />}
      {showModalWarning && (
        <Warning setShowModalWarning={setShowModalWarning} />
      )}
      {modalOverride && <OverrideModal />}
      {changeConsumptionModal && <ChangeConsumptionModal />}
      {shareModal && <ShareModal />}
      {batteriesSettings ? (
        <Batteries />
      ) : isShadeReportPDF ? (
        <ShadeReportPDF />
      ) : (
        <section>
          <Section1 />
          {/* Section 2 Solar Model -> System Size - Panels - Inverter - Energy Offset - Imagen desde satelite */}
          <Section2 />
          {/* Section 3 Energy Cost / Investing in a Sustainable Future*/}
          <Section3R />
          {/*       <Section3 />  Eliminar */}
          <div className="videoSection">
            <VideoComponent
              id={"fondo_004"}
              source={"/video/fondo_004.mp4"}
              background={"/img/preloadImage/fondo-004.webp"}
            />

            {/* Section 4 Battery Options (Total Estimated Savings) */}
            <Section4 offCanvas={false} />
            {/* Auxiliary products (2 forms and image of a home) */}
            {/* <Section5 /> for the moment none */}
          </div>
          {/* Save with Solar */}
          <Section6 />
          {/* Video Bg for Section 7 - 8 - 9 */}
          <div className="videoSection">
            <VideoComponent
              id={"fondo_007"}
              source={"/video/fondo_007.mp4"}
              background={"/img/preloadImage/fondo-007.webp"}
            />
            {/* Historical Energy Consumption vs Expected Solar Production */}
            <Section7 />
            {/* Proposal Details */}
            <Section8 />
            {/* Next Steps */}
            <Section9 />
          </div>
        </section>
      )}
    </>
  );
};

export default Proposal;
