import { useState } from "react";
import styles from "./Roofing.module.css";

const Roofing = () => {
  const [selectRoofing, setSelectRoofing] = useState(false);
  const [formIsSelected, setFormIsSelected] = useState(false);
  const options = [
    "Metal Roof (Cal pac, curragated, standing sea,)",
    "Flat Roof (Balasted, tar & gravel) - by quote",
    "Concrete tiles (Incl, tile replacement)",
    "Light weight tiles Comp Out (<9lbs tile limit) - by quote",
    "Reroof (partial, full, picture frame) - by quote",
  ];

  const handleClickInSelect = () => setSelectRoofing(!selectRoofing);

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setFormIsSelected(isChecked);
  };

  return (
    <div className={styles.cont}>
      <div
        className={`${styles.inputCheckboxContainer} ${styles.principalCheckBoxMargin}`}
      >
        <input
          id="abledform"
          type="checkbox"
          onChange={handleCheckboxChange}
          checked={formIsSelected}
        />
        <h3 className="text-center m-0">Roof Replacement Services</h3>
      </div>
      <form
        className={`${styles.form} ${formIsSelected ? styles.appears : ""}`}
      >
        <div className={styles.roofingContainer}>
          <div className={styles.optionContainer}>
            <input
              className={styles.checkbox}
              type="checkbox"
              id="surfacetype"
              disabled={!formIsSelected}
            />
            <div className={styles.info}>
              <p>Surface Type</p>
              <p>$0.00</p>
            </div>
          </div>
          <div onClick={handleClickInSelect} className={styles.customSelect}>
            Flat Roof (TPO,Rolled Comr)
          </div>
          {selectRoofing && formIsSelected && (
            <div className={styles.customSelectOptions}>
              {options.map((option) => (
                <div key={option} className={styles.customSelectOpiton}>
                  {option}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={styles.firstInputsContainer}>
          <div className={styles.inputContainer}>
            <label className={styles.inputContainerLabel} htmlFor="material">
              Existing Roofing Material
            </label>
            <input
              id="material"
              className={styles.inputContainerInput}
              type="text"
              placeholder="Asphalt Shingles"
              disabled={!formIsSelected}
            />
            <div className={styles.inputCheckboxContainer}>
              <label htmlFor="create">New Roof - Create a Quote</label>
              <input id="create" type="checkbox" disabled={!formIsSelected} />
            </div>
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.inputContainerLabel} htmlFor="trof">
              Roofing Type
            </label>
            <input
              id="trof"
              className={styles.inputContainerInput}
              type="text"
              placeholder="Ceramic Tiles"
              disabled={!formIsSelected}
            />
            <label className={styles.inputContainerLabel} htmlFor="roofcost">
              New Roof Cost
            </label>
            <input
              id="roofcost"
              className={styles.inputContainerInput}
              type="text"
              placeholder="$10,611.50"
              disabled={!formIsSelected}
            />
          </div>
        </div>
        <div className={styles.secondInputsContainer}>
          <div className={styles.inputContainerTwo}>
            <label className={styles.inputContainerLabel} htmlFor="square">
              Roof Square Footage
            </label>
            <input
              id="square"
              className={styles.inputContainerInput}
              type="text"
              placeholder="1500"
              disabled={!formIsSelected}
            />
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.container}>
              <div className={styles.inputContainer}>
                <label className={styles.inputContainerLabel} htmlFor="layers">
                  # of Existing Layers
                </label>
                <input
                  id="layers"
                  className={styles.inputContainerInput}
                  type="text"
                  placeholder="1"
                  disabled={!formIsSelected}
                />
              </div>
              <div className={styles.inputContainer}>
                <label className={styles.inputContainerLabel} htmlFor="degrees">
                  Roof Pitch (Degrees)
                </label>
                <input
                  id="degrees"
                  className={styles.inputContainerInput}
                  type="text"
                  placeholder="10"
                  disabled={!formIsSelected}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.inputContainer}>
            <label className={styles.inputContainerLabel} htmlFor="overlay">
              Overlay/Tear Off
            </label>
            <input
              id="overlay"
              className={styles.inputContainerInput}
              type="text"
              placeholder="Tear Off"
              disabled={!formIsSelected}
            />
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.inputContainerLabel} htmlFor="issues">
              Access Issues
            </label>
            <input
              id="issues"
              className={styles.inputContainerInput}
              type="text"
              placeholder="No"
              disabled={!formIsSelected}
            />
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.inputContainerLabel} htmlFor="newPlywood">
              Is a new Plywood Needed
            </label>
            <input
              id="newPlywood"
              className={styles.inputContainerInput}
              type="text"
              placeholder="No"
              disabled={!formIsSelected}
            />
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.inputCheckboxContainer}>
            <label htmlFor="roofSquares">Input Roof Squares</label>
            <input
              type="checkbox"
              id="roofSquares"
              disabled={!formIsSelected}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Roofing;
