import { createContext, useEffect, useState } from "react";

const BatteriesContext = createContext();

const BatteriesProvider = ({ children }) => {
  /* Boolean */
  const [changeConsumptionModal, setChangeConsumptionModal] = useState(false); // Modal of Change Consumption "<Section4/>" component
  const [monthlyConsumptionModal, setMonthlyConsumptionModal] = useState(false); //  Modal of Monthly Consumption "<Section7/>" component
  const [selfConsumption, setSelfConsumption] = useState(false); // Modal of Monthly Consumption in batteries "<Section4/>"
  const [noData, setNoData] = useState(true); // Dettect if there is data to show in the chart of "<Section4/>", if there is no data, the chart will be disabled

  /* Number */
  const [selectedBatteryIndex, setSelectedBatteryIndex] = useState(0); // Index of the battery selected to search the information in the array of batteries
  const [SC_chartMonthSelected, setSC_chartMonthSelected] = useState(0); // Number with the month selected by the user in the chart of "<Section4/>" component. Is the index of the array of months

  /* Array */
  const [tariffs, setTariffs] = useState([]); // Array of Objects with the information of the tariffs
  const [consumptionsProfiles, setConsumptionsProfiles] = useState([]); // Array of Objects with the information of the consumption profiles
  const [batteries, setBatteries] = useState([]); // Array of Objects with the information of the batteries
  const [monthsConsumptions, setMonthsConsumptions] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]); // Array of numbers with the monthly consumptions of the user in the chart of "<Section7/>", positions 0-11 are the months of the year and the position 12 is the total of the year
  const [selectedBatteries, setSelectedBatteries] = useState(null); // Array of numbers with the quantity of batteries selected by the user
  const [monthlySavings, setMonthlySavings] = useState(null); // Array of months  with the monthly savings of the user in the chart of "<Section4/>" component

  /* Object */
  const [selectedTariff, setSelectedTariff] = useState(null); // Object with the information of the actual tariff selected by the user
  const [mcSettings, setMcSettings] = useState({}); // Object with the information of the settings of the monthly consumption chart, The user only can modify propertie "max"
  const [selectedBattery, setSelectedBattery] = useState(null); // Object with the information of the actual battery selected by the user
  const [profileSelected, setProfileSelected] = useState(null); // Object with the information of the actual consumption profile selected by the user
  const [customProfileSelected, setCustomProfileSelected] = useState(null); // Object with the information of the actual custom profile selected by the user

  const [customProfiles, setCustomProfiles] = useState([]); // Array of Objects with the information of the custom profiles created by the user

  /* String */
  const [lastButtonPressed, setLastButtonPressed] = useState(null); // String with the last button pressed by the user, "+" or "-"

  const [batteriesSettings, setBatteriesSettings] = useState(false);
  const [scrollYPosition, setScrollYPosition] = useState(null);


  useEffect(() => {
    if (batteriesSettings) {
      setScrollYPosition(window.scrollY);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: scrollYPosition,
        behavior: "instant"
      })
    }
  }, [batteriesSettings]);

  /* Dettect if monthlySavings and profileSelected are not false and set noData for enable or disable charts */
  useEffect(() => {
    if (!monthlySavings || !profileSelected) {
      setNoData(false);
    } else {
      setNoData(true);
    }
  }, [monthlySavings, profileSelected]);

  /* sets the array of monthly consumptions for each change of "mcSettings" (causes refresh of the graph in section 7) */
  useEffect(() => {
    let monthsConsumptionsArr = [...monthsConsumptions];

    monthsConsumptionsArr = monthsConsumptionsArr.map((val) => {
      if (val > mcSettings.max) {
        return (val = mcSettings.max);
      }
      return val;
    });

    setMonthsConsumptions(monthsConsumptionsArr);
  }, [mcSettings]);

  return (
    <BatteriesContext.Provider
      value={{
        batteries,
        setBatteries,
        selectedBatteries,
        setSelectedBatteries,
        lastButtonPressed,
        setLastButtonPressed,
        selectedBatteryIndex,
        setSelectedBatteryIndex,
        selectedBattery,
        setSelectedBattery,
        consumptionsProfiles,
        setConsumptionsProfiles,
        profileSelected,
        setProfileSelected,
        SC_chartMonthSelected,
        setSC_chartMonthSelected,
        monthlyConsumptionModal,
        setMonthlyConsumptionModal,
        monthsConsumptions,
        setMonthsConsumptions,
        monthlySavings,
        setMonthlySavings,
        mcSettings,
        setMcSettings,
        noData,
        setNoData,
        selfConsumption,
        setSelfConsumption,
        customProfiles,
        setCustomProfiles,
        customProfileSelected,
        setCustomProfileSelected,
        changeConsumptionModal,
        setChangeConsumptionModal,
        tariffs,
        setTariffs,
        selectedTariff,
        setSelectedTariff,
        batteriesSettings,
        setBatteriesSettings,
      }}
    >
      {children}
    </BatteriesContext.Provider>
  );
};

export { BatteriesProvider, BatteriesContext };
