function createOptionsOfConsumptionProfile (profileSelected){
    const object = {
      animation: false,
        textStyle: {
          fontFamily: "DM Sans",
        },
        tooltip: {
          trigger: "axis",
          textStyle: {
            color: "black",
          },
  
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              19, 20, 21, 22, 23,
            ],
            axisLabel: {
              color: "#fff",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              color: "#fff",
            },
          },
        ],
        series: [
          {
            name: "Consumption",
            type: "line",
            stack: "Total",
            areaStyle: {
              opacity: 0.4,
            },
            color: "#ffc010",
            shadowBlur: 10,
            shadowColor: "rgba(0, 0, 0, 0.3)",
            data: profileSelected?.consumptionPercentage,
            smooth: true,
          },
        ],
      };
  
      return object;
  }
  
  export default createOptionsOfConsumptionProfile;