import styles from "./Tables.module.css";

const Modules = ({ modules, setModuleId, moduleId, setShowModal }) => {

  /* Set the module id and set the modal state in false */
  const handleClick = (event) => {
    const id = event.currentTarget.id;
    setModuleId(id);
    setShowModal((prev) => !prev);
  };

  return (
    <div className={styles.containerTable}>
      <div className={styles.titlesContainer}>
        <p>Name</p>
        <p>Manufacter</p>
        <p>Maximum Power Rating</p>
      </div>
      <div className={styles.objectsContainer}>
        {modules.map((module) => {
          return (
            <div
              key={module.id}
              id={module.id}
              className={`${styles.objectContainer} ${moduleId === module?.id ? styles.selected : ""}`} 
              onClick={(module) => handleClick(module)}
            >
              <span>{module?.name || "-"}</span>
              <span>{module?.manufacturer || "-"}</span>
              <span>{module?.maximumPowerRating || "-"}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Modules;
