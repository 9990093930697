import styles from "./Tables.module.css";

const Inverters = ({ inverters, inverterId, setInverterId, setShowModal }) => {

  /* Set the inverter id and set the modal state in false */
  const handleClick = (inverter) => {
    setInverterId(inverter.currentTarget.id);
    setShowModal((prev) => !prev);
  };

  return (
    <div className={styles.containerTable}>
      <div className={styles.titlesContainer}>
        <p>Name</p>
        <p>Manufacter</p>
      </div>
      <div className={styles.objectsContainer}>
        {inverters.map((inverter) => {
          return (
            <div
              key={inverter?.id}
              className={`${styles.objectContainer} ${
                inverterId === inverter?.id ? styles.selected : ""
              }`}
              id={inverter?.id}
              onClick={(inverter) => handleClick(inverter)}
            >
              <span>{inverter?.name || "-"}</span>
              <span>{inverter?.manufacturer || "-"}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Inverters;
