import React, { useContext, useEffect, useState } from "react";
import styles from "./Section2.module.css";
import { rekonUrl } from "../../context/AppContext";
import { MenuContext } from "../../context/MenuContext";
import { NavLink } from "react-router-dom";
import VideoComponent from "../VideoComponent/VideoComponent";
import { AuthContext } from "../../context/AuthContext";
import { ProposalContext } from "../../context/ProposalContext";

const Section2 = ({ reference }) => {
  /* Global states */
  const { setShowModal } = useContext(MenuContext);
  const { token } = useContext(AuthContext);
  const { proposalData, moduleId, inverterId, onlyView } =
    useContext(ProposalContext);

  /* Component states */
  const [moduleInfo, setModuleInfo] = useState("");
  const [inverterInfo, setInverterInfo] = useState("");

  /* Set modal to show */
  const handleClickInSelector = (event) => {
    const id = event.currentTarget.id;
    setShowModal((prev) => {
      let keys = Object.keys(prev);
      keys.map((prop) => (prev[prop] = false));
      return { ...prev, [id]: true };
    });
  };

  /* Set the module information finding by index */
  const module = () => {
    const index = proposalData.modules.findIndex(
      (module) => module.id === moduleId
    );

    if (index !== -1) setModuleInfo(proposalData.modules[index]);
  };

  /* Set the inverter information finding by index */
  const inverter = () => {
    const index = proposalData.inverters.findIndex(
      (inverter) => inverter.id === inverterId
    );

    if (index !== -1) setInverterInfo(proposalData.inverters[index]);
  };

  /* eject the functions when the component is mounted and every time the id's change */

  useEffect(() => {
    module();
  }, [moduleId]);

  useEffect(() => {
    inverter();
  }, [inverterId]);

  return (
    <section id="section2" className={styles.videoSection} ref={reference}>
      <VideoComponent
        id={"fondo_002"}
        source={"/video/fondo_002.mp4"}
        background={"/img/preloadImage/fondo-002.webp"}
      />
      <div className={`container-fluid`}>
        <div className={`row ${styles.rowMedium} overflow-hidden`}>
          <div className={`col-lg-6 ${styles.padTopCol1}`}>
            <h2 className={styles.customH1}>Customized Solar Solution</h2>
            <h2 className={styles.customH2Section2}>Your Solar model</h2>
            <div className={styles.pLeft60}>
              <div className={styles.marginBottom}>
                <div
                  id={"systemInfo"}
                  className={`${styles.infoSolarModel} ${styles.modulesDiv}`}
                  onClick={(event) => handleClickInSelector(event)}
                >
                  {proposalData?.realSystemCapacity ||
                    proposalData?.systemCapacity}
                  kW
                  <img src="/icons/eye.svg" alt="view" />
                </div>
                <div className={styles.infoDetails}>System Size</div>
              </div>

              <div className={styles.marginBottom}>
                <div
                  id="modules"
                  className={`${styles.infoSolarModel} ${styles.modulesDiv} ${
                    onlyView && styles.onlyView
                  }`}
                  onClick={
                    !onlyView
                      ? (event) => handleClickInSelector(event)
                      : () => {}
                  } //-----------------
                >
                  {moduleInfo.name || "Select your panel"}
                  {!onlyView && <img src="/icons/edit.svg" alt="can change" />}
                </div>
                {!onlyView && (
                  <div className={styles.infoDetails}>Select panel</div>
                )}
              </div>

              <div className={styles.marginBottom}>
                <div
                  id="inverters"
                  className={`${styles.infoSolarModel}  ${styles.modulesDiv} ${
                    onlyView && styles.onlyView
                  }`}
                  onClick={
                    !onlyView
                      ? (event) => handleClickInSelector(event)
                      : () => {}
                  }
                >
                  {inverterInfo.name || "Select your inverter"}
                  {!onlyView && <img src="/icons/edit.svg" alt="can change" />}
                </div>
                {!onlyView && (
                  <div className={styles.infoDetails}>Select inverter</div>
                )}
              </div>
              <div className={styles.marginBottom}>
                <div className={styles.infoSolarModel}>
                  {proposalData.project.productionOffset} %
                </div>
                <div className={styles.infoDetails}>Energy offset</div>
              </div>

              <div className={styles.marginBottom}>
                <div className={styles.infoSolarModel}>
                  {parseFloat(proposalData.ac_annual)?.toFixed(0)} kW
                </div>
                <div className={styles.infoDetails}>Yearly energy produced</div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 p-0 d-flex justify-content-center align-items-center">
            <div
              className={`${styles.mobileWidth} row h-100 ${styles.width} overflow-hidden position-relative justify-content-center align-items-center`}
            >
              <div data-aos="fade-left" className={styles.imgContainer}>
                {!onlyView && (
                  <NavLink
                    to={`${rekonUrl}/redirect?token=${token}`}
                    target="_blank"
                    className={styles.mask}
                  >
                    <p className={styles.maskTitle}>Edit In ReKon</p>
                  </NavLink>
                )}

                <img
                  src={proposalData.image || proposalData.satelitalImage}
                  alt="img_house_maps"
                  className={styles.satelitalImage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section2;
