import { useContext } from "react";
import styles from "./Section6.module.css";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";
import VideoComponent from "../VideoComponent/VideoComponent";
import { ProposalContext } from "../../context/ProposalContext";

const Section6Part4 = () => {
  const { proposalData } = useContext(ProposalContext);
  const [ref, inView] = useInView({ triggerOnce: true });

  return (
    <div className={`${styles.videoSection} ${styles.videoSectionUnits}`}>
      <VideoComponent
        id={"newSolarCost"}
        source={"/video/NewSolarCost.mp4"}
        background={"/img/preloadImage/newsolarcost000.webp"}
      />
      <div className="container-fluid">
        <div className="row">
          <div
            className={`col-lg-12 ${styles.centeredElements} overflow-hidden`}
          >
            <h3
              data-aos="zoom-in"
              className={`${styles.customH1Section6} ${styles.titleSec6}`}
            >
              Next Solar Cost
            </h3>

            <div className="row w-45">
              <div className={`col-6 ${styles.leftAlignedElements}`}>
                <div
                  className={`${styles.tSelIncen} ${styles.specificColortSelIncen} h-100`}
                >
                  Base Price
                </div>
                <div
                  className={`${styles.tSelIncen} h-100 ${styles.specificColortSelIncen} text-start`}
                >
                  Federal Tax Credit
                </div>
              </div>
              <div className={`col-6 ${styles.rightAlignedElements}`}>
                <div className={styles.tSelCash}>${proposalData?.baseFinalPrice}</div>
                <div className={styles.tSelCash}>
                  ${proposalData?.baseFederalTax}
                </div>
              </div>
              <br />
            </div>
            <div className="row p-5">
              <div className={"container"}>
                <div className={`${styles.textSec6} pb-0`}>
                  Total System Cost
                </div>
                <div className={styles.rect6Yellow}>
                  <div ref={ref} className={styles.infoWhite}>
                    {inView ? (
                      <CountUp
                        start={0}
                        end={proposalData?.financialAmount}
                        duration={1}
                        decimals={2}
                        prefix="$"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section6Part4;
