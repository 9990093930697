import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { apiUrl } from "../context/AppContext";
import useProposalObject from "./useProposalObject";
import { ProposalContext } from "../context/ProposalContext";

/* Custom Hook to send the proposal */
const useSend = () => {
  /* Global states */
  const { setSendSuccess, setSending } = useContext(AppContext);
  const { setSaveStatus, setSaveSuccess, setProposalStatusLegend } =
    useContext(ProposalContext);

  /* Custom Hook */
  const { addApiData, createObject, createConfig } = useProposalObject();

  /* Function to send the proposal to the API */
  function sendProposal() {
    setSending(true);

    const body = createObject();
    const bodyWithApiData = addApiData(body); // Add proposal data to the body
    const config = createConfig(bodyWithApiData);

    fetch(`${apiUrl}/project/sendProposal`, config)
      .then((res) => {
        /* this is the response of the API, is an object with the status and the message */
        if (res.status >= 400) throw new Error(404);

        setSaveSuccess(true);
        setSendSuccess(true);
        setProposalStatusLegend("Your Work has been submitted");

        /* Timeout for the status message */
        setTimeout(() => {
          setSaveSuccess(false);
          setProposalStatusLegend("");
          setSending(false);
          setSendSuccess(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);

        setSaveSuccess(true);
        setSendSuccess(true);
        setProposalStatusLegend("We couldn't save your proposal, try again.");
        setTimeout(() => {
          setProposalStatusLegend("");
          setSaveSuccess(false);
          setSending(false);
          setSendSuccess(false);
        }, 3000);

        setSaveStatus(false);
      });
  }

  return { sendProposal };
};

export default useSend;
