function createOptionsOfCustomConsumptionProfile(data) {
    const object = {
      animation: false,
      textStyle: {
        fontFamily: "DM Sans",
      },

  
      tooltip: {
        triggerOn: "none",
        axisPointer: {
          type: "shadow",
        },
        textStyle: {
          color: "black",
        },
        formatter: function (params) {
          return (
            "Hour: " +
            params.data[0].toFixed(2) +
            "<br>Kw: " +
            params.data[1].toFixed(2)
          );
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          data: [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23,
          ],
          axisLabel: {
            color: "#fff",
          },
          axisLine: { onZero: false },
        },
      ],
      yAxis: [
        {
          type: "value",
          axisLabel: {
            color: "#fff",
          },
          axisLine: { onZero: false },
        },
      ],
      series: [
        {
          id: "a",
          smooth: true,
          symbolSize: 3,
          data: data,
          name: "Consumption",
          type: "line",
          stack: "Total",
          areaStyle: {
            opacity: 0.4,
          },
          color: "#ffc010",
          shadowBlur: 10,
          shadowColor: "rgba(0, 0, 0, 0.3)",
        },
      ],
    };
  

    return object;
  }
  
  export default createOptionsOfCustomConsumptionProfile;
  