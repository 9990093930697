import React, {  useEffect, useRef } from "react";
import * as echarts from "echarts";

import createObjectOfUtillityBills from "./UtillityBills.chart.config";

/* Utillity Bills before and after Solar */

const UtilityBillsChart = ({proposalData}) => {

  const utillityBillsRef = useRef(null)

  useEffect(() => {
    const myChart = echarts.init(utillityBillsRef.current);
    const option = createObjectOfUtillityBills(proposalData);

    function resize() {
      myChart.resize();
      myChart.dispose();
    }

    /* Refresh the chart when the window is resized */
    window.addEventListener("resize", resize);
    /* Refresh the chart data when the proposalData changes */
    myChart.setOption(option);

    return () => {
      window.removeEventListener("resize", resize);
      myChart.dispose();
    };
  }, [proposalData,utillityBillsRef]);

  return (
    <div
      ref={utillityBillsRef}
      style={{ width: "100%", height: "500px" }}
    ></div>
  );
};

export default UtilityBillsChart;
