import { Fragment } from "react";
import styles from "../CostOverview.module.css";

const BatteriesAditional = ({ batteries, selectedBatteries }) => {
  return (
    <div className={styles.tableContainer}>
      <div className={styles.category}>
        <h3>Batteries Aditional</h3>
      </div>
      <table className={styles.section5Table}>
        <tbody className={styles.elementContainer}>
          {batteries.map((battery, index) => {
            if (selectedBatteries[index] === 0) return null;
            return (
              <Fragment key={battery.id}>
                <tr key={battery.id} className={styles.element}>
                  <td>{battery.name}</td>
                  {/*     <td>unit/s {selectedBatteries[index]}</td> */}
                  <td className={styles.selectedBatteries}>
                    <div className={styles.bat}>
                      ${selectedBatteries[index] * batteries[index].price} / x
                      {selectedBatteries[index]} Units
                    </div>
                  </td>
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default BatteriesAditional;
