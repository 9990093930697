import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { apiUrl } from "../context/AppContext";
import useProposalObject from "./useProposalObject";
import { ProposalContext } from "../context/ProposalContext";

/* Custom Hook to save the proposal */
const useSave = () => {
  /* Global states */
  const { setSending, setAutoSaveError } =
    useContext(AppContext);
  const { setSaveStatus, setSaveSuccess, setProposalStatusLegend,setAutoSaveStatus } =
    useContext(ProposalContext);

  /* Custom Hook */
  const { createObject, createConfig } = useProposalObject();

  async function saveProposal() {
    setSending(true);

    const body = createObject();
    const config = createConfig(body);

    /* Send the proposal to the API */
    fetch(`${apiUrl}/project/proposal`, config)
      .then((res) => {
        setSaveSuccess(true);
        setSaveStatus(true);
        setProposalStatusLegend("Your Work has been saved");
        setTimeout(() => {
          setSaveSuccess(false);
          setSending(false);
          setProposalStatusLegend("");
        }, 3000);
      })
      .catch((err) => {
        console.log(err);

        setSaveSuccess(true);
        setSaveStatus(true);
        setProposalStatusLegend("We couldn't save your proposal, try again.");
        setTimeout(() => {
          setSaveSuccess(false);
          setSending(false);
          setProposalStatusLegend("");
        }, 3000);

        setSaveStatus(false);
      });
  }

  /* Function to save the proposal in the API every x sec */
  function saveProposalAuto() {
    setAutoSaveError(false);
    const body = createObject();
    const config = createConfig(body);

    fetch(`${apiUrl}/project/proposal`, config)
      .then((res) => res.json())
      .catch((err) => {
        setAutoSaveError(true);
        setAutoSaveStatus("Error saving proposal");
      });
  }

  return { saveProposal, saveProposalAuto };
};

export default useSave;
