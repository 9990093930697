// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.App {
  text-align: center;
  position: relative;
}

.App-header {
  padding: 8px 0px;
  background-color: rgba(0, 0, 0, 0.52);
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.App-link {
  color: #61dafb;
}

.left-image {
  max-width: 70%;
  height: auto;
}

.right-image {
  width: 35px;
  height: auto;
  opacity: 1;
}

.view-hamburguer-icon {
  display: none;
}

 .buttonGo{
  background-color: var(--primary-color);
  border-radius: 10px;
  border-style: none;
  font-family: var(--lexend);
  padding: 4px 8px;
  border: 3px solid var(--primary-color);
  color: white;
  margin-right: 45px;
  font-size: 0.875rem;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
} 

@media (max-width: 767.98px) {
  .view-hamburguer-icon {
    display: inline;
  }
}


`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,qCAAqC;EACrC,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,kCAA0B;UAA1B,0BAA0B;AAC5B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,aAAa;AACf;;CAEC;EACC,sCAAsC;EACtC,mBAAmB;EACnB,kBAAkB;EAClB,0BAA0B;EAC1B,gBAAgB;EAChB,sCAAsC;EACtC,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,gCAAgC;AAClC;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":["\n\n.App {\n  text-align: center;\n  position: relative;\n}\n\n.App-header {\n  padding: 8px 0px;\n  background-color: rgba(0, 0, 0, 0.52);\n  height: 50px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  backdrop-filter: blur(5px);\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n.left-image {\n  max-width: 70%;\n  height: auto;\n}\n\n.right-image {\n  width: 35px;\n  height: auto;\n  opacity: 1;\n}\n\n.view-hamburguer-icon {\n  display: none;\n}\n\n .buttonGo{\n  background-color: var(--primary-color);\n  border-radius: 10px;\n  border-style: none;\n  font-family: var(--lexend);\n  padding: 4px 8px;\n  border: 3px solid var(--primary-color);\n  color: white;\n  margin-right: 45px;\n  font-size: 0.875rem;\n  font-weight: 400;\n  transition: all 0.2s ease-in-out;\n} \n\n@media (max-width: 767.98px) {\n  .view-hamburguer-icon {\n    display: inline;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
