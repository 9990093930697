import React, { Fragment, useState } from "react";
import styles from "./Section9.module.css";
import RouteInfo from "./RouteInfo";
import ImgSourceConsult from "../../assets/img/consult-image.webp";
import ImgSourceSurvey from "../../assets/img/site-survey-image.webp";
import ImgSourceFinal from "../../assets/img/final-design-image.webp";
import ImgSourcePermits from "../../assets/img/permits-and-paperwork-image.webp";
import ImgSourceInstall from "../../assets/img/install-image.webp";
import ImgSourceInspection from "../../assets/img/city-inspection-image.webp";
import ImgSourcePermission from "../../assets/img/permits-to-launch-image.webp";
import ImgSourceSwitch from "../../assets/img/flip-the-switch-image.webp";
import Card from "./Card";

const Section9 = () => {
  const [route, setRoute] = useState([
    {
      id: 1,
      title: "Consult",
      description:
        "We'll guide you through an initial consultation, providing an overview of your solar system design while offering a glimpse of the potential savings in store for you.",
      imageSource: ImgSourceConsult,
      isPressed: true,
      stage: [1,1,2]
    },
    {
      id: 2,
      title: "Site Survey",
      description:
        "During this stage, our experts assess your property to design a tailored solar system that fits your needs and conditions.",
      imageSource: ImgSourceSurvey,
      isPressed: false,
      stage: [2,1,2]
    },
    {
      id: 3,
      title: "Final Design",
      description:
        "We'll optimize your custom solar system for peak performance and provide a detailed plan for installation.",
      imageSource: ImgSourceFinal,
      isPressed: false,
      stage: [3,1,2]
    },
    {
      id: 4,
      title: "Permits and Paperwork",
      description:
        "We will take care of all the necessary permits and paperwork to streamline your solar installation process during the 'Permits and Paperwork' phase.",
      imageSource: ImgSourcePermits,
      isPressed: false,
      stage: [4,1,2]
    },
    {
      id: 5,
      title: "Install",
      description:
        "During installation, our skilled team will set up your solar system efficiently and with precision, ensuring a smooth transition to solar energy for your home.",
      imageSource: ImgSourceInstall,
      isPressed: false,
      stage: [5,4,6]
    },
    {
      id: 6,
      title: "City inspection",
      description:
        "We'll guide you through an initial consultation, providing an overview of your solar system design while offering a glimpse of the potential savings in store for you.",
      imageSource: ImgSourceInspection,
      isPressed: false,
      stage: [6,1,1]
    },
    {
      id: 7,
      title: "Permission to Launch",
      description:
        "We'll coordinate with local authorities to ensure your solar system meets all regulatory requirements, moving you closer to enjoying clean energy.",
      imageSource: ImgSourcePermission,
      isPressed: false,
      stage: [7,4,6]
    },
    {
      id: 8,
      title: "Flip the switch",
      description:
      "In the 'Flip the Switch' phase, your solar system is activated, and you start harnessing clean, renewable energy to power your home.",
      imageSource: ImgSourceSwitch,
      isPressed: false,
    },
  ]);
  return (
    <section id="section9" className={styles.sectionNine}>
      <div className={`${styles.sectionContainer} overflow-hidden`}>
        <div data-aos="fade-left" className={styles.titleContainer}>
          <h2>Your next steps</h2>
          <p>Financing Your solar system</p>
        </div>
        <div className={styles.routeAndInfoContainer}>
          <div className={styles.routeContainer}>
            {route.map((info) => (
              <RouteInfo
                key={info.id}
                info={info}
                route={route}
                setRoute={setRoute}
              />
            ))}
          </div>
          {route.map(
            (info,index) =>
              info.isPressed && (
                <Fragment key={index}>
                  
                  <Card  info={info} />
                
                </Fragment>
              )
          )}
        </div>
      </div>
    </section>
  );
};

export default Section9;
