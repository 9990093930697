import { Button, Offcanvas, OffcanvasHeader } from "react-bootstrap";
import Section4 from "../Section4/Section4";
import styles from "./Batteries.module.css";
import ConsumptionProfileComponentL from "./ConsumptionProfile/ConsumptionProfileComponentL";
import SelfConsumption from "./SelfConsumption/SelfConsumption";
import Tariffs from "./Tariffs/Tariffs";
import { useContext, useState } from "react";
import { BatteriesContext } from "../../context/BatteriesContext";

const Batteries = () => {
  const [show, setShow] = useState(false);
  const {selectedBatteries} = useContext(BatteriesContext);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <section className={styles.batteriesContainer}>
        <div className={styles.batteriesSection4Button} onClick={handleShow}>
          <img src="/icons/right-arrow.svg" alt="open" />
        </div>
      <div className={styles.image}>
        <Tariffs />
        <ConsumptionProfileComponentL />
        <SelfConsumption />


        <Offcanvas
          style={{ backgroundColor: "#000", width: "30%" }}
          show={show}
          onHide={handleClose}
          size="xxl"
        >
          <OffcanvasHeader>
            <h5 className={styles.offCanvasTitle}>Selected Batter
                {selectedBatteries.reduce((b, acc) => b + acc, 0) === 1
                  ? "y"
                  : "ies"}</h5>
          </OffcanvasHeader>
          <Offcanvas.Body>
            <Section4 offCanvas={true} />
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </section>
  );
};

export default Batteries;
