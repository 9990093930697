import { useContext, useEffect, useState } from "react";
import styles from "./Month.module.css";
import { AppContext } from "../../../../context/AppContext";
import { BatteriesContext } from "../../../../context/BatteriesContext";

const Month = ({
  kWh,
  index,
  month,
  invert,
  calculateCurrentAvgConsumption,
}) => {
  const { monthsConsumptions, setMonthsConsumptions,mcSettings } =
    useContext(BatteriesContext);

  const [orient, setOrient] = useState(
    window.innerWidth <= 767.98 ? "horizontal" : "vertical"
  );

  useEffect(() => {
    const handleResize = () =>
      window.innerWidth <= 767.98
        ? setOrient("horizontal")
        : setOrient("vertical");
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChange = (event) => {
    let value = event.target.value;

    if (isNaN(value) || value === "") value = 0;

    if (value > mcSettings.max || value < 0) return;

    let updatedMonthsConsumptions = [...monthsConsumptions];
    updatedMonthsConsumptions[index] = parseFloat(value);
    setMonthsConsumptions(updatedMonthsConsumptions);
  };

  return (
    <div className={styles.inputContainer}>
      <span className={styles.month}>{month}</span>
      <input
        className={`${styles.input} ${styles[invert]}`}
        type="range"
        min={0}
        value={kWh}
        max={mcSettings.max}
        orient={orient}
        onChange={(event) => handleChange(event)}
        onMouseUp={calculateCurrentAvgConsumption}
        onKeyUpCapture={calculateCurrentAvgConsumption}
        onKeyDownCapture={calculateCurrentAvgConsumption}
        onTouchEnd={calculateCurrentAvgConsumption}

      />
      <input
        type="text"
        min={0}
        className={styles.output}
        value={kWh}
        onChange={(event) => handleChange(event)}
      />
    </div>
  );
};

export default Month;
