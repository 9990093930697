import { createContext, useState } from "react";

const AddersContext = createContext();

const AddersProvider = ({ children }) => {
  /* Array */
  const [adders, setAdders] = useState([]); // Array of adders

  /* String */
  const [adderOption, setAdderOption] = useState("Electrical"); // Section selected in the adders menu

  /* Boolean */
  const [addersAnimation, setAddersAnimation] = useState(false); // Toggle that show the adders menu and active CSS animation
  const [formIsSelected, setFormIsSelected] = useState({
    Roofing: false,
    Painting: false,
  }); // Dettect if the user is in the form of Roofing or Painting

  return (
    <AddersContext.Provider
      value={{
        adders,
        setAdders,
        adderOption,
        setAdderOption,
        addersAnimation,
        setAddersAnimation,
        formIsSelected,
        setFormIsSelected,
      }}
    >
      {children}
    </AddersContext.Provider>
  );
};

export { AddersContext, AddersProvider };
