//import styles from "../../../Section5/Section5.module.css"
 import { useContext } from "react";
import styles from "./ExteriorPainting.module.css"
import { ProposalContext } from "../../../../context/ProposalContext";

const ExteriorPaintingService = ({formIsSelected, setFormIsSelected}) => {

    const {setInformation,information} = useContext(ProposalContext) 

    const handleChange = (event) => {
        setInformation((prev) => ({...prev, estimatedBudget: event.target.value}));
    }

    const handleCheckboxChange = (event) =>{
        const isChecked= event.target.checked;
        setFormIsSelected(isChecked);
    }

  return (
    <div className={styles.cont}>
         <div className={`${styles.inputCheckboxContainer} ${styles.principalCheckBoxMargin}`}>
            <input 
            id="abledform" 
            type="checkbox"
            onChange={handleCheckboxChange}
            checked={formIsSelected} 
            /> 
            <h3 className="text-center m-0">Exterior Painting Service</h3>
        </div>
    <form className={`${styles.form} ${formIsSelected ? styles.appears : ''}`}> 
        <div className={styles.containerExt}>
            <div className={styles.inputContainerExt}>
                <label htmlFor="">Surface Size</label>
                <input type="text" id="surfaceSize" disabled={!formIsSelected}/>
            </div>
            <div className={styles.inputContainerExt}>
                <label htmlFor="">Surface Type</label>
                <input 
                type="text" 
                placeholder="Ceramic Tiles"  
                id="surfaceType"
                disabled={!formIsSelected} />
            </div>
        </div>
        <div className={styles.containerExt}>
            <div className={styles.inputContainerExt}>
                <label htmlFor="">Surface Condition</label>
                <input type="text" id="surfaceCondition" disabled={!formIsSelected} /> 
            </div>
            <div className={styles.inputContainerExt}>
                <label htmlFor="">Additional Details</label>
                <input type="text" id="additionalDetails" disabled={!formIsSelected} />
            </div>
        </div>
        <div className={styles.containerExt}>
        <div className={styles.inputContainerExtMod}>
                <label htmlFor="">Current and Desired New Color</label>
                <input type="text" id="currentColor" disabled={!formIsSelected} />
                <input type="text" id="desiredColor" disabled={!formIsSelected}/>
            </div>
        </div>
        <div className={styles.rangeContainer}>
            <label htmlFor="">Estimated Budget:</label> 
            <input 
                onChange={(event) => {
                    handleChange(event);
                }}
                className={ styles.inputSlider} 
                type="range" 
                id="budget"
                name="budget"
                min={0} 
                max={1000} 
                
    
            /> 
            <output className={styles.outputSlider}>
                ${information.estimatedBudget}
            </output> 
        </div>
    </form>
  </div>
  )
}
export default ExteriorPaintingService;