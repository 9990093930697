import * as echarts from "echarts";
import { useContext, useEffect, useRef, useState } from "react";
import {
  createOptionsOfSelfConsumption,
  createOptionsOfSelfConsumptionMobile,
} from "./SelfConsumption.chart.config";
import { AppContext } from "../../../context/AppContext";
import { BatteriesContext } from "../../../context/BatteriesContext";

const Graph = () => {
  const chartRef = useRef(null);
  const { SC_chartMonthSelected, monthlySavings } =
    useContext(BatteriesContext);
  const {inner} = useContext(AppContext)

  /* Set the data to be displayed in the chart, represent the month selected */
  const [data, setData] = useState(monthlySavings[SC_chartMonthSelected]);

  /* Refresh the chart when the month changes */
  useEffect(() => {
    setData(monthlySavings[SC_chartMonthSelected]);
  }, [monthlySavings, SC_chartMonthSelected]);

  /* Create the chart foreach change in the data */
  useEffect(() => {
    const chartDom = chartRef.current;
    const myChart = echarts.init(chartDom);

    const option = createOptionsOfSelfConsumption(data);
    myChart.setOption(option);

    if (inner.innerWidth <= 768) {
      const optionMobile = createOptionsOfSelfConsumptionMobile(data);
      myChart.setOption(optionMobile);
    }

    /* Resize the chart when the window is resized */
    const handleResize = () => {
      myChart.resize();
      if (inner.innerWidth <= 768) {
        const optionMobile = createOptionsOfSelfConsumptionMobile(data);
        myChart.setOption(optionMobile);
      }
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      myChart.dispose();
    };
  }, [SC_chartMonthSelected, monthlySavings, data,inner]);

  return (
    <div
      id="selfConsumption"
      ref={chartRef}
      style={{ width: "100%", height: "75vh" }}
    ></div>
  );
};

export default Graph;
