import { createContext, useEffect, useState } from "react";

const FinancialContext = createContext();

const FinancialProvider = ({ children }) => {
  /* Array */
  const [paymentOptions, setPaymentOptions] = useState([]); // Array of Objects with the information of the payment options

  /* String */
  const [selectedBank, setSelectedBank] = useState("Mosaic"); // Bank selected by the user

  /* Object */
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null); // Payment option selected by the user

  /* Number */
  const [taxCreditValue, setTaxCreditValue] = useState(0); // Value of the tax credit selected by the user in the input

  
  /* Boolean */
  const [taxCredit, setTaxCredit] = useState(false); // Dettect if the user want to use the tax credit


  useEffect(() => {
    setSelectedPaymentOption(paymentOptions[0] || null);
  },[selectedBank])

  return (
    <FinancialContext.Provider
      value={{
        selectedBank,
        setSelectedBank,
        selectedPaymentOption,
        setSelectedPaymentOption,
        paymentOptions,
        setPaymentOptions,
        taxCreditValue,
        setTaxCreditValue,
        taxCredit,
        setTaxCredit,
      }}
    >
      {children}
    </FinancialContext.Provider>
  );
};

export { FinancialContext, FinancialProvider };
