import React, { useContext } from "react";
import styles from "./SelfConsumption.module.css";
import SelfConsumptionChart from "./SelfConsumption.chart"
import { BatteriesContext } from "../../../context/BatteriesContext";

const SelfConsumption = () => {
  const { SC_chartMonthSelected, setSC_chartMonthSelected } =
    useContext(BatteriesContext);

  const months = [
    { value: "january", month: "January" },
    { value: "february", month: "February" },
    { value: "march", month: "March" },
    { value: "april", month: "April" },
    { value: "may", month: "May" },
    { value: "june", month: "June" },
    { value: "july", month: "July" },
    { value: "august", month: "August" },
    { value: "september", month: "September" },
    { value: "october", month: "October" },
    { value: "november", month: "November" },
    { value: "december", month: "December" },
  ];



  const handleClickInSelect = (event) => {
    setSC_chartMonthSelected(event.target.value);
  };

  return (
    <div className={styles.selfConsumptionSection}>
        <h2 className={styles.titleH2}>Self Consumption</h2>
    <div className={styles.SelfConsumptionContainer}>
      <div className={styles.selectContainer}>
        <select
          id="selectMonth"
          value={SC_chartMonthSelected}
          onChange={(event) => handleClickInSelect(event)}
          className={styles.select}
        >
          {months.map((month, index) => {
            return (
              <option key={index} value={index}>
                {month.month}
              </option>
            );
          })}
        </select>
      </div>
      <SelfConsumptionChart />
      </div>
    </div>
  );
};

export default SelfConsumption;
