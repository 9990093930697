import { useContext, useState } from "react";
import styles from "./Adders.module.css";
import Electrical from "./Electrical/Electrical";
import Roofing from "./Roofing/Roofing";
import ExteriorPaintingService from "./Painting/ExteriorPaintingService";
import { AddersContext } from "../../../context/AddersContext";
import { ProposalContext } from "../../../context/ProposalContext";
import { MenuContext } from "../../../context/MenuContext";

/* Modal de Adders */

const Adders = () => {
  const { setMenuAdder } = useContext(MenuContext);

  const { value } = useContext(ProposalContext);
  const { addersAnimation, setAddersAnimation } = useContext(AddersContext);
  const [formIsSelected, setFormIsSelected] = useState(false);
  const { adderOption, setAdderOption } = useContext(AddersContext);

  /* Ejecutar Animación al cerrar el Modal */
  const handleClick = () => {
    setAddersAnimation(true);
    setTimeout(() => {
      setAddersAnimation(false);
      setMenuAdder(false);
    }, 400);
  };

  /* Mostrar la opción de la lista del menú que se eligió */
  const handleClickInList = (event) => {
    const adderOp = event.target.id;

    setAdderOption(adderOp);
  };

  return (
    <div
      className={`${styles.modal} ${addersAnimation && styles.modalAnimateEnd}`}
    >
      {/* Contenedor del titulo y el icono de cierre (cierra el modal al hacer clic) */}
      <div className={styles.modalTitleContainer}>
        <h3>Adders</h3>
        <div className={styles.modalTitleContainerImg}>
          <img
            onClick={handleClick}
            src="/icons/close_ring_duotone.svg"
            alt="close"
          />
        </div>
      </div>
      <div className={styles.menuContainer}>
        {/* contenedor de la  interfaz del modal */}
        <div className={styles.optionsContainer}>
          {adderOption === "Electrical" && <Electrical />}
          {adderOption === "Roofing" && <Roofing />}
          {adderOption === "Painting" && (
            <ExteriorPaintingService
              formIsSelected={formIsSelected}
              setFormIsSelected={setFormIsSelected}
            />
          )}
          <div className={styles.totalContainer}>
            <p>Total Adders</p>
            <p>${value?.toFixed(2)}</p>
          </div>
        </div>
        {/* Lista con menú */}
{/*         <div className={styles.listContainer}>
          <ul className={styles.ul}>
            <li
              onClick={(event) => handleClickInList(event)}
              id="Roofing"
              className={
                adderOption === "Roofing" ? styles.liIsSelected : styles.li
              }
            >
              Roofing
            </li>
            <li
              onClick={(event) => handleClickInList(event)}
              id="Electrical"
              className={
                adderOption === "Electrical" ? styles.liIsSelected : styles.li
              }
            >
              Electrical
            </li>
            <li
              onClick={(event) => handleClickInList(event)}
              id="Painting"
              className={
                adderOption === "Painting" ? styles.liIsSelected : styles.li
              }
            >
              Exterior Painting Service
            </li>
            <li
              onClick={(event) => handleClickInList(event)}
              id="Gutters"
              className={
                adderOption === "Gutters" ? styles.liIsSelected : styles.li
              }
            >
              Gutters
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  );
};

export default Adders;
