import { useContext, useEffect, useState } from "react";
import styles from "./Section1.module.css";
import "aos/dist/aos.css";
import VideoComponent from "../VideoComponent/VideoComponent";
import { ProposalContext } from "../../context/ProposalContext";

const Section1 = ({reference}) => {
  const { proposalData } = useContext(ProposalContext);
  const [clientAddress, setClientAddress] = useState("");

  /* replace commas with spaces */
  useEffect(() => {
    if (proposalData?.project?.clientAddress) {
      const address = proposalData?.project?.clientAddress
        ?.split(", ")
        .toString()
        .replaceAll(",", " ");
      setClientAddress(address);
    }
  }, []);

  return (
    <section id="section1" className="videoSection" ref={reference}>
      <VideoComponent
        id={"fondo_001"}
        source={"/video/fondo_001.mp4"}
        background={"/img/preloadImage/experiencetomorrownergytoday067.webp"}
      />
      <div
        className={`container-fluid ${styles.containerSmalll} overflow-hidden`}
      >
        <h1
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          className={`${styles.customH1}`}
        >
          Experience <br />
          Tomorrow's Energy <br />
          <span className={styles.colorAma}>TODAY</span>
        </h1>
        <div data-aos="fade-up" className={`${styles.infoContainer}`}>
          <h2 className={styles.customH2}>
            {proposalData?.project?.clientName.toUpperCase()}
          </h2>
          <div className={styles.lineMobile}></div>
          <div className={styles.informationContainerText}>
            <div className={styles.clientInformationContainer}>
              <p>{clientAddress}</p>
              <p>{proposalData?.project?.clientName}</p>
              <p>{proposalData?.project?.clientEmail}</p>
              <p>{proposalData?.project?.clientMobile}</p>
            </div>
            <div className={styles.lineMobile}></div>
            <div className={styles.salesManInfoContainer}>
              <p>Your representative:</p>
              <p>{proposalData?.project?.salesmanName}</p>
              <p>{proposalData?.project?.salesmanMobile}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section1;
