// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Page404_container__sKNzs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 100vh;
  row-gap: 64px;
  padding-left: 64px;
}

.Page404_title__SFulE {
  color: #ffffff;
  font-size: 3.75rem;
  font-family: var(--lexend);
  font-weight: 600;
  text-align: left;
}

.Page404_titleSpan__nUHkW {
  color: var(--primary-color);
}

.Page404_message__cGdcQ {
  color: #fff;
  font-size: 1.8rem;
  font-family: var(--lexend);
  font-weight: 600;
}

.Page404_button__-0\\+PG {
  background-color: var(--primary-color);
  border-radius: 10px;
  border-style: none;
  font-family: var(--lexend);
  padding: 8px 16px;
  border: 1px solid var(--primary-color);
  color: white;
  font-weight: 600;
}

.Page404_button__-0\\+PG:hover {
  background-color: white;
  color: black;
}

@media (max-width: 768px) {
  .Page404_container__sKNzs {
    padding-left: 16px;
    padding-right: 16px;
    align-items: center;
  }
  .Page404_title__SFulE {
    font-size: 2.5rem;
    text-align: center;
  }
  .Page404_message__cGdcQ {
    font-size: 1rem;
    text-align: center;
    background: rgba(97, 97, 97, 0.78);
    -webkit-backdrop-filter: blur(7.5px);
    backdrop-filter: blur(7.5px);
    border-radius: 10px;
    padding: 32px 16px;
  }
  .Page404_button__-0\\+PG {
    width: 50%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Page404/Page404.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,0BAA0B;EAC1B,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,sCAAsC;EACtC,mBAAmB;EACnB,kBAAkB;EAClB,0BAA0B;EAC1B,iBAAiB;EACjB,sCAAsC;EACtC,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE;IACE,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;EACrB;EACA;IACE,iBAAiB;IACjB,kBAAkB;EACpB;EACA;IACE,eAAe;IACf,kBAAkB;IAClB,kCAAkC;IAClC,oCAAoC;IACpC,4BAA4B;IAC5B,mBAAmB;IACnB,kBAAkB;EACpB;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: start;\n  height: 100vh;\n  row-gap: 64px;\n  padding-left: 64px;\n}\n\n.title {\n  color: #ffffff;\n  font-size: 3.75rem;\n  font-family: var(--lexend);\n  font-weight: 600;\n  text-align: left;\n}\n\n.titleSpan {\n  color: var(--primary-color);\n}\n\n.message {\n  color: #fff;\n  font-size: 1.8rem;\n  font-family: var(--lexend);\n  font-weight: 600;\n}\n\n.button {\n  background-color: var(--primary-color);\n  border-radius: 10px;\n  border-style: none;\n  font-family: var(--lexend);\n  padding: 8px 16px;\n  border: 1px solid var(--primary-color);\n  color: white;\n  font-weight: 600;\n}\n\n.button:hover {\n  background-color: white;\n  color: black;\n}\n\n@media (max-width: 768px) {\n  .container {\n    padding-left: 16px;\n    padding-right: 16px;\n    align-items: center;\n  }\n  .title {\n    font-size: 2.5rem;\n    text-align: center;\n  }\n  .message {\n    font-size: 1rem;\n    text-align: center;\n    background: rgba(97, 97, 97, 0.78);\n    -webkit-backdrop-filter: blur(7.5px);\n    backdrop-filter: blur(7.5px);\n    border-radius: 10px;\n    padding: 32px 16px;\n  }\n  .button {\n    width: 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Page404_container__sKNzs`,
	"title": `Page404_title__SFulE`,
	"titleSpan": `Page404_titleSpan__nUHkW`,
	"message": `Page404_message__cGdcQ`,
	"button": `Page404_button__-0+PG`
};
export default ___CSS_LOADER_EXPORT___;
