import styles from "../CostOverview.module.css"

const CostOverviewTable = ({ information, proposalData, selectedAdders,onlyView}) => {
  return (
    <div className={styles.tableContainer}>
      <div className={styles.category}>
        <h3>Cost Overview</h3>
      </div>
      <table className={styles.section5Table}>
        <tbody className={styles.elementContainer}>
          <tr className={styles.element}>
            <td>Base Price per Watt</td>
            <td>
              {onlyView ? information.overridePricePerWatt : proposalData.project.basePricePerWatt }
              $/w
            </td>
          </tr>
          {selectedAdders.map((adder) => (
            <tr key={adder.id} className={styles.element}>
              <td>{adder.name}</td>
              <td>
                {adder.name === "Travel Adder"
                  ? parseFloat(
                      (adder.price * adder.qty) /
                        (proposalData.systemCapacity * 1000)
                    ).toFixed(2)
                  : parseFloat(
                      adder.price / (proposalData.systemCapacity * 1000)
                    ).toFixed(2)}
                $/w
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CostOverviewTable;
