import styles from "./CalcsAlert.module.css";
import { useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";

const CalcsAlert = () => {
  const { applyChangesStatus, setApplyChangesStatus } = useContext(AppContext);



  useEffect(() => {
    let timeOut;
    if (applyChangesStatus === "Changes Applied")
      timeOut = setTimeout(() => {
        setApplyChangesStatus(null);
      }, 3000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [applyChangesStatus]);

  return (
    <div className={`${styles.changesStatusContainer} ${applyChangesStatus === "Changes Applied" && styles.success}`}>
      <p className={styles.changesStatusContainerText} >{applyChangesStatus}</p>
    </div>
  );
};

export default CalcsAlert;
