import { useContext, useState } from "react";
import styles from "./ShareModal.module.css";
import { ProposalContext } from "../../../../context/ProposalContext";
import { AppContext } from "../../../../context/AppContext";

const ShareModal = () => {
  const { proposalOnlyViewLink } = useContext(ProposalContext);
  const {setShareModal} = useContext(AppContext)
  const [textCopy, setTextCopy] = useState("Copy Link to Clipboard");

  const copyLink = async (event) => {
    try {
      await navigator.clipboard.writeText(proposalOnlyViewLink);
      setTextCopy("Copied to clipboard!");
      setTimeout(() => {
        setTextCopy("Copy Link to Clipboard");
      }, 3000);
    } catch (error) {
      setTextCopy("could not copy to the clipboard");
      setTimeout(() => {
        setTextCopy("Copy Link to Clipboard");
      }, 3000);
    }
  };

  const openInANewTab = () => {
    if (proposalOnlyViewLink) window.open(proposalOnlyViewLink, "_blank");
  };

  const closeModal = () => {
    setShareModal(false);
  }

  return (
    <div className={styles.modal}>
      <div className={styles.modalContainerImg}>
        <img
          onClick={closeModal}
          src="/icons/close_ring_duotone.svg"
          alt="close"
        />
      </div>
      <div className={styles.container}>
        <span>Link to share the proposal</span>
        <input disabled={true} value={proposalOnlyViewLink} />
        <button
          onClick={copyLink}
          disabled={proposalOnlyViewLink ? false : true}
        >
          {textCopy}
        </button>
        <button
          className={styles.buttonOpen}
          onClick={openInANewTab}
          disabled={proposalOnlyViewLink ? false : true}
        >
          Open in a new tab
        </button>
      </div>
    </div>
  );
};

export default ShareModal;
