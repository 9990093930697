import React, { useContext, useEffect, useState } from "react";
import styles from "./CostOverview.module.css";
import { Tooltip } from "react-bootstrap";
import { BatteriesContext } from "../../../../context/BatteriesContext";
import { AddersContext } from "../../../../context/AddersContext";
import { FinancialContext } from "../../../../context/FinancialContext";
import { ProposalContext } from "../../../../context/ProposalContext";
import SolarEnergyRates from "./Tables/SolarEnergyRates";
import CostOverviewTable from "./Tables/CostOverviewTable";
import BatteriesAditional from "./Tables/BatteriesAditional";
import AditionalFeesAndCosts from "./Tables/AditionalFeesAndCosts";
import Financing from "./Tables/Financing";

const CostOverview = () => {
  /* Global states */

  const { proposalData, information, setInformation, basePricePerWatt,onlyView} =
    useContext(ProposalContext);
  const { batteries, selectedBatteries } = useContext(BatteriesContext);
  const { adders } = useContext(AddersContext);
  const { selectedPaymentOption } = useContext(FinancialContext);

  /* States of compontents */

  /* Number */
  const [overrideValue, setOverrideValue] = useState(
    information.overridePricePerWatt || 2.4
  ); // Value of the override input
  const [finalPricePerWatt, setFinalPricePerWatt] = useState(0);

  /* Boolean */
  const [hasBatteries, setHasBatteries] = useState(false); // To show or not the batteries table

  /* Effects that verify if the batteries are selected. if they are, show the table. Else, hide it */
  useEffect(() => {
    selectedBatteries.forEach(
      (battery) => battery > 0 && setHasBatteries(true)
    );
  }, [selectedBatteries]);

  /* Effect that calculates the final price per watt */
  useEffect(() => {
    selectedAdders.forEach((adder) => {
      let price = adder?.measurementUnit
        ? parseFloat(
            (adder.price * adder.qty) / (proposalData.systemCapacity * 1000)
          )
        : parseFloat(adder.price / (proposalData.systemCapacity * 1000));

      setFinalPricePerWatt((prev) => (prev += price));
    });

    setFinalPricePerWatt(
      (prev) => (prev += parseFloat(information.overridePricePerWatt))
    );
  }, []);

  /* set the override input to number value */
  const handleNumberChange = (event) => {
    let value = parseFloat(event.target.value);

    setOverrideValue(value);

    if (value >= basePricePerWatt) {
      setInformation((prev) => ({
        ...prev,
        overridePricePerWatt: parseFloat(value),
      }));

      setFinalPricePerWatt(
        (prev) => prev - parseFloat(information.overridePricePerWatt) + value
      );
    }
  };

  const selectedAdders = adders.filter((adder) => adder.isChecked);

  const tooltip = (
    <Tooltip id="tooltip">
      The number must be greather or equal than 2.4
    </Tooltip>
  );

  return (
    <div className={styles.costContainer}>
      <CostOverviewTable
        information={information}
        proposalData={proposalData}
        selectedAdders={selectedAdders}
        onlyView={onlyView}
      />
      <AditionalFeesAndCosts
        selectedPaymentOption={selectedPaymentOption}
        proposalData={proposalData}
        overrideValue={overrideValue}
        handleNumberChange={handleNumberChange}
        finalPricePerWatt={finalPricePerWatt}
        tooltip={tooltip}
      />
      <SolarEnergyRates proposalData={proposalData} />
      {hasBatteries && (
        <BatteriesAditional
          batteries={batteries}
          selectedBatteries={selectedBatteries}
        />
      )}
      <Financing proposalData={proposalData} />
    </div>
  );
};

export default CostOverview;
