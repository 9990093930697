import React, { createContext, useEffect, useState } from "react";

const MenuContext = createContext();

const MenuProvider = ({ children }) => {
  /* Booleans */
  const [showModal, setShowModal] = useState({
    modules: false,
    inverters: false,
    systemInfo: false,
  }); // Modal of Modules, Inverters and System Info
  const [menuOpen, setMenuOpen] = useState(false); // Toggle that expand the menu of the right
  const [menuAdder, setMenuAdder] = useState(false); // Toggle that show the adders menu
  const [menuSettings, setMenuSettings] = useState(false); // Toggle that show the settings menu
  const [menuSystemPricing, setMenuSystemPricing] = useState(false); // Toggle that show the system pricing menu
  const [modalActive, setModalActive] = useState(false); // Dettect if any modal is active
  const [modalOverride, setModalOverride] = useState(false); // Dettect if the modal of override is active

  /* Close the menu when the user click outside of the menu */
  useEffect(() => {
    if (menuAdder) {
      setMenuSettings(false);
      setMenuSystemPricing(false);
    }

    if (menuSettings) {
      setMenuAdder(false);
      setMenuSystemPricing(false);
    }

    if (menuSystemPricing) {
      setMenuAdder(false);
      setMenuSettings(false);
    }
  }, [menuAdder, menuSettings, menuSystemPricing]);

  /* Dettect if any modal is active */
  useEffect(() => {
    if (
      Object.values(showModal).includes(true) ||
      menuAdder ||
      menuSettings ||
      menuSystemPricing
    )
      setModalActive(true);
    else setModalActive(false);
  }, [menuAdder, menuSettings, menuSystemPricing, showModal]);

  /* Dettect if any modal is active and disable or enable scroll of the body */
  useEffect(() => {
    if (modalActive) document.body.className = "bodyScroll";
    else document.body.className = "";
  }, [modalActive]);

  return (
    <MenuContext.Provider
      value={{
        menuOpen,
        setMenuOpen,
        menuAdder,
        setMenuAdder,
        menuSettings,
        setMenuSettings,
        menuSystemPricing,
        setMenuSystemPricing,
        showModal,
        setShowModal,
        modalActive,
        setModalActive,
        modalOverride,
        setModalOverride,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export { MenuContext, MenuProvider };
