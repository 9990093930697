

const phrases = [
    {
        "quote": "Small changes today, big impact tomorrow.",
        "author": "Unknown"
    },
    {
      "quote": "Nature is not a place to visit. It is home.",
      "author": "Gary Snyder"
    },
    {
      "quote": "Sustainability is the key to our survival.",
      "author": "John D. Liu"
    },
    {
      "quote": "Leave nothing but footprints, take nothing but pictures.",
      "author": "Unknown"
    },
    {
        "quote": "In every walk with nature, one receives far more than one seeks.",
        "author": "John Muir"
    },
    {
        "quote": "The earth does not belong to us; we belong to the earth.",
        "author": "Marlee Matlin"
    },
    {
        "quote": "The environment is where we all meet; where we all have a mutual interest; it is the one thing all of us share.",
        "author": "Lady Bird Johnson"
    },
    {
        "quote": "Conservation is a state of harmony between men and land.",
        "author": "Aldo Leopold"
    },
    {
      "quote": "Our planet's health is our wealth.",
      "author": "Unknown"
    },
    {
      "quote": "Every day is Earth Day.",
      "author": "Unknown"
    },
    {
      "quote": "Think globally, act locally.",
      "author": "David Brower"
    },
    {
        "quote": "Nature is painting for us, day after day, pictures of infinite beauty.",
        "author": "John Ruskin"
    },
    {
        "quote": "Nature always wears the colors of the spirit.",
        "author": "Ralph Waldo Emerson"
    },
    {
        "quote": "The best time to plant a tree was 20 years ago. The second-best time is now.",
        "author": "Chinese Proverb"
    },
    {
        "quote": "Ecology is the heart of theology.",
        "author": "Pope Francis"
    }
  ]
  
  export default phrases;