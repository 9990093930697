import { useContext, useEffect, useState } from "react";
import styles from "./MonthlyConsumptionModal.module.css";
import { AppContext } from "../../../context/AppContext";
import Month from "./Month/Month";
import { BatteriesContext } from "../../../context/BatteriesContext";

const MonthlyConsumptionModal = () => {
  const { showWarning, setShowWarning, setShowModalWarning } =
    useContext(AppContext);

  const {
    setMonthlyConsumptionModal,
    monthsConsumptions,
    mcSettings,
    setMcSettings,
  } = useContext(BatteriesContext);


  const [currentAvgConsumption, setCurrentAvgConsumption] = useState(
    monthsConsumptions.reduce((acc, current) => acc + current, 0) / 12
  );

  const calculateCurrentAvgConsumption = () => {
    const calculateNewAvgConsumption =
      monthsConsumptions.reduce((acc, current) => acc + current, 0) / 12;
    setCurrentAvgConsumption(calculateNewAvgConsumption);
  };

  var sBrowser,
    sUsrAg = navigator.userAgent;

  if (sUsrAg.indexOf("Firefox") > -1) sBrowser = "Mozilla Firefox";

  let months = [
    "Jan",
    "Feb",
    "March",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleChange = (event) => {
    setMcSettings({ ...mcSettings, max: parseFloat(event.target.value) });
  };

  const closeModal = () => {
    setMonthlyConsumptionModal(false);
    if (
      currentAvgConsumption >= mcSettings.maxLimit ||
      currentAvgConsumption <= mcSettings.minLimit
    ) {
      setShowModalWarning(true);
    }
  };

  useEffect(() => {
    currentAvgConsumption >= mcSettings.maxLimit ||
    currentAvgConsumption <= mcSettings.minLimit
      ? setShowWarning("warning")
      : setShowWarning("");
  }, [currentAvgConsumption]);

  return (
    <div className={styles.modalContainer}>
      <div className={styles.headerContainer}>
        <h3 className={styles.headerContainerTitle}>Monthly Consumption</h3>
        <div className={styles.headerContainerImg}>
          <img
            src="/icons/close_ring_duotone.svg"
            alt="close"
            onClick={() => closeModal()}
          />
        </div>
      </div>
      <div
        className={`d-flex justify-content-evenly align-items-center w-100 ${styles.height}`}
      >
        <div className={styles.settingContainer}>
          <span className={styles.spanText}>Maximum consumption of kWh: </span>
          <input
            type="number"
            min={0}
            value={mcSettings.max}
            onChange={(event) => handleChange(event)}
          />
        </div>
        <div className={styles.settingContainer}>
          <span className={styles.spanText}>Current monthly average: </span>
          <span
            className={`${styles.spanCurrentMonthlyAverage} ${styles[showWarning]}`}
          >
            {currentAvgConsumption.toFixed(2) || ""}
          </span>
        </div>
      </div>
      <div className={styles.inputsTypeRangeContainer}>
        {monthsConsumptions.map((month, index) => {
          return (
            <Month
              key={months[index]}
              kWh={month}
              index={index}
              month={months[index]}
              invert={!(sBrowser === "Mozilla Firefox") ? "writtingMode" : ""}
              calculateCurrentAvgConsumption={calculateCurrentAvgConsumption}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MonthlyConsumptionModal;
