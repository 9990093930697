import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppProvider } from "./context/AppContext";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { BatteriesProvider } from "./context/BatteriesContext";
import { AddersProvider } from "./context/AddersContext";
import { FinancialProvider } from "./context/FinancialContext";
import { ProposalProvider } from "./context/ProposalContext";
import { MenuProvider } from "./context/MenuContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <AppProvider>
      <AuthProvider>
        <MenuProvider>
          <ProposalProvider>
            <BatteriesProvider>
              <AddersProvider>
                <FinancialProvider>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </FinancialProvider>
              </AddersProvider>
            </BatteriesProvider>
          </ProposalProvider>
        </MenuProvider>
      </AuthProvider>
    </AppProvider>
  </>
);

reportWebVitals();
