import { useContext, useEffect, useState } from "react";
import styles from "./Loader.module.css";
/* Importamos el Array de frases */
import phrases from "./phrases";
import { ProposalContext } from "../../context/ProposalContext";

const Loader = () => {
  const { error } = useContext(ProposalContext);
  const [phrase, setPhrase] = useState(null);

  useEffect(() => {
    const index = Math.floor(Math.random() * (phrases.length - 1));
    setPhrase(phrases[index]);
  }, []);

  return (
    <>
      <section className={styles.imageSection}>
        <div className={styles.image}>
          <div className={styles.loaderContainer}>
            <div className={styles.containerText}>
              {!error ? (
                <>
                  {
                    <h2>
                      {phrase?.quote}
                      <br />
                      <br />
                      {phrase?.author}
                    </h2>
                  }
                  <div className="d-flex flex-column justify-content-center align-items-center my-4 py-4">
                    <div className={styles.spinner}>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <p className={styles.loadingText}>
                      Loading Contract Proposal
                    </p>
                  </div>
                </>
              ) : (
                <p>{error}</p>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Loader;
