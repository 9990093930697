
import VideoCarousel3 from "./VideoCarousel/VideoCraousel";
import styles from "./part2.module.css";

const Part2 = () => {
  return (
    <div className={styles.part2Container}>
      <h2 className={styles.title}>
        Investing in a <br />
        Sustainable Future
      </h2>
      <p className={styles.information}>
        Switching to solar means saving money and reducing your carbon
        footprint; clean solar energy produces no emissions. Over the next 30
        years, you'll save.
      </p>

      <VideoCarousel3 />
    </div>
  );
};

export default Part2;
