import React, { useContext } from "react";
import success from "../../assets/img/success.svg";
import styles from "./SaveProposalStatus.module.css";
import warning from "../../assets/img/warning.svg";
import { ProposalContext } from "../../context/ProposalContext";
const SaveProposalSuccess = () => {
  const {saveStatus,proposalStatusLegend} = useContext(ProposalContext)

  return (
    <div className={styles.containerSuccess}>
      <div className={styles.imageSuccessContainer}>
        <img
          src={saveStatus ? success : warning}
          alt={saveStatus ? "success" : "warning"}
        />
      </div>
      <div
        className={`${styles.divSuccessTextContainer} ${
          !saveStatus && styles.dynamicColor
        }`}
      >
        <p>{proposalStatusLegend || ""}</p>
      </div>
    </div>
  );
};

export default SaveProposalSuccess;
