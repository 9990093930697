import styles from "../Section4/Section4.module.css";
import styles6 from "./Section6.module.css";
import Sec6RangeContainer from "./Sec6RangeContainer";
import { useContext, useEffect } from "react";
import { FinancialContext } from "../../context/FinancialContext";
import { ProposalContext } from "../../context/ProposalContext";

const Section6Part3 = () => {
  const { value, information, setInformation,proposalData, onlyView } = useContext(ProposalContext);
  const { taxCreditValue, setTaxCreditValue, taxCredit, setTaxCredit } =
    useContext(FinancialContext);

  /* Calculate the new value of the tax credit */
  
  useEffect(() => {
    if (!taxCredit) {
      const valueTc = (proposalData.baseFinalPrice * information.federalTaxCredit) / 100;
      setTaxCreditValue(valueTc);
      setInformation({ ...information, creditAmount: 100 });
    } else {
      const newValueIncentive =
        (proposalData?.baseFinalPrice * information.federalTaxCredit * information.creditAmount) /
        10000;

      setTaxCreditValue(newValueIncentive);
    }
  }, [
    value,
    information.federalTaxCredit,
    taxCredit,
    information.creditAmount,
  ]);

  return (
    <div className={`container ${styles.containerPart3Padding}`}>
      <div className="row">
        <div
          className={`col-lg-6  ${styles6.centeredElements} overflow-hidden mb-4`}
        >
          <div data-aos="fade-up" className={styles6.customH1Sec6Part3}>
            My Incentives
          </div>
          <div data-aos="fade-up" className={styles6.textSec6Part3}>
            Federal Tax Credits
          </div>

          <div className={`row ${styles6.spaceContainer}`}>
            <div className={`col-md-12 mb-0 ${styles6.centeredElements}`}>
              <div className={styles6.rect6Part3}>
                <span className={styles6.infoYellow}>
                  ${taxCreditValue?.toFixed(2)}
                </span>
              </div>
              <div className={styles6.leyendaPart3}>
                Apply credit to my loan
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className={`${styles6.tSelIncen} text-start`}>
                <input
                  id="FederalTax"
                  type="checkbox"
                  className={styles.checkbox}
                  checked={taxCredit}
                  onChange={() => setTaxCredit(!taxCredit)}
                  disabled={onlyView}
                />
                Federal Tax Credit
              </div>
            </div>
          </div>
        </div>
        <div
          className={`col-lg-6 ${styles6.centeredElements} ${styles6.marginBottom}`}
        >
          {taxCredit && (
            <div className={styles6.backgroundIncentives}>
              <hr />
              <Sec6RangeContainer />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Section6Part3;
