function createOptionsOfSelfConsumption(data) {
    const option = {
      textStyle: {
        fontFamily: "DM Sans",
      },
      tooltip: {
        trigger: "axis",
        textStyle: {
          color: "black",
        },
  
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        textStyle: {
          color: "white",
          bottom: 0,
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          data: [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23,
          ], // Hour of the day
          axisLabel: {
            color: "#fff",
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          max: (value) => (value.max >= 20 ? Math.round(value.max + 5) : 20), // Max value of the y axis
  
          axisLabel: {
            color: "#fff",
          },
        },
      ],
      /* Remember that the order of the series is important for the stacking */
      series: [
        {
          name: "Solar generation",
          type: "bar",
          stack: "a",
          emphasis: {
            focus: "series",
          },
          data: data.stackedPowGen,
          color: "yellow",
        },
        {
          name: "Discharge next day",
          type: "bar",
          stack: "a",
          emphasis: {
            focus: "series",
          },
          data: data.stackedBattDischargeNextDay,
          color: "palegreen",
        },
        {
          name: "Battery discharge",
          type: "bar",
          stack: "a",
          emphasis: {
            focus: "series",
          },
          data: data.stackedBattDischarge,
          color: "green",
        },
        {
          name: "Consumption",
          type: "bar",
          stack: "a",
          emphasis: {
            focus: "series",
          },
          data: data.stackedConsumption,
          color: "red",
        },
        {
          name: "Battery charging",
          type: "bar",
          stack: "a",
          emphasis: {
            focus: "series",
          },
          data: data.stackedBattCharge,
          color: "blue",
        },
        {
          name: "Grid injection",
          type: "bar",
          stack: "a",
          emphasis: {
            focus: "series",
          },
          data: data.stackedSellingPowGen,
          color: "orange",
        },
      ],
    };
  
    return option;
  }
  
  function createOptionsOfSelfConsumptionMobile(data) {
    const optionMobile = {
      legend: {
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
          fontSize: 10,
        },
        width: "80%",
        left: 0,
        
      },
    };
  
    return optionMobile;
  }
  
  export { createOptionsOfSelfConsumption, createOptionsOfSelfConsumptionMobile };