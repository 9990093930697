import { Link } from "react-router-dom";
import styles from "./Page404.module.css";

const Page404 = () => {
  return (
    <section className="videoSection">
      <video autoPlay loop muted className="video">
        <source src="/video/fondo_001.mp4" type="video/mp4" />
      </video>
      <div className={styles.container}>
        <h2 className={styles.title}>
          Page 404 <br /> <span className={styles.titleSpan}>Not Found</span>
        </h2>
        <p className={styles.message}>
          The page you are looking for is not available
        </p>
        <Link className={styles.button}>Go to home</Link>
      </div>
    </section>
  );
};

export default Page404;
