import { useContext } from "react";
import styles from "./Section7.module.css";
import HistoricalVsExpected from "./HistoricalVsExpected.chart";
import { BatteriesContext } from "../../context/BatteriesContext";
import { ProposalContext } from "../../context/ProposalContext";

const Section7 = ({reference}) => {
  const { setMonthlyConsumptionModal,monthsConsumptions } = useContext(BatteriesContext);
  const { onlyView,proposalData } = useContext(ProposalContext)

  const handleClick = () => {
    setMonthlyConsumptionModal((prev) => !prev);
  };

  return (
    <section id="section7" className={styles.sectionSeven} ref={reference}>
      <div className={styles.sectionSevenContainer} onClick={!onlyView ? handleClick : () => {}} >
        <HistoricalVsExpected proposalData={proposalData} monthsConsumptions={monthsConsumptions}  />
        {
          !onlyView &&
          <button className={styles.opButton}>
            Customize
          </button>
        }
      </div>
      <br />
    </section>
  );
};

export default Section7;
