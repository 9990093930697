// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_modalContainer__lgwNN {
  position: fixed;
  width: 80%;
  height: auto;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(98, 98, 98, 0.87);
  -webkit-backdrop-filter: blur(7.5px);
          backdrop-filter: blur(7.5px);
  border-radius: 10px;
  z-index: 30;
  animation: Modal_animate__dFNm9 0.5s ease-in-out forwards;
}

@keyframes Modal_animate__dFNm9 {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}


.Modal_headerContainer__ENJNa{
    display: flex;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid white;
}

.Modal_headerContainer__ENJNa .Modal_headerContainerTitle__GcYX8{
    width: 90%;
    margin: 0;
    text-align: center;
    font-family: var(--lexend); 
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #fff;
}

.Modal_headerContainerImg__s7jdd {
    width: 10%;
    height: auto;
  }
  
.Modal_headerContainerImg__s7jdd img {
    cursor: pointer;
  }

.Modal_tableContainer__oJO35{
    margin: 32px 16px;
}  


@media (max-width: 767.98px){

  .Modal_modalContainer__lgwNN {
    width: 98%;
  }

  .Modal_headerContainer__ENJNa .Modal_headerContainerTitle__GcYX8{
    width: 70%;
    text-align: left;
    margin-left: 16px;
    font-size: 1.2rem;
}

.Modal_headerContainerImg__s7jdd {
    width: 30%;
    height: auto;
  }

  
}`, "",{"version":3,"sources":["webpack://./src/components/Section2/Modal/Modal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,UAAU;EACV,YAAY;EACZ,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,kCAAkC;EAClC,oCAA4B;UAA5B,4BAA4B;EAC5B,mBAAmB;EACnB,WAAW;EACX,yDAA4C;AAC9C;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;;AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,UAAU;IACV,SAAS;IACT,kBAAkB;IAClB,0BAA0B;IAC1B,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,UAAU;IACV,YAAY;EACd;;AAEF;IACI,eAAe;EACjB;;AAEF;IACI,iBAAiB;AACrB;;;AAGA;;EAEE;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;IACV,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,YAAY;EACd;;;AAGF","sourcesContent":[".modalContainer {\n  position: fixed;\n  width: 80%;\n  height: auto;\n  top: 54%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background: rgba(98, 98, 98, 0.87);\n  backdrop-filter: blur(7.5px);\n  border-radius: 10px;\n  z-index: 30;\n  animation: animate 0.5s ease-in-out forwards;\n}\n\n@keyframes animate {\n  from{\n    opacity: 0;\n  }\n  to{\n    opacity: 1;\n  }\n}\n\n\n.headerContainer{\n    display: flex;\n    align-items: center;\n    padding: 16px 0;\n    border-bottom: 1px solid white;\n}\n\n.headerContainer .headerContainerTitle{\n    width: 90%;\n    margin: 0;\n    text-align: center;\n    font-family: var(--lexend); \n    font-size: 1.8rem;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n    color: #fff;\n}\n\n.headerContainerImg {\n    width: 10%;\n    height: auto;\n  }\n  \n.headerContainerImg img {\n    cursor: pointer;\n  }\n\n.tableContainer{\n    margin: 32px 16px;\n}  \n\n\n@media (max-width: 767.98px){\n\n  .modalContainer {\n    width: 98%;\n  }\n\n  .headerContainer .headerContainerTitle{\n    width: 70%;\n    text-align: left;\n    margin-left: 16px;\n    font-size: 1.2rem;\n}\n\n.headerContainerImg {\n    width: 30%;\n    height: auto;\n  }\n\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": `Modal_modalContainer__lgwNN`,
	"animate": `Modal_animate__dFNm9`,
	"headerContainer": `Modal_headerContainer__ENJNa`,
	"headerContainerTitle": `Modal_headerContainerTitle__GcYX8`,
	"headerContainerImg": `Modal_headerContainerImg__s7jdd`,
	"tableContainer": `Modal_tableContainer__oJO35`
};
export default ___CSS_LOADER_EXPORT___;
