import Background from "../../assets/img/circleBackgorund.svg";
import Circle from "../../assets/img/circle.svg";
import styles from "./Section9.module.css";
import CircleWhite from "../../assets/img/CircleWhite.svg";

const RouteInfo = ({ info, route, setRoute }) => {

  
  const handleClick = (title) => {
    const updatedRoute = route.map((item) => {
      if (item.title === title) {
        /* invert the property in the corresponding object for the selected item */
        return { ...item, isPressed: true };
      } else {
        /* Establish the isActive property to false for the other items */
        return { ...item, isPressed: false };
      }
    });

    /* Update the state "route" with the new array */
    setRoute(updatedRoute);
  };

  return (
    <div className={styles.routeInfo} onClick={() => handleClick(info.title)}>
      <div id={info.title} className={styles.imageContainer}>
        {info.id === 1 && <div className={styles.line}></div>}
        <img className={styles.imageBg} src={Background} alt="Button" />
        <img
          className={styles.imageCircle}
          src={info.isPressed ? Circle : CircleWhite}
          alt="Button"
        />
      </div>
      <p className={styles.routeText}>{info.title}</p>
    </div>
  );
};

export default RouteInfo;
