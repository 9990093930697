// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Error_container__cRMi1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 100vh;
  width: 50%;
  row-gap: 64px;
  padding-left: 64px;
}

.Error_title__WNFwj {
  color: #ffffff;
  font-size: 1.675rem;
  font-family: var(--lexend);
  font-weight: 600;
  line-height: 1.8;
  text-align: left;
  margin: 0;
}

.Error_titleSpan__ytjQT {
  color: var(--primary-color);
}

.Error_message__QF\\+Aq {
  color: #fff;
  font-size: 1.8rem;
  font-family: var(--lexend);
  font-weight: 600;
}

.Error_error__9uczQ {
  color: #fff;
  font-family: var(--lexend);
  font-weight: 600;
  text-align: left;
}

.Error_button__qK6Zt {
  background-color: var(--primary-color);
  border-radius: 10px;
  border-style: none;
  font-family: var(--lexend);
  padding: 8px 16px;
  border: 3px solid var(--primary-color);
  color: white;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
}

.Error_button__qK6Zt:hover {
  color: black!important;
}

@media (max-width: 768px) {
  .Error_container__cRMi1 {
    width: 100%;
    padding: 16px;
  }

  .Error_title__WNFwj {
    font-size: 1rem;
    font-weight: 500; 
  }

  .Error_message__QF\\+Aq {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Error/Error.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;EACb,UAAU;EACV,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,0BAA0B;EAC1B,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,0BAA0B;EAC1B,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,sCAAsC;EACtC,mBAAmB;EACnB,kBAAkB;EAClB,0BAA0B;EAC1B,iBAAiB;EACjB,sCAAsC;EACtC,YAAY;EACZ,gBAAgB;EAChB,gCAAgC;AAClC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE;IACE,WAAW;IACX,aAAa;EACf;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,SAAS;EACX;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: start;\n  height: 100vh;\n  width: 50%;\n  row-gap: 64px;\n  padding-left: 64px;\n}\n\n.title {\n  color: #ffffff;\n  font-size: 1.675rem;\n  font-family: var(--lexend);\n  font-weight: 600;\n  line-height: 1.8;\n  text-align: left;\n  margin: 0;\n}\n\n.titleSpan {\n  color: var(--primary-color);\n}\n\n.message {\n  color: #fff;\n  font-size: 1.8rem;\n  font-family: var(--lexend);\n  font-weight: 600;\n}\n\n.error {\n  color: #fff;\n  font-family: var(--lexend);\n  font-weight: 600;\n  text-align: left;\n}\n\n.button {\n  background-color: var(--primary-color);\n  border-radius: 10px;\n  border-style: none;\n  font-family: var(--lexend);\n  padding: 8px 16px;\n  border: 3px solid var(--primary-color);\n  color: white;\n  font-weight: 400;\n  transition: all 0.2s ease-in-out;\n}\n\n.button:hover {\n  color: black!important;\n}\n\n@media (max-width: 768px) {\n  .container {\n    width: 100%;\n    padding: 16px;\n  }\n\n  .title {\n    font-size: 1rem;\n    font-weight: 500; \n  }\n\n  .message {\n    font-size: 1rem;\n    font-weight: 500;\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Error_container__cRMi1`,
	"title": `Error_title__WNFwj`,
	"titleSpan": `Error_titleSpan__ytjQT`,
	"message": `Error_message__QF+Aq`,
	"error": `Error_error__9uczQ`,
	"button": `Error_button__qK6Zt`
};
export default ___CSS_LOADER_EXPORT___;
