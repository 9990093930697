import sourceImage from "../../assets/img/emphase5-1.webp";
import styles from "./OffCanvasSection4.module.css";
import trash from "../../assets/img/trash-black.svg";
import useSave from "../../CustomHooks/useSave";
import { useEffect, useState } from "react";

const OffCanvasSection4 = ({
  decrementValue,
  incrementValue,
  changeBatteryType,
  selectedBattery,
  selectedBatteries,
  totalEstimatedSaving,
  reset,
  setReset,
  setSelectedBatteryIndex,
}) => {
  const [indexArray, setIndexArray] = useState(0);

  useEffect(() => {
    const index = selectedBatteries.findIndex((bat) => bat !== 0);
    setIndexArray(index);
  }, [selectedBatteries]);

  const resetClick = () => {
    setSelectedBatteryIndex(indexArray);
    setReset(!reset);
  };

  return (
    <div className={styles.container}>
      <img
        className={styles.leftPosition}
        src="/icons/OpLeft.svg"
        alt="LeftButton"
        onClick={() => changeBatteryType("left")}
      />
      <img
        className={styles.rightPosition}
        src="/icons/OpRight.svg"
        alt="Right Button"
        onClick={() => changeBatteryType("right")}
      />
      <div className={styles.imageContainer}>
        <img
          className={styles.image}
          src={selectedBattery.image || sourceImage}
          alt="Battery"
        />
      </div>
      <div className={styles.information}>
        <h3 className={styles.informationTitle}>{selectedBattery.name}</h3>
        <p className={styles.info}>
          <span>Energy capacity: </span> {selectedBattery.capacity} kWh*
        </p>
        <p className={styles.info}>
          <span>Power rating:</span>
          <span>3.84kW</span>
        </p>
        <p className={styles.info}>
          <span>Manufacturer Warranty:</span>
          <span>10 years</span>
        </p>
        <p className={`${styles.info} ${styles.backup}`}>
          Battery Backup Included*
        </p>
        <div className={styles.separate}></div>
        <div className={styles.IqBatteryContainer}>
          <div className={styles.buttonsPriceContainer}>
            <div className={styles.priceContainer}>
              <p className={styles.informationTitle2}>Unit Price</p>
              <div className={styles.priceButtonContainer}>
                <p className={styles.informationTitle2}>
                  ${selectedBattery.price}
                </p>
                <button className={styles.buttonAdd} onClick={incrementValue}>
                  ADD
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {totalEstimatedSaving > 0 && (
        <>
          <div className={styles.selected}>
            <div className={styles.titleContainer}>
              <p>Quantity</p>
              <p>Total Cost</p>
            </div>
            <div className={styles.selectedInfoContainer}>
              <p>
                {selectedBatteries.map((quantity) => {
                  if (!quantity) return null;

                  return quantity;
                })}
              </p>
              <p>${totalEstimatedSaving}</p>
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <button className={styles.button}>
              <img
                className={styles.trash}
                src={trash}
                alt="trash"
                onClick={resetClick}
              />
            </button>
            <button className={styles.button} onClick={decrementValue}>
              -
            </button>
            <button className={styles.button} onClick={incrementValue}>
              +
            </button>
          </div>
          <h3 className={styles.selectedTitle}>
            Selected Battery/ies: {selectedBattery.name}
          </h3>
        </>
      )}
    </div>
  );
};

export default OffCanvasSection4;
