import { createContext, useEffect, useRef, useState } from "react";

const ProposalContext = createContext();

const ProposalProvider = ({ children }) => {

  const billsRef = useRef(null);
  const utillityBillsRef = useRef(null);
  const utBillsRef = useRef(null) 
  /* Boolean */
  const [isFetching, setIsFetching] = useState(true);
  const [onlyView, setOnlyView] = useState(false);
  const [error, setError] = useState(null); // Error of the first fetch, if is true, the app will show the error page and the status message
  const [saveStatus, setSaveStatus] = useState(true); // Status of the save button for the proposal
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [autoSaveStatus, setAutoSaveStatus] = useState(null);
  const [isShadeReportPDF, setIsShadeReportPDF] = useState(false);
  /* Object */
  /*   const [proposalClientUrl, setProposalClientUrl] = useState(null); */
  
  const [proposalData, setProposalData] = useState(null); // Principal object of the proposal
  const [information, setInformation] = useState({
    eveningUsage: "Average",
    backupPercentage: 50,
    hours: 3,
    ratedPowerKW: 5,
    peakKW: 5,
    federalTaxCredit: 30,
    escalationRate: 4,
    dcToAcRatio: 0.9,
    creditAmount: 100,
    estimatedBudget: 0,
    overridePricePerWatt: 0,
    profit: 0,
  }); // Object with the information of the proposal that the user can change
  
  const [overrideAndProfit, setOverrideAndProfit] = useState({
    override: 0,
    profit: 0,
  });

  /* IDs */
  const [projectId, setProjectId] = useState(null); // Number
  const [moduleId, setModuleId] = useState(""); // String
  const [inverterId, setInverterId] = useState(""); // String
  
  /* Number */
  const [value, setValue] = useState(0); // Proposal Price (check)
  const [totalEstimatedSaving, setTotalEstimatedSaving] = useState(null); // Total estimated cost of batteries (check the name)
  const [savingsAnnual, setSavingsAnnual] = useState(null); // Total estimated savings of the proposal (check the name)
  const [savingsAnnualDataIndex, setSavingsAnnualDataIndex] = useState(null); // Index of the savings annual data selected by the user
  const [basePricePerWatt, setBasePricePerWatt] = useState(null);
  /* String */
  const [proposalStatusLegend, setProposalStatusLegend] = useState("");
  const [proposalOnlyViewLink,setProposalOnlyViewLink] = useState("");
  

  
  /* AutoSave Alert */
  useEffect(() => {
    let timeOutAutoSave;
    
    timeOutAutoSave = setTimeout(() => {
      if (autoSaveStatus !== null) setAutoSaveStatus(null);
    }, 3000);
    
    return () => {
      clearTimeout(timeOutAutoSave);
    };
  }, [autoSaveStatus]);

  useEffect(() => {
    setSavingsAnnual(proposalData?.savings_annual);
  }, [proposalData]);

  return (
    <ProposalContext.Provider
      value={{
        proposalData,
        setProposalData,
        projectId,
        setProjectId,
        moduleId,
        setModuleId,
        inverterId,
        setInverterId,
        value,
        setValue,
        totalEstimatedSaving,
        setTotalEstimatedSaving,
        information,
        setInformation,
        error,
        setError,
        saveStatus,
        setSaveStatus,
        saveSuccess,
        setSaveSuccess,
        proposalStatusLegend,
        setProposalStatusLegend,
        autoSaveStatus,
        setAutoSaveStatus,
        isFetching,
        setIsFetching,
        savingsAnnual,
        setSavingsAnnual,
        savingsAnnualDataIndex,
        setSavingsAnnualDataIndex,
        overrideAndProfit,
        setOverrideAndProfit,
        basePricePerWatt,
        setBasePricePerWatt,
        onlyView,
        setOnlyView,
        billsRef,
        utillityBillsRef,
        utBillsRef,
        proposalOnlyViewLink,
        setProposalOnlyViewLink,
        isShadeReportPDF,
        setIsShadeReportPDF,
      }}
    >
      {children}
    </ProposalContext.Provider>
  );
};

export { ProposalContext, ProposalProvider };
