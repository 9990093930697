import React, { useContext, useEffect } from "react";
import styles from "./Electrical.module.css";
import SubMenu from "./SubMenu/SubMenu";
import { AddersContext } from "../../../../context/AddersContext";
import { ProposalContext } from "../../../../context/ProposalContext";

const Electrical = () => {
  const { proposalData, value, setValue, onlyView } =
    useContext(ProposalContext);
  const { adders, setAdders } = useContext(AddersContext);

  const isASmallSystem = (id) => {
    /*     (adder.id === "ADDE-0020" &&
    (proposalData?.realSystemCapacity ||
      proposalData?.systemCapacity) < 4
      ? true
      : onlyView
      ? true
      : false) */

    if (id !== "ADDE-0020") return false;

    if (
      (proposalData?.realSystemCapacity || proposalData?.systemCapacity) < 4 ||
      onlyView
    )
      return true;

    return false;
  };

  const handleChangeInInput = (event, adder) => {
    let valueOfInput;

    if (event) valueOfInput = event.target.value;
    else valueOfInput = adder.qty;

    if (isNaN(valueOfInput) || valueOfInput < 0) return;

    let newArrOfAdders = [...adders];
    const index = newArrOfAdders.findIndex((add) => add.id === adder.id);
    const oldQty = Math.ceil(parseInt(newArrOfAdders[index].qty));
    const oldValue = oldQty * adder.price;
    const newQty = valueOfInput;
    const newTotalPrice = newQty * adder.price;

    // Resta el valor anterior antes de actualizar
    const newValue = value - oldValue + newTotalPrice; // Se elimina la resta de newAdder.price

    newArrOfAdders[index].totalPrice = newTotalPrice;
    newArrOfAdders[index].qty = event?.target.value || adder.qty;

    setAdders([...newArrOfAdders]);
    setValue(newValue);
  };

  const removeUnits = (adder) => {
    const newArrAdders = [...adders];
    const index = newArrAdders.findIndex((add) => add.id === adder.id);
    setValue(value - newArrAdders[index].totalPrice);
    delete newArrAdders[index].qty;
    delete newArrAdders[index].totalPrice;
    setAdders([...newArrAdders]);
  };

  const handleChange = (id, isChecked) => {
    if (onlyView) return;
    if (isASmallSystem(id)) return;

    const newArrAdders = [...adders];
    const index = newArrAdders.findIndex((adder) => adder.id === id);
    // Obtener el valor del adder
    const adderValue = newArrAdders[index].totalPrice
      ? newArrAdders[index].totalPrice
      : newArrAdders[index].price;
    newArrAdders[index].isChecked = !newArrAdders[index].isChecked;

    if (newArrAdders[index].measurementUnit) {
      if (!isChecked) {
        removeUnits(newArrAdders[index]);
        return;
      }

      newArrAdders[index].qty = "1";
      newArrAdders[index].totalPrice = "1";
      handleChangeInInput(null, newArrAdders[index]);
      setAdders([...newArrAdders]);
      setValue(value + newArrAdders[index].price);
      return;
    }

    if (isChecked) {
      const newValue = value + adderValue;
      setValue(newValue);
    } else {
      const newValue = value - adderValue;
      setValue(newValue);
    }
    setAdders([...newArrAdders]);
  };
  return (
    <div className={styles.electricalContainer}>
      {adders.map((adder) => {
        return (
          <div
            className={onlyView && !adder?.isChecked && "d-none"}
            key={adder.id}
          >
            <div
              className={`${styles.optionContainer} ${
                adder?.isChecked
                  ? adder?.measurementUnit
                    ? styles.removeMarginB
                    : ""
                  : ""
              }`}
              onClick={() => handleChange(adder.id, !adder.isChecked)}
            >
              <input
                readOnly
                id={adder.id}
                className={styles.checkbox}
                type="checkbox"
                name={adder.name}
                value={
                  adder.isChecked ||
                  (adder.id === "ADDE-0020" && isASmallSystem(adder.id))
                }
                checked={adder.isChecked || isASmallSystem(adder.id)}
                disabled={adder.id === "ADDE-0020" && isASmallSystem(adder.id)}
              />
              <div className={styles.info}>
                <p>{adder.name}</p>
                <p>
                  ${adder.price.toFixed(2)}{" "}
                  {adder.measurementUnit && `per ${adder.measurementUnit}`}
                </p>
              </div>
            </div>
            {adder.measurementUnit && adder.isChecked && (
              <SubMenu
                adder={adder}
                handleChangeInInput={handleChangeInInput}
                removeUnits={removeUnits}
                onlyView={onlyView}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Electrical;
