// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Section3R_section3Container__mOEqY{
    display: flex;
    width: 100%;
    height: auto;
}

@media (max-width: 992px){
    .Section3R_section3Container__mOEqY{
        flex-direction: column;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Section3Reloaded/Section3R.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,YAAY;AAChB;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".section3Container{\n    display: flex;\n    width: 100%;\n    height: auto;\n}\n\n@media (max-width: 992px){\n    .section3Container{\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section3Container": `Section3R_section3Container__mOEqY`
};
export default ___CSS_LOADER_EXPORT___;
