import styles from "./Section5.module.css";
import sourceImageCasa from "../../assets/house/clipfile.webp";
import { useContext } from "react";
import Circle from "./Circle";
import { AddersContext } from "../../context/AddersContext";
import { MenuContext } from "../../context/MenuContext";

const Section5 = () => {
  const { setMenuAdder } = useContext(MenuContext);
  const { setAdderOption, formIsSelected, setFormIsSelected } =
    useContext(AddersContext);

  const handleClick = (event) => {
    setAdderOption(event.currentTarget.id);
    setMenuAdder(true);
    const property = event.currentTarget.id;

    const updatedFormIsSelected = {};

    for (const prop in formIsSelected) {
      if (formIsSelected.hasOwnProperty(prop) && prop !== "isPressed") {
        updatedFormIsSelected[prop] = false;
      }
    }

    updatedFormIsSelected[property] = true;

    setFormIsSelected(updatedFormIsSelected);
  };

  return (
    <section id="section5" className={styles.section}>
      <div className={styles.containerSection}>
        <div className={styles.containerTitle}>
          <h3>Auxilary Products</h3>
        </div>
        <div className={`${styles.informationContainer} `}>
          <div
            className={`${styles.infoImageContainer} ${styles.imageTransform} `}
          >
            <div className={styles.containerImagePosition}>
              <Circle
                variable={"circleContainer"}
                handleClick={handleClick}
                id={"Painting"}
                formIsSelected={formIsSelected.Painting}
                text={"Exterior"}
                left={"tooltipL"}
              />
              <Circle
                variable={"circleContainerTwo"}
                handleClick={handleClick}
                id={"Roofing"}
                formIsSelected={formIsSelected.Roofing}
                text={"Roofing"}
              />
              <img
                className={styles.imageTecho}
                src={sourceImageCasa}
                alt="Emphase"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section5;
