import styles from "./Tables.module.css"

const SystemInfo = ({info}) => {
  return (
    <div className={styles.containerTable}>
    <div className={`${styles.titlesContainer} ${styles.arrayTitlesContainer}`}>
      <p>Array</p>
      <p>Modules</p>
      <p>Azimuth</p>
      <p>Tilt</p>
    </div>
    <div className={styles.objectsContainer}>
      {
        info.map(i => {
          return (
            <div className={`${styles.objectContainer} ${styles.arrObjectContainer}`} key={i.array}>
              <span>{i.array || "-"}</span>   
              <span>{i.modules || "-"}</span>   
              <span>{i.azimuth.join(" / ") || "-"}</span>   
              <span>{i.tilt.join(" / ") || "-"}</span>   
            </div>
          )
        })
      }
    </div>
  </div>
  )
}

export default SystemInfo