import { useContext } from "react";
import styles from "../CostOverview.module.css";
import { OverlayTrigger } from "react-bootstrap";
import { MenuContext } from "../../../../../context/MenuContext";
import { ProposalContext } from "../../../../../context/ProposalContext";

const AditionalFeesAndCosts = ({
  selectedPaymentOption,
  proposalData,
  overrideValue,
  handleNumberChange,
  finalPricePerWatt,
  tooltip,
}) => {
  const { setModalOverride } = useContext(MenuContext);
  const { information, basePricePerWatt, onlyView } =
    useContext(ProposalContext);

  const handleClickInEditOverride = () => setModalOverride(true);
  return (
    <div className={styles.tableContainer}>
      <div className={styles.category}>
        <h3>Aditional Fees and Costs</h3>
      </div>
      <table className={styles.section5Table}>
        <tbody className={styles.elementContainer}>
          <tr className={styles.element}>
            <td>Federal tax credit</td>
            <td>${proposalData.baseFederalTax}</td>
          </tr>
          {!onlyView && (
            <tr className={styles.element}>
              <td> Override Base Price per Watt</td>
              <td>
                <div className="d-flex align-items-center">
                  <div className={styles.overrideInputContainer}>
                    <span>{information.overridePricePerWatt} $/W</span>
                    <img
                      onClick={handleClickInEditOverride}
                      className={styles.editButton}
                      src="/icons/edit_white.svg"
                      alt="can change"
                    />
                  </div>
                </div>
              </td>
            </tr>
          )}

          <tr className={styles.element}>
            <td> Final Price per Watt</td>
            <td>
              {overrideValue >= basePricePerWatt
                ? finalPricePerWatt?.toFixed(2)
                : "-"}
              $/w
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AditionalFeesAndCosts;
