import React, { Fragment, useContext, useEffect, useRef } from "react";
import { Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./Menu.module.css";
import list from "./imagesFont.json";
import MenuOpen from "./MenuOpen";
import imagesFont from "./imagesFont.json";
import { MenuContext } from "../../context/MenuContext";
import { BatteriesContext } from "../../context/BatteriesContext";
import { useNavigate } from "react-router-dom";
import { ProposalContext } from "../../context/ProposalContext";

const Menu = () => {
  const {
    menuAdder,
    setMenuAdder,
    menuSettings,
    setMenuSettings,
    menuOpen,
    setMenuOpen,
    menuSystemPricing,
    setMenuSystemPricing,
  } = useContext(MenuContext);

  const { setBatteriesSettings, batteriesSettings } =
    useContext(BatteriesContext);

  const { isShadeReportPDF, setIsShadeReportPDF } = useContext(ProposalContext);

  const menuRef = useRef([]);

  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setMenuAdder(false);
    setMenuSystemPricing(false);
  };

  const handleClick = (event) => {
    const listName = event.currentTarget.id;
    const item = imagesFont.find((i) => i.name === listName);
    const isAnchor = event.currentTarget.getAttribute("is-anchor");

    if (listName === "adders") setMenuAdder(!menuAdder);
    else if (listName === "systemPricing")
      setMenuSystemPricing(!menuSystemPricing);
    else if (listName === "settings") setMenuSettings(!menuSettings);
    else if (listName === "batteries") setBatteriesSettings(!batteriesSettings);
    else if (listName === "shadeReport") setIsShadeReportPDF((prev) => !prev);

    if (isAnchor) return;

    if (!item.hasOwnProperty("section")) setMenuOpen(!menuOpen);
  };

  const renderOverlay = (item) => (
    <Tooltip id={`tooltip-${item.name}`}>{`${item.displayName}`}</Tooltip>
  );

  return (
    <>
      {!menuOpen ? (
        <div className={styles.menuMobile}>
          <div className={styles.homeContainer} onClick={toggleMenu}>
            <img
              width={25}
              height={25}
              src="/img/home.webp"
              alt="home"
              className={styles.home}
            />
          </div>
          <div className={styles.menu}>
            {list.map((item, index) => (
              <Fragment key={item.name}>
                <OverlayTrigger placement="left" overlay={renderOverlay(item)}>
                  <a
                    variant="light"
                    className={`${styles.itemContainer} ${
                      item.name === "adders" && styles.line
                    } ${
                      item.name === "batteries" && styles.batteriesBackground
                    }`}
                    onClick={(event) => {
                      if (item.section) {
                        window.location.replace(`#section${item.section}`);
                        // navigate(`#section${item.section}`)
                      }
                      handleClick(event);
                    }}
                    // href={item.section ? `#section${item.section}` : null}
                    // href={item.section ? window.location.replace("/",item.section) : null}
                    ref={(el) => (menuRef.current[index] = el)}
                    id={item.name}
                    is-anchor="true"
                    data-index={index}
                  >
                    <Image
                      className={styles.iconImage}
                      src={item.url}
                      alt={item.name}
                    />
                  </a>
                </OverlayTrigger>
              </Fragment>
            ))}
          </div>
        </div>
      ) : (
        <MenuOpen handleClick={handleClick} />
      )}
    </>
  );
};

export default Menu;
