import { NavLink, useNavigate } from "react-router-dom";
import styles from "./Error.module.css";
import { AuthContext } from "../../context/AuthContext";
import { useContext, useState } from "react";
import { ProposalContext } from "../../context/ProposalContext";
import { AppContext } from "../../context/AppContext";

const Error = ({ error }) => {
  const { token } = useContext(AuthContext);
  const {  url } = useContext(AppContext);
  const {  setError } = useContext(ProposalContext);
  const [param, setParam] = useState("");
  const navigate = useNavigate();

  /* Reload proposal is not working properly, fix later */



  const handleClick = () => {
    window.location.href = url;
    
    if (
      window.location.href.includes("redirect?token=") ||
      window.location.href.includes("project/")
    ) {
      window.location.reload();
    } else {
      setError(false);
      navigate("/404");
    }
  };

  return (
    <section className="videoSection">
      <video autoPlay loop muted className="video">
        <source src="/video/fondo_001.mp4" type="video/mp4" />
      </video>
      <div className={styles.container}>
        <h2 className={styles.title}>
          Oops! Something went wrong.
          <br />
          We couldn't load the proposal at the moment.
          <br />
          <span className={styles.titleSpan}> Please try again later.</span>
        </h2>
        <div className="d-flex flex-column align-items-start">
          <p className={styles.message}>Error type:</p>
          <p className={styles.error}>{error || "-"}</p>
        </div>
        <div>
          <NavLink
            to={`${url}/${param}`}
            onClick={handleClick}
            className={styles.button}
          >
            Reload Proposal
          </NavLink>
        </div>
      </div>
    </section>
  );
};

export default Error;
