import styles from "../CostOverview.module.css";

const SolarEnergyRates = ({ proposalData }) => {
  return (
    <div className={styles.tableContainer}>
      <div className={styles.category}>
        <h3>Solar Energy Rates</h3>
      </div>
      <table className={styles.section5Table}>
        <tbody className={styles.elementContainer}>
{/*           <tr className={styles.element}>
            <td>Effective solar energy rate</td>
            <td> $- </td> 
          </tr>  check */}
          <tr className={styles.element}>
            <td>Rate Escalation</td>
            <td> {proposalData.project.escalationRate || "-"}%</td>
          </tr>
          <tr className={styles.element}>
            <td> DC to AC Rate</td>
            <td> {proposalData.project.dcToAcRatio || "-"}</td>
          </tr>
          <tr className={styles.element}>
            <td>Production Offset</td>
            <td> {proposalData.project.productionOffset || "-"}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SolarEnergyRates;
