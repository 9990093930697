// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Warning_containerWarning__RQEQN {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background: rgba(98, 98, 98, 0.87);
  -webkit-backdrop-filter: blur(7.5px);
  backdrop-filter: blur(7.5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 32px;
  padding: 32px;
  z-index: 10000;
}

.Warning_containerWarning__RQEQN img {
  width: 100px;
  height: auto;
}

.Warning_containerWarning__RQEQN p {
    color: #fff;
    font-size: 1.3rem;
    font-family:  'Lexend', sans-serif; 
}

.Warning_containerWarning__RQEQN button {
    padding: 8px 16px;
    border-radius: 10px;
    background-color: #fbb800;
    font-family:  'Lexend', sans-serif; 
    font-size: 1.3rem;
    color: #fff;
    font-weight: 600;
    border-style: none;
}

.Warning_containerWarning__RQEQN button:hover {
    background-color: rgba(0,200,217);
}


`, "",{"version":3,"sources":["webpack://./src/components/Warning/Warning.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,mBAAmB;EACnB,kCAAkC;EAClC,oCAAoC;EACpC,4BAA4B;EAC5B,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,kCAAkC;AACtC;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,yBAAyB;IACzB,kCAAkC;IAClC,iBAAiB;IACjB,WAAW;IACX,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,iCAAiC;AACrC","sourcesContent":[".containerWarning {\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  border-radius: 10px;\n  background: rgba(98, 98, 98, 0.87);\n  -webkit-backdrop-filter: blur(7.5px);\n  backdrop-filter: blur(7.5px);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  row-gap: 32px;\n  padding: 32px;\n  z-index: 10000;\n}\n\n.containerWarning img {\n  width: 100px;\n  height: auto;\n}\n\n.containerWarning p {\n    color: #fff;\n    font-size: 1.3rem;\n    font-family:  'Lexend', sans-serif; \n}\n\n.containerWarning button {\n    padding: 8px 16px;\n    border-radius: 10px;\n    background-color: #fbb800;\n    font-family:  'Lexend', sans-serif; \n    font-size: 1.3rem;\n    color: #fff;\n    font-weight: 600;\n    border-style: none;\n}\n\n.containerWarning button:hover {\n    background-color: rgba(0,200,217);\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerWarning": `Warning_containerWarning__RQEQN`
};
export default ___CSS_LOADER_EXPORT___;
