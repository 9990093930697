import React, { useContext, useState } from "react";
import styles from "./Settings.module.css";
import { FinancialContext } from "../../../context/FinancialContext";
import { ProposalContext } from "../../../context/ProposalContext";
import { MenuContext } from "../../../context/MenuContext";

const Settings = () => {
  const { setMenuSettings, menuSettings } = useContext(MenuContext);

  const { information, setInformation, onlyView, proposalData } =
    useContext(ProposalContext);
  const { setTaxCreditValue } = useContext(FinancialContext);

  const [stAnimation, setStAnimation] = useState(false);

  /* realizar animación a cerrar el menú */
  const handleClickInCloseButton = () => {
    setStAnimation(!stAnimation);
    setTimeout(() => {
      setStAnimation(!stAnimation);
      setMenuSettings(!menuSettings);
    }, 400);
  };

  const update = (event) => {
    let id = event.target.id;
    let ActuallyInfo = { ...information };

    if (id === "escalationRate") {
      setInformation({
        ...ActuallyInfo,
        escalationRate: parseInt(event.target.value),
      });
    } else if (id === "dcToAcRatio") {
      setInformation({
        ...ActuallyInfo,
        dcToAcRatio: parseFloat(event.target.value),
      });
    } else if (id === "taxCredit") {
      setInformation({
        ...ActuallyInfo,
        federalTaxCredit: parseInt(event.target.value),
      });
      let calc =
        parseFloat(proposalData?.baseFinalPrice) *
        (parseFloat(event.target.value) / 100);
      let totalProposal = parseFloat(calc.toFixed(2));
      setTaxCreditValue(totalProposal);
    }
  };

  return (
    <div className={`${styles.modal}  ${stAnimation && styles.spAnimateEnd}`}>
      <div className={styles.modalTitleContainer}>
        <h3>Settings</h3>
        <div className={styles.modalTitleContainerImg}>
          <img
            onClick={handleClickInCloseButton}
            src="/icons/close_ring_duotone.svg"
            alt="close"
          />
        </div>
      </div>
      <div className={styles.menuContainer}>
        <div className={styles.infoContainer}>
          <div className={styles.settingContainer}>
            <p>Utility bill escalation rate</p>
            <div>
              {!onlyView && (
                <input
                  id="escalationRate"
                  className={styles.input}
                  type="range"
                  min={0}
                  value={information.escalationRate}
                  max={10}
                  onChange={(event) => update(event)}
                  disabled={onlyView}
                />
              )}
              <output className={styles.output}>
                {information.escalationRate}%
              </output>
            </div>
          </div>
          <div className={styles.settingContainer}>
            <p>DC to AC Ratio</p>
            <div>
              {!onlyView && (
                <input
                  id="dcToAcRatio"
                  className={styles.input}
                  type="range"
                  min={0.9}
                  value={information.dcToAcRatio}
                  max={2}
                  step={0.1}
                  onChange={(event) => update(event)}
                  disabled={onlyView}
                />
              )}

              <output className={styles.output}>
                {information.dcToAcRatio}%
              </output>
            </div>
          </div>
          <div className={styles.settingContainer}>
            <p>Federal Tax Credit</p>
            <div>
              {!onlyView && (
                <input
                  id="taxCredit"
                  className={styles.input}
                  type="range"
                  min={0}
                  value={information.federalTaxCredit}
                  max={32}
                  onChange={(event) => update(event)}
                  disabled={onlyView}
                />
              )}

              <output className={styles.output}>
                {information.federalTaxCredit}%
              </output>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
