import React, { useState, useRef, useContext } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { AppContext } from "../../../../context/AppContext";
import styles from "./VideoCarousel.module.css";

const VideoCarousel3 = () => {
  const { handleVideoLoad, videosLoadStatus } = useContext(AppContext);
  const [selectedVideo, setSelectedVideo] = useState(0);
  const videoRefs = useRef([]);
  const [isPlaying, setIsPlaying] = useState(false);

  /* videos that will be displayed in the carousel, the videos are saved in the public folder */
  const videoUrls = ["/video/Carbon.mp4", "/video/Tree.mp4", "/video/Gas.mp4"];

  /* thumnails that will be displayed in the carousel */
  const thumbnails = [
    {
      title1: "Turns of coal",
      title2: "burned",
      imgSrc: "/img/fabrica-sec3.webp",
      id: "carbon",
      bg: "/img/preloadImage/carbon.webp",
    },
    {
      title1: "Trees ",
      title2: "Planted",
      imgSrc: "/img/arbol-sec3.webp",
      id: "tree",
      bg: "/img/preloadImage/tree.webp",
    },
    {
      title1: "Barrels of",
      title2: "Oil",
      imgSrc: "/img/auto-sec3.webp",
      id: "gas",
      bg: "/img/preloadImage/gas.webp",
    },
  ];

  const handleImageClick = (index) => {
    setSelectedVideo(index);
    setIsPlaying(true);
    videoRefs.current[index].play();
  };

  return (
    <>
      <div className={styles.videoCarouselContainer}>
        <div className={styles.carouselContainer}>
          <Carousel
            showThumbs={false}
            selectedItem={selectedVideo}
            showStatus={false}
            showArrows={false}
            swipeable={false}
            dynamicHeight={100}
          >
            {videoUrls.map((videoUrl, index) => (
              <div className="w-100 h-100" key={index}>
                <video
                  id={thumbnails[index].id}
                  ref={(el) => (videoRefs.current[index] = el)}
                  controls={false}
                  loop
                  autoPlay={isPlaying}
                  muted
                  className="imageClassCarousel"
                  style={{
                    width: "73%",
                    height: "100%",
                    backgroundImage: !videosLoadStatus[thumbnails[index].id]
                      ? `url(${thumbnails[index].bg})`
                      : "none",
                  }}
                  onLoadedData={(event) => handleVideoLoad(event.target.id)}
                >
                  <source src={videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
      <div className={styles.controlsContainer}>
        <div className={styles.controls}>
          {thumbnails.map((thumbnail, index) => (
            <div
              key={index}
              className={styles.control}
              onClick={() => handleImageClick(index)}
            >
              <img src={thumbnail.imgSrc} alt={`Video ${index + 1}`} />
              <div className={styles.controlText}>
                {thumbnail.title1}
                <br />
                {thumbnail.title2}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default VideoCarousel3;
