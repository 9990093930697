import {  useEffect } from "react";
import * as echarts from "echarts";
import createOptionsOfCostLineChart from "./costLine.chart.config";

const BillsChart = ({height,proposalData}) => {


  /* Chart */
  useEffect(() => {
    const myChart = echarts.init(document.getElementById("bills")); // initialize chart
    const data = ["1", "5", "10", "15", "20", "25", "30"]; // x-axis data
    const links = data.map(function (item, i) {
      return {
        source: i,
        target: i + 1,
      };
    }); // links between x-axis data
    links.pop(); // remove last link

    /* Chart Options */
    const option = createOptionsOfCostLineChart(proposalData, data, links);
    myChart.setOption(option);

    function resize() {
      myChart.resize();
    }

    /* Resize chart on window resize */
    window.addEventListener("resize", resize);

    return () => {
      myChart.dispose();
      window.removeEventListener("resize", () => resize);
    };
  }, [proposalData.thirtyYearsBillProjection]);

  return (
    <div
      id="bills"
      style={{
        width: "100%",
        height: height,
        paddingTop: "30px",
        paddingLeft: "16px",
        paddingRight: "16px",
      }}
    />
  );
};

export default BillsChart;
