import React from "react";
import Section6Part1 from "./Section6Part1";
import Section6Part2 from "./Section6Part2";
import Section6Part3 from "./Sec6Part3";
import Section6Part4 from "./Sec6Part4";
import "../../styles.css";
import styles from "./Section6.module.css";
import VideoComponent from "../VideoComponent/VideoComponent";

const Section6 = ({reference}) => {
  return (
    <section id="section6" className={styles.videoSection} ref={reference}>
      <VideoComponent
        id={"fondo_006"}
        source={"/video/fondo_006.mp4"}
        background={"/img/preloadImage/fondo-006.webp"}
      />
      <div className={styles.sectionContainer}>
        <Section6Part1></Section6Part1>
        <Section6Part2></Section6Part2>
        <Section6Part3></Section6Part3>
        <Section6Part4></Section6Part4>
      </div>
    </section>
  );
};

export default Section6;
