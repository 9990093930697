import { useContext, useEffect, useRef, useState } from "react";
import styles from "./Tariffs.module.css";
import { BatteriesContext } from "../../../context/BatteriesContext";
import { ProposalContext } from "../../../context/ProposalContext";

const Tariffs = () => {
  const inputRef = useRef(null);
  const { tariffs, selectedTariff, setSelectedTariff } =
    useContext(BatteriesContext);
  const { onlyView } = useContext(ProposalContext);
  const [tariffsFiltered, setTariffsFiltered] = useState(tariffs);
  const [isInInput, setIsInInput] = useState(false);

  useEffect(() => {
    inputRef.current.value = `${selectedTariff.code} ${selectedTariff.name}`;
  }, []);

  const focus = () => {
    setIsInInput(true);
  };

  const onBlurFunction = () => {
    setIsInInput(false);
  };

  const handleChange = (event) => {
    const value = event.target.value.toLowerCase();

    if (value === "") {
      setTariffsFiltered(tariffs);
      return;
    }

    const newTariffsFileterd = tariffs.filter(
      (tariff) =>
        tariff.name.toLowerCase().includes(value) ||
        tariff.utility.toLowerCase().includes(value) ||
        tariff.code.toLowerCase().includes(value) ||
        tariff.state.toLowerCase().includes(value) ||
        tariff.stateAbb.toLowerCase().includes(value)
    );
    setTariffsFiltered(newTariffsFileterd);
  };

  const handleClickInTariff = (id) => {
    const tariffSelectedFounded = tariffs.find(
      (tariff) => tariff.id === Number(id)
    );

    setIsInInput(false);
    setSelectedTariff(tariffSelectedFounded);
    inputRef.current.value = `${tariffSelectedFounded.code} ${tariffSelectedFounded.name}`;
  };

  return (
    <div className={styles.searchContainer}>
      <h2 className={styles.title}>Rate</h2>
      <div className={styles.inputContainer}>
        <input
          placeholder={"Select an item"}
          className={styles.searchBar}
          type="text"
          onFocus={focus}
          onChange={handleChange}
          ref={inputRef}
          disabled={onlyView}
        />

        {!onlyView && <img src="/icons/search.svg" alt="search" />}
        {!onlyView && (
          <div className={styles.tariffs} onBlur={onBlurFunction}>
            {isInInput &&
              tariffsFiltered.map((tariff) => {
                return (
                  <div
                    key={tariff.id}
                    className={styles.tariff}
                    id={tariff.id}
                    onClick={() => handleClickInTariff(tariff.id)}
                  >
                    <p className={styles.info}>
                      <span>
                        {tariff.utility} - {tariff.stateAbb}
                      </span>
                      <br />
                      {tariff.code} {tariff.name}
                    </p>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Tariffs;
