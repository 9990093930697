import styles from "./Warning.module.css";
import warning from "../../assets/img/warning.svg";
const Warning = ({ setShowModalWarning }) => {
  return (
    <div className={styles.containerWarning}>
      <img src={warning} alt="warning" />
      <p>Warning</p>
      <p>your new consumption is far from your old consumption</p>
      <button  onClick={() => setShowModalWarning((prev) => false)} >Accept</button>
    </div>
  );
};

export default Warning;
