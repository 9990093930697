import React, { useContext, useEffect, useRef } from "react";
import * as echarts from "echarts";
import { BatteriesContext } from "../../../context/BatteriesContext";
import createOptionsOfCustomConsumptionProfile from "./CustomConsumption.chart.config";
import { AppContext } from "../../../context/AppContext";

const CustomConsumptionProfileGraph = ({ sendCustomProfile }) => {
  const chartRef = useRef(null);
  const {
    profileSelected,
    consumptionsProfiles,
    setConsumptionsProfiles,
    setProfileSelected,
  } = useContext(BatteriesContext);
  const { inner } = useContext(AppContext);

  useEffect(() => {
    return () => {
      sendCustomProfile();
    };
  }, []);

  /* Update the array of profiles when the custom profile is updated */

  useEffect(() => {
    function updateArrayOfProfiles() {
      const profiles = consumptionsProfiles;

      const profileSelctedIndex = profiles.findIndex(
        (profile) => profile.id === profileSelected.id
      );

      profiles[profileSelctedIndex] = profileSelected;
      setConsumptionsProfiles([...profiles]);
    }

    updateArrayOfProfiles();
  }, [profileSelected]);

  /* Create the chart foreach change in the data */
  useEffect(() => {
    const myChart = echarts.init(chartRef.current);
    const symbolSize = 15;
    let lastPos = new Array(23);
    let data = profileSelected?.consumptionPercentage.map((value, index) => [
      index,
      value,
    ]);

    const option = createOptionsOfCustomConsumptionProfile(data);
    option && myChart.setOption(option);

    function resize() {
      myChart.resize();
    }

    /* Resize the chart when the window is resized */
    window.addEventListener("resize", resize);

    function showTooltip(dataIndex) {
      myChart.dispatchAction({
        type: "showTip",
        seriesIndex: 0,
        dataIndex: dataIndex,
      });
    }
    function hideTooltip(dataIndex) {
      myChart.dispatchAction({
        type: "hideTip",
      });
    }

    setTimeout(function () {
      // Add shadow circles (which is not visible) to enable drag.
      myChart.setOption({
        graphic: data.map(function (item, dataIndex) {
          return {
            type: "circle",
            style: {
              fill: "#fff",
              stroke: "#ffb800",
            },
            position: myChart.convertToPixel("grid", item),
            shape: {
              cx: 0,
              cy: 0,
              r: symbolSize / 2,
            },
            invisible: false,
            draggable: true,
            ondrag: function (dx, dy) {
              if (lastPos[dataIndex] === undefined) lastPos[dataIndex] = this.x;
              this.x = lastPos[dataIndex];
              onPointDragging(dataIndex, [lastPos[dataIndex], this.y]);
            },
            onmousemove: function () {
              showTooltip(dataIndex);
            },
            ondragend: function () {
              myChart.setOption({
                yAxis: {
                  max: Math.round(
                    Math.max(...data.map((item) => item[1])) + 100
                  ),
                },
              });
              const dataUpdated = myChart
                .getOption()
                .series[0].data.map((coordinates) => coordinates[1]);
              setProfileSelected(prev => {
                return {
                  ...prev,
                  consumptionPercentage: dataUpdated,
                }
              })
              onPointDragging(dataIndex, [lastPos[dataIndex], this.y]);
            },
            onmouseout: function () {
              hideTooltip(dataIndex);
            },
            z: 100,
          };
        }),
      });
    }, 0);

    function updateAllPoints() {
      myChart.setOption({
        graphic: data.map(function (item, dataIndex) {
          return {
            position: myChart.convertToPixel("grid", item),
          };
        }),
      });
    }

    function onPointDragging(dataIndex, pos) {
      data[dataIndex] = myChart.convertFromPixel("grid", pos);

      if (data[dataIndex][1] < 0) {
        data[dataIndex][1] = 0;
      }
      // Update data
      myChart.setOption({
        series: [
          {
            id: "a",
            data: data,
          },
        ],
      });
      updateAllPoints();
    }

    return () => {
      window.removeEventListener("resize", resize);
      myChart.dispose();
    };
  }, [profileSelected, inner]);

  return <div ref={chartRef} style={{ width: "100%", height: "80vh" }}></div>;
};

export default CustomConsumptionProfileGraph;
