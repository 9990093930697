import { useContext } from "react";
import { ProposalContext } from "../../../context/ProposalContext";
import styles from "./part1.module.css";
import BillsChart from "../costLine.chart";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup/";

const Part1 = () => {
  const { proposalData, information } = useContext(ProposalContext);
  const [ref, inView] = useInView({ triggerOnce: true }); // Hook to detect if the element is in the viewport

  return (
    <div className={styles.part1Container}>
      <h2 className={styles.title}>
        Conventional <br />
        Energy Costs
      </h2>
      <p className={styles.information}>
        Electricity costs rise
        <span> {information?.escalationRate}% </span> yearly due to inflation.
        Solar investment is wise—predictable costs, shielding from rate hikes,
        leading to big savings.
      </p>
      <div className={styles.chartContainer}>
        <BillsChart height={"350px"} proposalData={proposalData}/>
        <div className={styles.dataContainer}>
          <div className={styles.data}>
            <span ref={ref} className={styles.dataRed}>
              {inView ? (
                <CountUp
                  start={0}
                  end={proposalData?.project?.avgMonthlyBill?.toFixed(2)}
                  duration={1}
                  decimals={2}
                  prefix="$"
                />
              ) : (
                ""
              )}
            </span>
            <p>Your utility bill today</p>
          </div>
          <div className={styles.data}>
            <span ref={ref} className={styles.dataRed}>
              {inView ? (
                <CountUp
                  start={0}
                  end={
                    proposalData?.thirtyYearsBillProjection
                      ? proposalData?.thirtyYearsBillProjection[
                          proposalData?.thirtyYearsBillProjection.length - 1
                        ]
                      : 0
                  }
                  duration={1}
                  decimals={2}
                  prefix="$"
                />
              ) : (
                ""
              )}
            </span>
            <p>Your utility bill over 30 years</p>
          </div>
        </div>
      </div>
      <div className={styles.dataCenterContainer}>
        <div className={styles.dataCenter}>
          <p>Your total bill over 30 years</p>
          <span ref={ref} className={styles.dataRed}>
            {inView ? (
              <CountUp
                start={0}
                end={proposalData?.billOverThirtyYears?.toFixed(2)}
                duration={1}
                decimals={2}
                prefix="$"
              />
            ) : (
              ""
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Part1;
