function createObjectOfHistoricalVsExpectedConfig(
  proposalData = {},
  monthsConsumptions = [],
) {



  const object = {
    backgroundColor: "transparent",
    animation: false,
    title: {
      text: "Historical Energy Consumption vs. Expected Solar Production",
      left: "9%",
      textStyle: {
        fontFamily: "Lexend", // Fuente del título
        fontSize: window.innerWidth <= 991 ? "1rem" : "1.4rem", // Tamaño de fuente del título, // Tamaño de fuente del título
        color: "white", // Color del texto del título
        width: 320,
        overflow: window.innerWidth <= 576 ? "break" : "none",
      },
      padding: [0, 0, 20, 0],
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        crossStyle: {
          color: "#999",
        },
      },
      formatter: function (params) {
        var tooltipContent = "";
        for (var i = 0; i < params.length; i++) {
          var seriesName = params[i].seriesName;
          var value = params[i].value;
          var unit = " kWh";
          var color = params[i].color;
          tooltipContent +=
            '<span style="color:' +
            color +
            ';">' +
            seriesName +
            ": " +
            value.toFixed(2) +
            unit +
            "<br/>";
        }
        return tooltipContent;
      },
    },

    xAxis: [
      {
        type: "category",
        data: [
          "Jan",
          "Feb",
          "March",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ],
        axisPointer: {
          type: "shadow",
        },
        axisLabel: {
          color: "#fff", // Cambia el color del texto del eje X a blanco
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        name: "kWh",
        min: 0,
        max: Math.max(...proposalData.ac_monthly, ...monthsConsumptions),
        interval: 150,
        axisLabel: {
          show: false,
          formatter: "{value} kWh",
          color: "#fff",
        },
      },
      {
        type: "value",
        name: "kWh",
        min: 0,
        max: Math.max(...proposalData.ac_monthly, ...monthsConsumptions),
        interval: 150,
        axisLabel: {
          show: false,
          formatter: "{value} kWh",
        },
      },
    ],
    series: [
      {
        name: "Solar Production",
        type: "line",
        data: proposalData.ac_monthly,
        itemStyle: {
          color: "rgba(0,200,217)",
          shadowBlur: 10,
          shadowColor: "rgba(255,255,255,0.6)",
        },
        lineStyle: {
          width: 4,
        },
        symbolSize: 10,
      },
      {
        name: "Energy Consumption",
        type: "bar",
        yAxisIndex: 1,
        data: monthsConsumptions,
        itemStyle: {
          color: "rgba(251,186,6,0.8)",
          shadowBlur: 10,
          shadowColor: "rgba(255,255,255,0.9)",
          barWidth: 20,
        },
      },
    ],
  };

  return object;
}

export default createObjectOfHistoricalVsExpectedConfig;
